import PersonAdd from "@material-ui/icons/PersonAdd";
import PersonPin from "@material-ui/icons/PersonPin";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import CustomTabs from "components/COMMON/Tabs/CustomTabs";
import UserEdit from "components/COMMON/Users/UserEdit";
import UsersList from "components/COMMON/Users/UsersList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import * as AuthService from 'services/AuthService.js';


const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "26px",
    fontWeight: "450",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

export const USER_LIST_TAB_INDEX = 0;
export const USER_CREATE_TAB_INDEX = 1;
export const USER_UPDATE_TAB_INDEX = 2;

class UsersManagement extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.customTabsRef = React.createRef();
    //this.forceShowAndSelectionUserUpdateTab = this.forceShowAndSelectionUserUpdateTab.bind(this);
    //this.forceShowAndSelectionUserUpdateTab = this.forceHideUserUpdateTab.bind(this);
  }

  getInitialState() {
    return {
      selectedTab: (USER_LIST_TAB_INDEX),
      currentLoggedUserCanManageUsers: (AuthService.authenticathedUserCanManageUsers()),
      showUserUpdateTab: false,
      selectedUserForEdit: null
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }


  getDynamicTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Dettagli utente",
        tabIcon: PersonPin,
        tabContent: (
          <UserEdit readOnly={!this.state.currentLoggedUserCanManageUsers}
                    createOrModify={false}
                    editLogged={false}
                    customTabsRef={this.customTabsRef}/>
        )
      }
    );
    return tabs;
  }

  getActiveTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Lista utenti esistenti",
        tabIcon: SupervisorAccount,
        tabContent: (
          <UsersList readOnly={!this.state.currentLoggedUserCanManageUsers} customTabsRef={this.customTabsRef}/>
        )
      }
    );
    if (this.state.currentLoggedUserCanManageUsers === true) {
      tabs.push(
        {
          tabName: "Crea nuovo utente",
          tabIcon: PersonAdd,
          tabContent: (
            <UserEdit readOnly={!this.state.currentLoggedUserCanManageUsers}
                      createOrModify={true}
                      editLogged={false}
                      customTabsRef={this.customTabsRef}/>
          )
        }
      );
    }

    return tabs;
  }

 
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>   
          <CustomTabs style={styles.cardTitleWhite}
            selectedTabIndex={this.state.selectedTab}
            headerColor="info"
            tabs={this.getActiveTabs()}
            dynamicTabs={this.getDynamicTabs()}
            showDynamicTabs={this.state.showUserUpdateTab}
            itemForDynamicTabs={this.state.selectedUserForEdit}
            ref={this.customTabsRef}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default UsersManagement;
