import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import Print from "@material-ui/icons/Print";
import SaveIcon from '@material-ui/icons/Save';
import { StyleSheet } from '@react-pdf/renderer';
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { axiosApiCaller } from 'layouts/AxiosService';
import PropTypes from 'prop-types';
import React from "react";
import ReactDOM from "react-dom";
// react component plugin for creating a beautiful datetime dropdown picker
import 'react-nice-dates/build/style.css';
import ReactToPrint from 'react-to-print';
import * as AuthService from 'services/AuthService.js';
import Swal from 'sweetalert2';
import * as CascoDashboard from 'views/TEE/Dashboard';
import ApprofondimentoVisualizer from "./ApprofondimentoVisualizer";






// Create styles
const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const classes = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));


const CHANGE_VIEW_TIME_GO_TO_LIST = 1200;

class ApprofondimentiMultiVisualizer extends React.Component {

  constructor(props) {
    super(props);
    //this.cancelEditing = this.cancelEditing.bind(this);
    this.componentRef = React.createRef();
    this.state = this.getInitialState();
    if (this.props.itemObj) {
      this.getListApprofondimentoRequestDocuments(this.props.itemObj.id);
    }
    this.downloadFileOfApprofondimentoRequest = this.downloadFileOfApprofondimentoRequest.bind(this);
    this.viewFileOfApprofondimentoRequest = this.viewFileOfApprofondimentoRequest.bind(this);
    this.getDocumentTypeList=this.getDocumentTypeList.bind(this);
  }


  getInitialState() {
    return {
      errorMessage: null,
      typeForDetail: CascoDashboard.PERSONA_FISICA_LABEL,
      itemForDetail: this.props.itemObj,
      editMode: false,
      isEditModeUsable: this.props.isEditModeUsable ? this.props.isEditModeUsable : false,
      specificScoreComponent: null,
      listaDocumenti: [],      
      pdfData: null,
      ispdfDataChanged: false,
      alert: null,
      tipoDocumentiList:[],
    };
  }



  getListApprofondimentoRequestDocuments(idApprofondimentoRequest) {
    let headerWithAuth = AuthService.getAuthHeaderAsObj();
    headerWithAuth["Content-Type"] = "multipart/form-data";
    let queryString = '?idApprofondimentoRequest=' + idApprofondimentoRequest;
    axiosApiCaller.get(AuthService.getApiForUploadDocumentForListApprofondimentoRequestDocuments() + queryString)
      .then(res => {
        this.setState({ listaDocumenti: res.data });
      })
      .catch((error) => {
      });
  }


  componentWillMount() {
    if (this.props.itemObj) {
      if (this.props.itemObj.codiceFiscale) {
        let positionDetail = this.extractApprofondimentoRequestDetailFromBackend(this.props.itemObj.codiceFiscale);
      }
    }
  }

 getDocumentTypeList(){
            axiosApiCaller.get(AuthService.postBackendApiDocumenti())
        .then((res) => {
                if (res.data.success === true) {
                   this.setState({tipoDocumentiList:res.data.payload})
                } else {
                    console.log('FLAG ERROR ON RESPONSE: ' + res.data.message);
                    this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');
                }
            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
                this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
            });
  }


  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.getDocumentTypeList()
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  changeMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  saveData() {
    let approfondimentoRequestDaModificare = {};
    let listaDocumentiDaEliminare = this.componentRef.current.listaDocumentiDaEliminare;
    //teeDiscoveredJuridicalSubjecBoxDaModificare.elaborationMessage = this.componentRef.current.cleanedData.elaborationMessage;
    approfondimentoRequestDaModificare.elaborationState = this.componentRef.current.cleanedData.elaborationState;
    approfondimentoRequestDaModificare.note = this.componentRef.current.cleanedData.note;
    approfondimentoRequestDaModificare.id = this.componentRef.current.cleanedData.id;
    //approfondimentoRequestDaModificare.platformId = this.componentRef.current.cleanedData.platformId;
    //let request={approfondimentoRequest: approfondimentoRequestDaModificare};
    let apiUrlToCall = '';
    apiUrlToCall = AuthService.postApiForUpdateApprofondimentoRequest();
    axiosApiCaller.post(apiUrlToCall, approfondimentoRequestDaModificare)
    apiUrlToCall = AuthService.postApiForDeleteListDocumentOfApprofondimentoRequest() + '?idApprofondimentoRequest=' + approfondimentoRequestDaModificare.id;
    axiosApiCaller.post(apiUrlToCall, listaDocumentiDaEliminare).
      then(res => {
        console.log(res);
        this.setState({ listaDocumenti: res.data });
        this.componentRef.current.listaDocumentiDaEliminare = [];
      })
      .catch((error) => {
        console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
        console.log(JSON.stringify(error.message));
      });
    this.changeMode();
  }

  render() {
    let message = '';
    //let specificScoreComponent = null;
    message = 'Componente per la gestione della richiesta di approfondimento';
    this.specificScoreComponent = (
      <ApprofondimentoVisualizer
        itemForDetail={this.state.itemForDetail}
        errorMessage={this.state.errorMessage}
        editMode={this.state.editMode}
        mainIcon={this.props.itemIcon}
        listaDocumenti={this.state.listaDocumenti}
        click={this.viewFileOfApprofondimentoRequest}
        download = {this.downloadFileOfApprofondimentoRequest}
        ref={this.componentRef}
        pdfData={this.state.pdfData}
        ispdfDataChanged={this.state.ispdfDataChanged}
        />
    );

    return (
      <div style={{ marginLeft: "10px" }}>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {/*
            <span style={{ float: 'right', margin: '-23px 30px 10px 0px' }}>
              <SmallMenu ragioneSociale={this.props.itemKey} menuPosition={"inDetails"} />
            </span>
              */}
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return <a href="#"><Print style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '30px', height: '30px' }} /></a>;
              }}
              content={() => this.componentRef}
            />
            {(() => {
              if (!this.state.editMode) {
                return <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="download" onClick={() => { this.changeMode(); }}>
                  <EditIcon />
                </IconButton>;
              }
              else if (this.state.editMode) {
                return <IconButton color="secondary" style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="download" onClick={() => { this.changeMode(); }}>
                  <CancelIcon />
                </IconButton>;
              }
              /*
            if (!this.state.editMode && this.state.isEditModeUsable) {
              if(this.state.itemForDetail.elaborationState != "SOSPESA" &&  this.state.itemForDetail.elaborationState != "ANNULLATA"){
                return <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="download" onClick={() => { this.changeMode(); }}>
                  <EditIcon />
                </IconButton>;
              }
            }
            else if (this.state.editMode) {
              return <IconButton color="secondary" style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="download" onClick={() => { this.changeMode(); }}>
                <CancelIcon />
              </IconButton>;
            }
            */
            })()}
            {(() => {
              return <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="upload" onClick={() => { this.uploadOnS3(this.componentRef.current.cleanedData.id); }}>
                <CloudUploadIcon id = "uploadFile"/>
              </IconButton>;
            })()}
            {(() => {
              if (this.state.editMode) {
                return <IconButton color="primary" style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="download" onClick={() => { this.saveData(); }}>
                  <SaveIcon />
                </IconButton>;
              }
            })()}
            {this.specificScoreComponent}
          </GridItem>
        </GridContainer>
        <br />
      </div >
    )
  }

  uploadOnS3(idApprofondimentoRequest) {
    let options=""
    for(let tipoDocument of this.state.tipoDocumentiList){
      options=options+'<option value="'+tipoDocument.nome_documento+'">'+tipoDocument.nome_documento+'</option>'}
    (async () => {

      const { value: file } = await Swal.fire({
        title: 'Upload File',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Upload',
        reverseButtons: true,
        input: 'file',
        // inputAttributes: {
        //   // 'accept': 'text/*',
        //   //'accept': '.xlsx',
        //   'aria-label': 'Upload file'
        // }
         html:
          '<div style="margin-left:-120px"><p style="padding-right:5px;display:inline-block">Choose document type</p><select style="font-size:large" name="doc_type" id="swal-input1">'+options+'</select></div>',
      })

      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        // formData.append("tipoDocumento",document.getElementById('swal-input1').value)
        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";
        let queryString = '?idApprofondimentoRequest=' + idApprofondimentoRequest +'&tipoDocumento='+document.getElementById('swal-input1').value;
        axios.post(AuthService.postApiForUploadDocumentForApprofondimentoRequest() + queryString, formData, {
          headers: headerWithAuth
        })
          .then(res => {
            if (!this.state.listaDocumenti || this.state.listaDocumenti.length == 0) {
              this.state.listaDocumenti = [];
            }
            this.state.listaDocumenti.push(res.data);
            this.setState({ listaDocumenti: this.state.listaDocumenti });
            //this.showRedirectAlert(title, message); 
          })
          .catch((error) => {
            console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
            console.log(JSON.stringify(error.message));
          });

      }
    })()
  }


  downloadFileOfApprofondimentoRequest(documentoDaScaricare) {
    let queryString = "?nameOnS3=" + documentoDaScaricare.nameOnS3 + "&nomeDefaultForDownloadDialogWindows=" + documentoDaScaricare.filename;
    axios.get(AuthService.getBackendApiForApprofondimentoRequestFileDownload() + queryString,
      {
        responseType: 'blob',
        headers: {
          // 'Accept': 'application/octet-stream',
          'Authorization': AuthService.getLoggedUserJwtToken()
        }
      })
      .then((response) => {
        // var headers = new Headers(response.headers);
        var headers = new Headers(response.headers);
        var contentDisposition = headers.get('Content-Disposition');
        var indiceFilename = contentDisposition.search("="); //filename=nomeFileIniziale...Mi prendo l'indice dell'uguale e faccio substring
        var nomeDefault = contentDisposition.substring(indiceFilename + 1, contentDisposition.length);
        var extension = nomeDefault.substring(nomeDefault.lastIndexOf(".") + 1);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeDefault);
          document.body.appendChild(link);
          link.click();
      })
      .catch((error) => console.log(error));
  }

  viewFileOfApprofondimentoRequest(documentoDaScaricare) {
    let queryString = "?nameOnS3=" + documentoDaScaricare.nameOnS3 + "&nomeDefaultForDownloadDialogWindows=" + documentoDaScaricare.filename;
    axios.get(AuthService.getBackendApiForApprofondimentoRequestFileDownloadBase64() + queryString,
      {
        // responseType: 'blob',
        headers: {
          // 'Accept': 'application/octet-stream',
          'Authorization': AuthService.getLoggedUserJwtToken()
        }
      })
      .then((response) => {
        // var headers = new Headers(response.headers);
        var headers = new Headers(response.headers);
        var contentDisposition = headers.get('Content-Disposition');
        var indiceFilename = contentDisposition.search("="); //filename=nomeFileIniziale...Mi prendo l'indice dell'uguale e faccio substring
        var nomeDefault = contentDisposition.substring(indiceFilename + 1, contentDisposition.length);
        var extension = nomeDefault.substring(nomeDefault.lastIndexOf(".") + 1);
        if (extension && extension.localeCompare("pdf") !== 0) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeDefault);
          document.body.appendChild(link);
          link.click();
        } else {
          this.setState({ pdfData: response.data });
          this.setState({ ispdfDataChanged: !this.state.ispdfDataChanged });
        }
      })
      .catch((error) => console.log(error));
  }

  extractApprofondimentoRequestDetailFromBackend(positionFiscalCode) {
    let appName = AuthService.getApplicationName();
    //let headerWithAuth = AuthService.getAuthHeaderAsObj();
    let apiUrlToCall = '';
    if (this.props.itemObj.personaFisica && ((this.props.itemObj.personaFisica === true))) {
      apiUrlToCall = AuthService.getBackendApiUtpPersonDetail();
    } else {
      apiUrlToCall = AuthService.getBackendApiUtpCompanyDetail();
    }
    axiosApiCaller.post(apiUrlToCall, {
      applicationName: appName,
      fiscalCode: positionFiscalCode
    })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ itemForDetail: res.data.payload });
          if (res.data.payload.personaFisica && ((res.data.payload.personaFisica === true))) {
            this.setState({ typeForDetail: CascoDashboard.PERSONA_FISICA_LABEL });
          } else {
            this.setState({ typeForDetail: CascoDashboard.PERSONA_GIURIDICA_LABEL });
          }
          this.setState({ errorMessage: null });
        } else {
          this.setState({ errorMessage: res.data.message });
        }
      })
      .catch((error) => {
        console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
        console.log(JSON.stringify(error.message));
        this.setState({ errorMessage: error.message });
      });
  }

}

ApprofondimentiMultiVisualizer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemObj: PropTypes.object.isRequired,
  itemDescription: PropTypes.string.isRequired,
  itemIcon: PropTypes.object.isRequired
};

export default ApprofondimentiMultiVisualizer;