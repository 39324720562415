/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import * as AuthService from 'services/AuthService.js';
import sintegra from "assets/img/common/sintegra-mini-logo.jpg";
import volksbank from "assets/img/common/volksbank-mini-logo.jpg";
import CommonCustomIcon from "views/COMMON/svgicons/CommonCustomIcon"
//import AccountCircle from "@material-ui/icons/AccountCircle";
import Widgets from "@material-ui/icons/Widgets";
import HowToReg from "@material-ui/icons/HowToReg";
import { Link } from "react-router-dom";
import { AuthConsumer } from "context/AuthContext.js";
import {appMainColors} from "assets/jss/material-dashboard-pro-react.js"

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "#283E4F",
      margin: "0",
      fontSize: "19px",
      fontWeight: "700",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#283E4F"
    }
  },
  cardTitleWhite: {
    color: "#283E4F",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "42px",
    fontWeight: "700",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardAvatarRightAlign: {
    textAlign: "right"
  },
  cardCategoryPowered: {
    "&,& a,& a:hover,& a:focus": {
      color: "#283E4F",
      margin: "0",
      fontSize: "13px",
      fontWeight: "600",
      marginTop: "0",
      marginBottom: "0"
    }
  }  
};

const useStyles = makeStyles(styles);

export default function HomeHead() {
  const classes = useStyles();
  const topButtonsColor = appMainColors[1];
  let logo=sintegra;
  if(AuthService.getApplicationName()=="VOLKSBANK"){
    logo=volksbank;
  }

  return (
    <AuthConsumer>
      {({ isAuth, login, logout }) => (
        <Card style={{height: '100px'}}>
          <CardHeader color="#FFFFFF">
            <table style={{width: '98%'}}>
              <tbody>
                <tr style={{width: '90%'}}>
                  <td style={{float:'left', width: '30%'}}>
                    <CommonCustomIcon icon={"Logo"} type={"large"} style={{marginTop: '-60px'}}/>
                  </td>
                  {/* <td style={{float:'right', width: '20%', textAlign: 'right'}}>
                    <CardAvatar className={classes.cardAvatarRightAlign} style={{paddingTop: '10px', marginTop: '5px', float:'right', marginRight:'0px'}}>
                      <a href="#" style={{ marginRight:'3px'}}>
                        <img src={logo} alt="Sintegra Group" height="42" width="42" />
                      </a>
                    </CardAvatar>
                    {isAuth ? (
                      <Link to="/consolle" style={{ color: topButtonsColor }}>
                        <Widgets style={{marginTop: '12px', float:'right', fontSize:'49px', marginRight:'17px'}}/>
                      </Link>                       
                     ) : (
                      <Link to="/login" style={{ color: topButtonsColor }}>
                        <Widgets style={{marginTop: '12px', float:'right', fontSize:'49px', marginRight:'17px'}}/>
                      </Link> 
                    )}                      
                  </td>                 */}
                </tr>
              </tbody>
            </table>
          </CardHeader>
          <CardBody>

          </CardBody>
        </Card>
      )}
    </AuthConsumer>
  );
}