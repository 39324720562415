import React from "react";
import {
    BrowserRouter as Router,
    Link,
    withRouter
} from "react-router-dom";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import HowToReg from "@material-ui/icons/HowToReg";
import Security from "@material-ui/icons/Security";
import Home from "@material-ui/icons/Home";
import NoEncryption from "@material-ui/icons/NoEncryption";
import * as AuthService from 'services/AuthService.js';
import { AuthConsumer } from "context/AuthContext.js";
import { appMainColors } from "assets/jss/material-dashboard-pro-react.js"

import * as AxiosService from 'layouts/AxiosService';
const styles = {
    cardCategoryWhite: {
        color: "#FFFFFF",
        margin: "0",
        fontSize: "18px",
        fontWeight: "700",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontSize: "31px",
        fontWeight: "700",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardAvatarRightAlign: {
        textAlign: "right"
    },
    cardCategoryPowered: {
        color: "#FFFFFF",
        margin: "0",
        fontSize: "13px",
        fontWeight: "600",
        marginTop: "0",
        marginBottom: "0"
    },
    centeredLoginForm: {
        width: "55%",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    }
};


class QRCodePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        let code = '';
        if(localStorage.getItem('qr-code')){
            code = localStorage.getItem('qr-code');
            localStorage.removeItem('qr-code');
        }
        return {
            codice: code, message: '',
            submitted: false, success: false, successRedirect: '/admin', failureRedirect: ''
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        let topMessage = null;
        let msg = '';
        const loginButtonsColor = appMainColors[0];
        if (this.state.submitted) {
            if (this.state.success) {
                msg = (<Success variant="h4"><Security />{this.state.message}</Success>);
            } else {
                msg = (<Warning variant="h4"><Security />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message}</Warning>);
            }
            topMessage = (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                        <br />{msg}
                    </GridItem>
                </GridContainer>
            )
        } else topMessage = (<br />);
        return (
            // <div style={!this.props.withMargin? styles.centeredLoginForm:{backgroundColor:"white"}}>
            <div style={{ backgroundColor: "white" }}>
                <AuthConsumer>
                    {({ isAuth, login, logout }) => (

                        <GridContainer style={{ width: '100%' }}>
                            <GridItem xs={12} sm={12} md={12}>
                                <div style={{ margin: "auto", minWidth: "400px" }}>
                                    <Card>
                                        <div style={{ position: "relative", border: "2px solid rgb(188, 188, 188)" }}>
                                            <div style={{ position: "absolute", top: "-30px", left: "30px", padding: "0 17px", backgroundColor: "white" }}>
                                                <p style={{ fontStyle: "italic" }}>
                                                    <b style={{ fontStyle: "normal", fontSize: "1.6em" }}>{AuthService.APPLICATION_DESCRIPTION}</b> <b style={{ fontSize: "1.5em", color: "rgb(65,65,65)" }}> QR Code</b>
                                                    <br />
                                                    <b style={{ fontSize: "0.85em" }}>QR Code generato</b>
                                                </p>
                                            </div>

                                            <CardBody>
                                                {topMessage}
                                                <img src={this.state.codice} style={{'maxWidth':'95%', 'maxHeight':'20%'}}></img>
                                            </CardBody>
                                            <CardFooter>
                                                <div style={{ position: "relative", top: "40px", marginLeft: "auto", marginRight: "auto", mtextAlign: "center" }}>
                                                    <Button
                                                        style={{ fontSize: '17px' }}
                                                        onClick={() => { this.prosegui() }}
                                                    >
                                                        Completa associazione&nbsp;&nbsp;&nbsp;<HowToReg style={{ fontSize: '28px' }} />
                                                    </Button>
                                                </div>
                                                <br />
                                            </CardFooter>
                                        </div>
                                    </Card>
                                </div>
                            </GridItem>
                        </GridContainer>

                    )}
                </AuthConsumer>
            </div>
        );
    }


    prosegui() {    
        this.props.history.push("/verify-code");
      }
}

export default QRCodePage;
