import React from "react";
import {
  BrowserRouter as Router,
  Link,
  withRouter
} from "react-router-dom";
//import AuthService from "services/AuthService.js";
import * as AuthService from 'services/AuthService.js';
import { AuthConsumer } from "context/AuthContext.js";

class Logout extends React.Component {

  
  constructor(props) {
    super();
    //this.state = this.getInitialState();
  }

  getInitialState() {
    return {};
  }

  componentDidMount() {
    this.doLogout();
  }

  componentWillUnmount() {
  }

  doLogout() {    
    this.logoutFromBackend();
    //this.context.logout();
    AuthService.clearLoggedUserInfos();
    this.props.history.push("/home");
  }

  render() {
    return (
      <div></div>
    );
  }

  logoutFromBackend() {
    let headerWithAuth = AuthService.getAuthHeaderAsObj();
    fetch(AuthService.getBackendApiLogout(), {
      method: 'POST',
      headers: headerWithAuth
    })
    .then(res => res.json())
    .then((data) => {
    })
    .catch((error) => {
    });
  }

}

export default withRouter(Logout);