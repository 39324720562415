import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select/Select";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Save from "@material-ui/icons/Save";
import WarningIcon from "@material-ui/icons/Warning";
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import Warning from "components/Typography/Warning.js";
import { axiosApiCaller } from 'layouts/AxiosService';
import PropTypes from 'prop-types';
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import 'react-nice-dates/build/style.css';
import * as AuthService from 'services/AuthService.js';
import * as UserManagementTabs from 'views/COMMON/UsersManagement.js';







const classes = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));



// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

const AUTO_CLOSE_TIME_DENIED_OPERATION = 2400;
const AUTO_CLOSE_TIME_SUCCESS_OPERATION = 1800;
const CHANGE_VIEW_TIME_GO_TO_USER_LIST = 2100;

class UserEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.initialState = this.getInitialState();
    this.handleChangeRole = this.handleChangeRole.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.deniedOperation = this.deniedOperation.bind(this);
    this.successOperation = this.successOperation.bind(this);
  }


  getInitialState() {
    return {
      originalLoginEmail: '',
      loginEmail: '',
      loginEmailValid: false,
      password: '',
      passwordValid: false,
      passwordConfirm: '',
      passwordConfirmValid: false,
      firstName: '',
      firstNameValid: false,
      lastName: '',
      lastNameValid: false,
      birthDate: null,
      birthDateFormatted: '',
      birthDateFormattedForEditing: '',
      birthPlace: '',
      userSelectedRole: '',
      userSelectedRoleValid: false,
      appRoles: [],
      errorMessage: null,
      alert: null
    };
  }

  resetForm() {
    document.getElementById("create-user-form").reset();
    this.setState(this.initialState);
    this.extractAllApplicationRoles();
  }

  goToUsersListView() {
    setTimeout(this.props.customTabsRef.current.setSelectedTab(UserManagementTabs.USER_LIST_TAB_INDEX), CHANGE_VIEW_TIME_GO_TO_USER_LIST);
  }

  cancelEditing() {
    if (this.props.editLogged === true) {
      this.setState(this.initialState);
    } else {
      this.props.customTabsRef.current.setItemForDynamicTabs(null);
      this.props.customTabsRef.current.setSelectedTab(UserManagementTabs.USER_LIST_TAB_INDEX);
      this.props.customTabsRef.current.hideDynamicTabs();
    }
  }

  hideAlert(redirectToListView) {
    this.setState({ alert: null });
    if (redirectToListView === true) {
      if (this.props.createOrModify) {
        this.goToUsersListView();
      } else {
        this.cancelEditing();
      }
    }
  };

  componentDidMount() {
    this.extractAllApplicationRoles();
    if (this.props.createOrModify === false) {
      let userLoginForDetails = '';
      if (this.props.editLogged === true) {
        userLoginForDetails = AuthService.getLoggedUserObject().loginEmail;
      } else {
        userLoginForDetails = this.props.customTabsRef.current.getItemForDynamicTabs();
      }
      this.extractUserDetails(userLoginForDetails);
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  handleChangeLoginEmail(event) {
    let newValue = event.target.value.trim();
    this.setState({ loginEmail: newValue });
    if (newValue != null) {
      if (verifyEmail(newValue) === false) {
        this.setState({ loginEmailValid: false });
        this.setState({ errorMessage: 'L\'email di login non è valida' });
      } else {
        this.setState({ loginEmailValid: true });
        this.setState({ errorMessage: null });
      }
    } else {
      this.setState({ loginEmailValid: false });
      this.setState({ errorMessage: 'L\'email di login non può essere vuota' });
    }
  };

  handleChangeFirstName(event) {
    let newValue = event.target.value.trim();
    this.setState({ firstName: newValue });
    if (newValue != '') {
      this.setState({ firstNameValid: true });
      this.setState({ errorMessage: null });
    } else {
      this.setState({ firstNameValid: false });
      this.setState({ errorMessage: 'Il nome non può essere vuoto' });
    }
  };

  handleChangeLastName(event) {
    let newValue = event.target.value.trim();
    this.setState({ lastName: newValue });
    if (newValue != '') {
      this.setState({ lastNameValid: true });
      this.setState({ errorMessage: null });
    } else {
      this.setState({ lastNameValid: false });
      this.setState({ errorMessage: 'Il cognome non può essere vuoto' });
    }
  };

  checkPasswordCorrispondence(newPwdValue, newPwdConfirmValue) {
    let pwdVal = this.state.password;
    let pwdConfirmVal = this.state.passwordConfirm;
    if (newPwdValue && (newPwdValue != null)) {
      pwdVal = newPwdValue;
    }
    if (newPwdConfirmValue && (newPwdConfirmValue != null)) {
      pwdConfirmVal = newPwdConfirmValue;
    }
    if (pwdVal === pwdConfirmVal) {
      this.setState({ passwordValid: true });
      this.setState({ passwordConfirmValid: true });
      this.setState({ errorMessage: null });
    } else {
      this.setState({ passwordValid: false });
      this.setState({ passwordConfirmValid: false });
      this.setState({ errorMessage: 'Le password indicate non corrispondono' });
    }
  }

  handleChangePassword(event) {
    let newValue = event.target.value.trim();
    this.setState({ password: newValue });
    if (newValue != '') {
      this.checkPasswordCorrispondence(newValue, null);
    } else {
      this.setState({ passwordValid: false });
      this.setState({ errorMessage: 'La password non può essere vuota' });
    }
  };

  handleChangePasswordConfirm(event) {
    let newValue = event.target.value.trim();
    this.setState({ passwordConfirm: newValue });
    if (newValue != '') {
      this.checkPasswordCorrispondence(null, newValue);
    } else {
      this.setState({ passwordConfirmValid: false });
      this.setState({ errorMessage: 'La password di conferma non può essere vuota' });
    }
  };

  handleChangeBirthPlace(event) {
    this.setState({ birthPlace: event.target.value });
  };

  handleChangeBirthDate(date) {
    if (date == null || date.trim == "") {
      this.setState({ birthDate: null });
    }
    else {
      this.setState({ birthDate: date });
    }
  };

  handleChangeRole(event) {
    //event.preventDefault();
    const theNewValue = event.target.value;
    this.setState({ userSelectedRole: theNewValue });
    if ((theNewValue !== null) && (theNewValue !== '')) {
      this.setState({ userSelectedRoleValid: true });
      this.setState({ errorMessage: null });
    } else {
      this.setState({ userSelectedRoleValid: false });
      this.setState({ errorMessage: 'Il ruolo non può essere nullo' });
    }
  };

  render() {

    let topMessage = null;
    let msg = '';
    if (this.state.errorMessage !== null) {
      msg = (<Warning variant="h4"><WarningIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.errorMessage}</Warning>);
      topMessage = (
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
            {msg}
          </GridItem>
        </GridContainer>
      )
    } else topMessage = (<br />);
    const oggi = new Date();
    return (
      <div style={{ marginLeft: "23px" }}>
        {this.state.alert}
        {topMessage}
        <form id="create-user-form">
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Ruolo nell'applicazione *</InputLabel>
                <Select
                  success={this.state.userSelectedRoleValid == true}
                  error={this.state.userSelectedRoleValid == false}
                  value={this.state.userSelectedRole}
                  onChange={this.handleChangeRole}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: (this.props.readOnly || this.props.editLogged)
                  }}
                >
                  {this.state.appRoles.map(role => (
                    <MenuItem key={role.id} value={role.name}>
                      {role.name + ' - ' + role.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <CustomInput
                  success={this.state.loginEmailValid == true}
                  error={this.state.loginEmailValid == false}
                  labelText="Email di login *"
                  inputProps={{
                    value: this.state.loginEmail,
                    disabled: (this.props.readOnly || this.props.editLogged),
                    type: "email",
                    onChange: event => { this.handleChangeLoginEmail(event) }
                  }}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <CustomInput
                  success={this.state.firstNameValid == true}
                  error={this.state.firstNameValid == false}
                  labelText="Nome *"
                  inputProps={{
                    value: this.state.firstName,
                    disabled: this.props.readOnly,
                    type: "text",
                    onChange: event => { this.handleChangeFirstName(event) }
                  }}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <CustomInput
                  success={this.state.lastNameValid == true}
                  error={this.state.lastNameValid == false}
                  labelText="Cognome *"
                  inputProps={{
                    value: this.state.lastName,
                    disabled: this.props.readOnly,
                    type: "text",
                    onChange: event => { this.handleChangeLastName(event) }
                  }}
                />
              </FormControl>
            </GridItem>
          </GridContainer>

          {((this.props.readOnly) || (!this.props.createOrModify && !this.props.editLogged)) ? (null) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <CustomInput
                    success={this.state.passwordValid == true}
                    error={this.state.passwordValid == false}
                    labelText="Password *"
                    inputProps={{
                      value: this.state.password,
                      disabled: this.props.readOnly,
                      type: "password",
                      onChange: event => { this.handleChangePassword(event) },
                      autoComplete: "off"
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          )}
          {((this.props.readOnly) || (!this.props.createOrModify && !this.props.editLogged)) ? (null) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <CustomInput
                    success={this.state.passwordConfirmValid == true}
                    error={this.state.passwordConfirmValid == false}
                    labelText="Conferma password *"
                    inputProps={{
                      value: this.state.passwordConfirm,
                      disabled: this.props.readOnly,
                      type: "password",
                      onChange: event => { this.handleChangePasswordConfirm(event) },
                      autoComplete: "off"
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <CustomInput
                  labelText="Luogo di nascita"
                  inputProps={{
                    value: this.state.birthPlace,
                    disabled: this.props.readOnly,
                    type: "text",
                    onChange: event => { this.handleChangeBirthPlace(event) },
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    style={{ marginTop: '12px', marginLeft: '3%', width: '140px' }}
                    label={"Data di nascita"}
                    maxDate={oggi}
                    format="dd/MM/yyyy"
                    value={this.state.birthDate}
                    onChange={date =>
                      this.handleChangeBirthDate(date)
                    }
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </GridItem>
          </GridContainer>
          <br />
          {(this.props.readOnly) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div style={{ margin: 'auto', textAlign: "center" }}>
                  <Button
                    style={{ fontSize: '15px', margin: '0px 30px 0px 0px' }}
                    color="warning"
                    onClick={() => { this.cancelEditing() }}
                    round>
                    Chiudi&nbsp;&nbsp;&nbsp;<Cancel style={{ fontSize: '28px' }} />
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          ) : (
            (this.props.createOrModify) ? (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{ margin: 'auto', textAlign: "center" }}>
                    <Button
                      style={{ fontSize: '15px', margin: '0px 30px 0px 0px' }}
                      color="warning"
                      onClick={() => { this.resetForm() }}
                      round>
                      Pulisci&nbsp;&nbsp;&nbsp;<Cancel style={{ fontSize: '28px' }} />
                    </Button>
                    <Button
                      disabled={!this.checkFieldValidity(false)}
                      style={{ fontSize: '15px' }}
                      color="success"
                      onClick={() => { this.createOrUpdateUser() }}
                      round>
                      Crea utente&nbsp;&nbsp;&nbsp;<Save style={{ fontSize: '28px' }} />
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{ margin: 'auto', textAlign: "center" }}>
                    <Button
                      style={{ fontSize: '15px', margin: '0px 30px 0px 0px' }}
                      color="warning"
                      onClick={() => { this.cancelEditing() }}
                      round>
                      {(this.props.editLogged === true) ? ('Ripristina') : ('Annulla')}&nbsp;&nbsp;&nbsp;<Cancel style={{ fontSize: '28px' }} />
                    </Button>
                    <Button
                      disabled={!this.checkFieldValidity(false)}
                      style={{ fontSize: '15px' }}
                      color="success"
                      onClick={() => { this.createOrUpdateUser() }}
                      round>
                      {(this.props.editLogged === true) ? ('Modifica profilo') : ('Modifica utente')}&nbsp;&nbsp;&nbsp;<Save style={{ fontSize: '28px' }} />
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            )
          )}
        </form>
      </div>
    )
  }

  checkFieldValidity(fireMessageBox) {
    let msg = '';
    if (this.state.loginEmail == null || (verifyEmail(this.state.loginEmail.trim()) === false)) {
      msg = 'L\'email di login fornita non è valida';
    }
    if (this.state.firstName === '') {
      msg = 'Il nome fornito non è valido';
    }
    if (this.state.lastName === '') {
      msg = 'Il cognome fornito non è valido';
    }
    if (this.props.createOrModify || this.props.editLogged) {
      if ((this.state.password === '') || (this.state.passwordConfirm === '') || (this.state.password !== this.state.passwordConfirm)) {
        msg = 'Le password fornite non sono valide o non coincidono';
      }
    }
    if (this.state.userSelectedRole === '') {
      msg = 'Il ruolo fornito non è valido';
    }
    if (msg !== '') {
      if (fireMessageBox === true) {
        this.deniedOperation('Impossibile creare o aggiornare l\'utente', msg);
      }
      return false;
    } else {
      return true;
    }
  }

  deniedOperation(primaryMsg, secondaryMsg) {
    this.setState({
      alert:
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: "center" }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color="success"
                  onClick={() => { this.hideAlert() }}
                  round>
                  <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}<br />
        </SweetAlert>
    });
    setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
  }

  successOperation(primaryMsg, secondaryMsg) {
    this.setState({
      alert:
        <SweetAlert
          success
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: "center" }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color="success"
                  onClick={() => { this.hideAlert() }}
                  round>
                  <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}<br />
        </SweetAlert>
    });
    setTimeout(this.hideAlert, AUTO_CLOSE_TIME_SUCCESS_OPERATION, (!this.props.editLogged));
  };


  /*
   warningWithConfirmMessage = (primaryMsg, loginWasChanged) => {
     this.setState({alert: 
       <SweetAlert
         warning
         style={{ display: "block", marginTop: "140px" }}
         title={primaryMsg}
         customButtons={
           <React.Fragment>
               <div style={{margin: 'auto', textAlign: "center"}}>   
                 <Button 
                   style={{fontSize: '13px', margin: '0px 30px 0px 0px'}}                   
                   color="warning"
                   onClick={() => { this.hideAlert() }}
                   round>
                     <Cancel style={{fontSize: '28px'}} />&nbsp;Annulla
                 </Button>               
                 <Button 
                   style={{fontSize: '13px', margin: '0px 0px 0px 0px'}}                   
                   color="success"
                   onClick={() => {  }}
                   round>
                     <CheckCircle style={{fontSize: '28px'}} />&nbsp;Conferma
                 </Button>                                            
               </div>
           </React.Fragment>
         }
       >
         {(loginWasChanged === true)?
           ('La e-mail di login del proprio profilo personale è stata modificata, la conferma renderà necessario un nuovo login.')
           : ('Le informazioni del profilo personale saranno aggiornate senza che sia necessario un nuovo login.')
         }
       </SweetAlert>
     });     
   };
   */


  extractAllApplicationRoles() {
    //let headerWithAuth = AuthService.getAuthHeaderAsObj();
    // fetch(AuthService.BACKEND_API_APPLICATION_ROLES, {
    //   method: 'POST',
    //   headers: headerWithAuth,
    //   body: JSON.stringify({
    //     applicationName: AuthService.APPLICATION_NAME
    //   })
    // })
    axiosApiCaller.post(AuthService.getBackendApiApplicationRoles(), { applicationName: AuthService.getApplicationName() })
      .then((res) => {
        if (res.data.success === true) {
          console.log(JSON.stringify(res.data.payload));
          let roles = res.data.payload;
          this.setState({ appRoles: roles, message: res.data.message });
        } else {
          this.setState({ message: res.data.message });
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error.message));
      });
  }

  extractUserDetails(userLoginForDetails) {
    //let headerWithAuth = AuthService.getAuthHeaderAsObj();
    // fetch(AuthService.BACKEND_API_USER_DETAIL, {
    //   method: 'POST',
    //   headers: headerWithAuth,
    //   body: JSON.stringify({
    //     loginEmail: userLoginForDetails,
    //     applicationName: AuthService.APPLICATION_NAME
    //   })
    // })
    console.log(AuthService.getApplicationName()) //no va bene, gli passiamo TEE e non ADMINSTRATION_PANEL
    axiosApiCaller.post(AuthService.getBackendApiUserDetail(), {
      loginEmail: userLoginForDetails,
      applicationName: AuthService.getApplicationName()
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log(JSON.stringify(res.data.payload));
          this.setState({
            originalLoginEmail: res.data.payload.loginEmail,
            loginEmail: res.data.payload.loginEmail,
            loginEmailValid: true,
            password: res.data.payload.password,
            passwordValid: true,
            passwordConfirm: res.data.payload.password,
            passwordConfirmValid: true,
            firstName: res.data.payload.firstName,
            firstNameValid: true,
            lastName: res.data.payload.lastName,
            lastNameValid: true,
            birthDate: res.data.payload.birthDate,
            birthDateFormatted: res.data.payload.formattedBirthDate,
            birthDateFormattedForEditing: res.data.payload.formattedBirthDateForEditing,
            birthPlace: res.data.payload.birthPlace,
            userSelectedRole: res.data.payload.appRoleNames[0],
            userSelectedRoleValid: true,
            errorMessage: null,
            alert: null
          });
          if (this.props.editLogged === true) {
            this.initialState = JSON.parse(JSON.stringify(this.state));
          }
        } else {
          this.setState({ message: res.data.message });
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error.message));
      });
  }

  createOrUpdateUser() {
    if (!this.checkFieldValidity(true)) {
      return;
    }
    let apiToCall = '';
    let originalLoginEmail = '';
    if (this.props.createOrModify === true) {
      apiToCall = AuthService.getBackendApiAddUserToApplication();
    } else if (this.props.editLogged === true) {
      apiToCall = AuthService.getBackendApiEditProfileToApplication();
      originalLoginEmail = this.state.originalLoginEmail;
    } else {
      apiToCall = AuthService.getBackendApiEditUserToApplication();
      originalLoginEmail = this.state.originalLoginEmail;
    }
    //let headerWithAuth = AuthService.getAuthHeaderAsObj();
    let bodyObj = {
      originalLoginEmail: originalLoginEmail,
      loginEmail: this.state.loginEmail,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      birthPlace: this.state.birthPlace,
      birthDate: this.state.birthDate,
      appName: AuthService.getApplicationName(),
      appRoleNames: [this.state.userSelectedRole]
    }
    console.log(JSON.stringify(bodyObj));
    // fetch(apiToCall, {
    //   method: 'POST',
    //   headers: headerWithAuth,
    //   body: JSON.stringify(bodyObj)
    // })
    axiosApiCaller.post(apiToCall, bodyObj)
      .then((res) => {
        if (res.data.success === true) {
          console.log(JSON.stringify(res.data.payload));
          if (this.props.createOrModify) {
            this.successOperation('Utente creato con successo', 'Il nuovo utente potrà accedere all\'applicazione con i privilegi concessi al ruolo assegnatogli');
          } else {
            this.successOperation('Utente modificato con successo', 'L\'utente potrà accedere all\'applicazione con i privilegi concessi al ruolo assegnatogli');
          }
        } else {
          if (this.props.createOrModify) {
            this.deniedOperation('Errore nella creazione dell\'utente', res.data.message);
          } else {
            this.deniedOperation('Errore nella modifica dell\'utente', res.data.message);
          }
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error.message));
      });
  }

}

UserEdit.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  createOrModify: PropTypes.bool.isRequired,
  editLogged: PropTypes.bool.isRequired,
  customTabsRef: PropTypes.object.isRequired
};

export default UserEdit;