import DateFnsUtils from "@date-io/date-fns";
import FormControl from '@material-ui/core/FormControl';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Save from "@material-ui/icons/Save";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { axiosApiCaller } from 'layouts/AxiosService';
import PropTypes from 'prop-types';
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import * as AuthService from 'services/AuthService.js';
import * as ContractTabs from 'views/COMMON/Contract.js';
const AUTO_CLOSE_TIME_DENIED_OPERATION = 6400;
const AUTO_CLOSE_TIME_SUCCESS_OPERATION = 1800;
const CHANGE_VIEW_TIME_GO_TO_CONTRACT_LIST = 2100;

class ContractEdit extends React.Component {

    constructor(props) {
        super(props);
        this.initialState = this.getInitialState();
        this.state = this.getInitialState();
        this.hideAlert = this.hideAlert.bind(this);
        this.failedAlert = this.failedAlert.bind(this);
        this.successAlert = this.successAlert.bind(this);
        this.redirectToListView = this.redirectToListView.bind(this);
        this.handleChangeDataInizio =this.handleChangeDataInizio.bind(this);
        this.handleChangeDataFine = this.handleChangeDataFine.bind(this)
    }
    getInitialState() {
        return {
            dataInizioContratto: null,
            dataInizioContrattoValid: false,
            dataFineContratto: null,
            dataFineContrattoValid: false,
            numeroInvii: 0,
            progressivoKo: 0,
            numeroInviiValid: false,
            applicationName: '',
            applicationNameValid: false,
            errorMessage: null,
            alert: null,
            id : null
        }
    }
    componentDidMount() {

        this.extractAllApplication();
        let newValue = AuthService.getApplicationName()
        this.setState({ applicationName: newValue })
        if (AuthService.getApplicationName() != null) {
            if (this.verifyDate(newValue) === false) {
                this.setState({ applicationNameValid: false });
                this.setState({ errorMessage: "Nome dell'applicazione non è valida" });
            } else {
                this.setState({ applicationNameValid: true });
                this.setState({ errorMessage: null });
            }
        } if (newValue == null || newValue == "") {
            this.setState({ applicationNameValid: false });
            this.setState({ errorMessage: "Nome dell'applicazione non può essere vuota" });
        }

        if (this.props.createOrModify === false) {
            let selectedItem = this.props.customTabsRef.current.getItemForDynamicTabs();
            this.state.id = selectedItem
            this.extractContractDetails(selectedItem);
        }
    }



    extractContractDetails(selectedItem) {
        axiosApiCaller.get(AuthService.getContracts() + "/" + selectedItem)
            .then((res) => {
                if (res.data.success === true) {
                    this.setState({
                        dataInizioContratto: res.data.payload.dataInizioContratto,
                        dataInizioContrattoValid: true,
                        dataFineContratto: res.data.payload.dataFineContratto,
                        dataFineContrattoValid: true,
                        numeroInvii: res.data.payload.numeroInvii,
                        progressivoKo: res.data.payload.progressivoKo,
                        numeroInviiValid: true,
                        applicationName: res.data.payload.applicationName,
                        applicationNameValid: true,
                        errorMessage: null,
                        alert: null
                    });
                } else {
                    this.setState({ message: res.data.message });
                }
            })
            .catch((error) => {
            });
    }


    formatSessionDateTime(dateTime) {
        var date = new Date(dateTime);
        return new Intl.DateTimeFormat("it", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        }).format(date);
    }

    extractAllApplication() {
    }

    handleChange(event) {
        let newValue = event.target.value.trim();
        switch (event.target.name) {
            case 'dataInizioContratto':
                this.setState({ dataInizioContratto: newValue });
                if (newValue != null) {
                    if (this.verifyDate(newValue) === false) {
                        this.setState({ dataInizioContrattoValid: false });
                        this.setState({ errorMessage: 'L\'Data inizio non è valida' });
                    } else {
                        this.setState({ dataInizioContrattoValid: true });
                        this.setState({ errorMessage: null });
                    }
                } if (newValue == null || newValue == "") {
                    this.setState({ dataInizioContrattoValid: false });
                    this.setState({ errorMessage: 'L\'Data inizio non può essere vuota' });
                }
                break
            case 'dataFineContratto':
                this.setState({ dataFineContratto: newValue });
                if (newValue != null) {
                    if (this.verifyDate(newValue) === false) {
                        this.setState({ dataFineContrattoValid: false });
                        this.setState({ errorMessage: 'L\'Data fine non è valida' });
                    } else {
                        this.setState({ dataFineContrattoValid: true });
                        this.setState({ errorMessage: null });
                    }
                } if (newValue == null || newValue == "") {
                    this.setState({ dataFineContrattoValid: false });
                    this.setState({ errorMessage: 'L\'Data fine non può essere vuota' });
                }
            //     break
            // case 'numeroInvii':
            //     this.setState({ numeroInvii: 0 });
            //     if (newValue != null) {
            //         if (this.verifyDate(newValue) === false) {
            //             this.setState({ numeroInviiValid: false });
            //             this.setState({ errorMessage: 'L\'Numero Invii non è valida' });
            //         } else {
            //             this.setState({ numeroInviiValid: true });
            //             this.setState({ errorMessage: null });
            //         }
            //     } if (newValue == null || newValue == "") {
            //         this.setState({ numeroInviiValid: false });
            //         this.setState({ errorMessage: 'L\'Numero Invii non può essere vuota' });
            //     }
                break
            case 'applicationName':
                this.setState({ applicationName: newValue });
                if (newValue != null) {
                    if (this.verifyDate(newValue) === false) {
                        this.setState({ applicationNameValid: false });
                        this.setState({ errorMessage: "Nome dell'applicazione non è valida" });
                    } else {
                        this.setState({ applicationNameValid: true });
                        this.setState({ errorMessage: null });
                    }
                } if (newValue == null || newValue == "") {
                    this.setState({ applicationNameValid: false });
                    this.setState({ errorMessage: "Nome dell'applicazione non può essere vuota" });
                }
                break
        }
    }
    verifyDate(date) {
        return true
    }
    cancelEditing() {

        this.setState(this.initialState);

        this.props.customTabsRef.current.setItemForDynamicTabs(null);
        this.props.customTabsRef.current.setSelectedTab(ContractTabs.CONTRACT_LIST_TAB_INDEX);
        this.props.customTabsRef.current.hideDynamicTabs();

    }
    checkFieldValidity(fireMessageBox) {
        let msg = '';
        if (this.state.dataInizioContratto == null) {
            msg = 'Data inizia non è valida';
        }
        if (this.state.dataFineContratto == null) {
            msg = 'Data fine non è valida';
        }
        // if (this.state.numeroInvii === null) {
        //     msg = 'Numero Invii non è valido';
        // }
        if (this.state.applicationName === '') {
            msg = "Nome dell'applicazione non è valida";
        }

        if (msg !== '') {
            if (fireMessageBox === true) {
                this.deniedOperation('Impossibile creare o aggiornare l\'utente', msg);
            }
            return false;
        } else {
            return true;
        }
    }

    resetForm() {
        document.getElementById("create-contact-form").reset();
        this.setState(this.initialState);
    }

    createOrUpdateContact() {
        if (!this.checkFieldValidity(true)) {
            return;
        }
        let bodyObj = {
            applicationName: this.state.applicationName,
            dataInizioContratto: this.state.dataInizioContratto,
            dataFineContratto: this.state.dataFineContratto,
            numeroInvii: this.state.numeroInvii,
            progressivoKo: this.state.progressivoKo,
            id: this.state.id
        }

        axiosApiCaller.post(AuthService.addContract(), bodyObj)
            .then((res) => {
                if (res.data.success === true) {
                    if (this.props.createOrModify) {
                        this.successAlert('Contratto creato con successo', res.data.message);
                    } else {
                        this.successAlert('Contratto modificato con successo', res.data.message);
                    }

                } else {
                    if (this.props.createOrModify) {
                        this.failedAlert('Errore nella creazione del contratto', res.data.message);
                    } else {
                        this.failedAlert('Errore nella modifica del contratto', res.data.message);
                    }
                }
            })
    }
    successAlert(primaryMsg, secondaryMsg) {
        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "140px", width: "40em" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center", marginBottom: "15px" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 10px 0px 0px' }}
                                    color="warning"
                                    onClick={() => { this.redirectToListView() }}
                                    round>
                                    <ArrowBackIcon style={{ fontSize: '28px' }} />&nbsp;Torna alla lista
                            </Button>

                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                                    color="success"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <PersonAdd style={{ fontSize: '28px' }} />&nbsp;Continua ad aggiungere
                            </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
                // this.resetForm()

        // setTimeout(this.hideAlert, AUTO_CLOSE_TIME_SUCCESS_OPERATION, (!this.props.editLogged));     

    };

    failedAlert(primaryMsg, secondaryMsg) {
        this.setState({
            alert:
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                                    color="success"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                    </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
        setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
    }

    hideAlert() {
        this.setState({ alert: null });

    };
    redirectToListView() {
        setTimeout(this.props.customTabsRef.current.setSelectedTab(ContractTabs.CONTRACT_LIST_TAB_INDEX), CHANGE_VIEW_TIME_GO_TO_CONTRACT_LIST);
    }

    handleChangeDataInizio(value) {
        this.setState({ dataInizioContratto: value })
    }
    handleChangeDataFine(value) {
        this.setState({ dataFineContratto: value })
    }

    render() {
        return (<div style={{ marginLeft: "23px" }}>
            {this.state.alert}
            {/* {topMessage} */}
            <form id="create-contact-form">

                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <CustomInput
                                success={this.state.applicationNameValid == true}
                                error={this.state.applicationNameValid == false}
                                name="applicationName"
                                labelText="Nome dell'applicazione *"
                                inputProps={{
                                    name: "applicationName",
                                    value: this.state.applicationName,
                                    type: "text",
                                    disabled:true,
                                    onChange: event => { this.handleChange(event) }
                                }}
                            />
                        </FormControl>
                    </GridItem>
                </GridContainer>



                <br />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // success={this.state.dataInizioContrattoValid == true}
                                    // error={this.state.dataInizioContrattoValid == false}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data inizio contratto *"
                                    value={this.state.dataInizioContratto}
                                    name="dataFineContratto"
                                    onChange={(event) => { this.handleChangeDataInizio(event) }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Data fine contratto *"
                                    value={this.state.dataFineContratto}
                                    //maxDate={new Date()}
                                    //minDate={this.state.startDate}
                                    onChange={(event) => { this.handleChangeDataFine(event) }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />

                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </GridItem>
                </GridContainer>
                <br />

                {/* <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <CustomInput
                                success={this.state.numeroInviiValid == true}
                                error={this.state.numeroInviiValid == false}
                                labelText="Numero Invii *"
                                name="numeroInvii"
                                inputProps={{
                                    name: "numeroInvii",
                                    value: this.state.numeroInvii,
                                    type: "number",
                                    onChange: event => { this.handleChange(event) }
                                }}
                            />
                        </FormControl>
                    </GridItem>
                </GridContainer> */}

                <br />
                {(this.props.readOnly) ? (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 10px 0px 0px' }}
                                    color="danger"
                                    onClick={() => { this.cancelEditing() }}
                                    round>
                                    <Cancel style={{ fontSize: '28px' }} />&nbsp;Chiudi
                                </Button>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 10px 0px 0px' }}
                                    color="warning"
                                    onClick={() => { this.redirectToListView() }}
                                    round>
                                    <ArrowBackIcon style={{ fontSize: '28px' }} />&nbsp;Torna alla lista
                                </Button>
                                <Button
                                    disabled={!this.checkFieldValidity(false)}
                                    style={{ fontSize: '15px' }}
                                    color="success"
                                    onClick={() => { this.createOrUpdateContact() }}
                                    round>
                                    Salva Modifica &nbsp;&nbsp;&nbsp;<Save style={{ fontSize: '28px' }} />
                                </Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                ) : (
                    (this.props.createOrModify) ? (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <div style={{ margin: 'auto', textAlign: "center" }}>

                                    <Button
                                        style={{ fontSize: '13px', margin: '0px 10px 0px 0px' }}
                                        color="warning"
                                        onClick={() => { this.redirectToListView() }}
                                        round>
                                        <ArrowBackIcon style={{ fontSize: '28px' }} />&nbsp;Torna alla lista
                                    </Button>

                                    {/* <Button
                                        style={{ fontSize: '15px', margin: '0px 30px 0px 0px' }}
                                        color="warning"
                                        onClick={() => { this.resetForm() }}
                                        round>
                                        Pulisci&nbsp;&nbsp;&nbsp;<Cancel style={{ fontSize: '28px' }} />
                                    </Button> */}
                                    <Button
                                        disabled={!this.checkFieldValidity(false)}
                                        style={{ fontSize: '15px' }}
                                        color="success"
                                        onClick={() => { this.createOrUpdateContact() }}
                                        round>
                                        Crea contratto&nbsp;&nbsp;&nbsp;<Save style={{ fontSize: '28px' }} />
                                    </Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                    ) : (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <div style={{ margin: 'auto', textAlign: "center" }}>
                                    <Button
                                        style={{ fontSize: '15px', margin: '0px 30px 0px 0px' }}
                                        color="warning"
                                        onClick={() => { this.cancelEditing() }}
                                        round>
                                        {(this.props.editLogged === true) ? ('Ripristina') : ('Annulla')}&nbsp;&nbsp;&nbsp;<Cancel style={{ fontSize: '28px' }} />
                                    </Button>
                                    <Button
                                        disabled={!this.checkFieldValidity(false)}
                                        style={{ fontSize: '15px' }}
                                        color="success"
                                        onClick={() => { this.createOrUpdateContact() }}
                                        round>
                                        {(this.props.editLogged === true) ? ('Modifica profilo') : ('Modifica utente')}&nbsp;&nbsp;&nbsp;<Save style={{ fontSize: '28px' }} />
                                    </Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                    )
                )}
            </form>
        </div>)

    }
}

ContractEdit.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    createOrModify: PropTypes.bool.isRequired,
    editLogged: PropTypes.bool.isRequired,
    customTabsRef: PropTypes.object.isRequired
};


export default ContractEdit;