import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from "@material-ui/icons/Search";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import { axiosApiCaller } from "layouts/AxiosService";
import PropTypes from "prop-types";
import React from "react";
import * as AuthService from "services/AuthService.js";

const localStyles = {
  icon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "23px",
    height: "23px",
    top: "0px",
    left: "2px",
    position: "relative",
  },
  actionButton: {
    margin: "5px 5px 5px 5px",
    padding: "0px",
    width: "30px",
    height: "30px",
    minWidth: "auto",
    fontSize: "20px",
  },
  dialogConfirmButton: {
    margin: "0 0 0 5px",
    padding: "20px",
    width: "80px",
    height: "auto",
    minWidth: "auto",
  },
  dialogCancelButton: {
    margin: "0 0 0 5px",
    padding: "7px",
    width: "auto",
    height: "auto",
    minWidth: "auto",
  },
};

const styles = {
  ...localStyles,
  ...extendedTablesStyle,
};

class StatusRobotList extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.extractAllStatusRobotList = this.extractAllStatusRobotList.bind(this);
  }

  getInitialState() {
    return {
      statusRobotListTableData: [],
      currentLoggedUser: AuthService.getLoggedUserObject(),
      currentLoggedToken: AuthService.getLoggedUserJwtToken(),
      currentAppName: AuthService.getApplicationName(),
      message: "",
      dataInizioFilter: null,
      dataFineFilter: null,
    };
  }

  componentDidMount() {
    this.extractAllStatusRobotList();
  }

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    const tableHead = [
      "Stato",
      "Robot Type",
      "Data Avvio",
      "Data Fine",
      "N Posizioni Totali",
      "N Posizioni Elaborate",
      "N Posizioni KO",
      "N Posizioni Scartate",
    ];
    let actionButtonsColumnClasses = null;
    let actionButtonsColumnHeaderClasses = null;
    if (this.props.readOnly) {
      actionButtonsColumnClasses = [classes.centeredSingleActionButtonsColumn];
      actionButtonsColumnHeaderClasses = [
        classes.centeredSingleActionButtonsColumnHead,
      ];
    } else {
      actionButtonsColumnClasses = [classes.centeredTripleActionButtonsColumn];
      actionButtonsColumnHeaderClasses = [
        classes.centeredTripleActionButtonsColumnHead,
      ];
    }
    return (
      <div style={{ width: "93%", marginLeft: "3px" }}>
        {this.state.alert}

        <FormControl fullWidth onKeyPress={this.onKeyUp}>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                name="dataInizio"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data inizio"
                value={this.state.dataInizioFilter}
                onChange={(event) => {
                  this.handleChangeDataInizio(event);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker style={{ marginLeft: "2%"}}
                name="dataFine"
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data fine contratto"
                value={this.state.dataFineFilter}
                onChange={(event) => {
                  this.handleChangeDataFine(event);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />

              <Button color="success" style={{ position: "relative", top: "17px", left: "20px" }} onK round
                onClick={() => {
                  this.extractAllStatusRobotList();
                }}
                class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-91 makeStyles-success-101 makeStyles-round-122">
                Filtra&nbsp;&nbsp;&nbsp;
                <SearchIcon style={{ fontSize: "28px" }} />
              </Button>
            </MuiPickersUtilsProvider>
          </GridItem>
        </FormControl>
        
        <Table
          tableHeaderColor="warning"
          tableHead={tableHead}
          tableData={this.state.statusRobotListTableData}
          customCellClasses={actionButtonsColumnClasses}
          customClassesForCells={[0]}
          customHeadCellClasses={actionButtonsColumnHeaderClasses}
          customHeadClassesForCells={[0]}
            order= "desc"
            orderBy= {2}
        />
      </div>
    );
  }

  handleChangeDataInizio(value) {
    this.setState({ dataInizioFilter: value });
  }
  handleChangeDataFine(value) {
    this.setState({ dataFineFilter: value });
  }

  convertStatusRobotListToTableData(jsonContractData) 
  {
    
    let rowKeyForActions = "";
    let retArray = [];
    jsonContractData.forEach((element) => {
      rowKeyForActions = element.id;
      let singleArray = [];
      singleArray.push(element.stato ? element.stato : "");
      singleArray.push(element.robotType ? element.robotType : "");
      singleArray.push(element.dataAvvio ? this.formatSessionDateTime(element.dataAvvio) : "");
      singleArray.push(element.dataFine ? this.formatSessionDateTime(element.dataFine) : "");
      singleArray.push(element.numeroPosizioniTotali ? element.numeroPosizioniTotali : "");
      singleArray.push(element.numeroPosizioniElaborate ? element.numeroPosizioniElaborate : "");
      singleArray.push(element.numeroPosizioniKO ? element.numeroPosizioniKO : "");
      singleArray.push(element.numeroPosizioniScartate ? element.numeroPosizioniScartate : "");
      retArray.push(singleArray);
    });
    return retArray;
  }

  enableUser(userIdentifier) {}

  disableUser(userIdentifier) {}

  formatSessionDateTime(dateTime) {
    var date = new Date(dateTime);
    return new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(date);
  }

  extractAllStatusRobotList() {
    let appName = AuthService.getApplicationName();
    axiosApiCaller
      .post(AuthService.getBackendApiForSearchRobotList(), {
        offSet: 0,
        rowsPerPage: 10,
        size: 300,
        applicationName: appName,
        start:
          this.state.dataInizioFilter != null
            ? this.state.dataInizioFilter
            : null,
        end:
          this.state.dataFineFilter != null ? this.state.dataFineFilter : null,
      })
      .then((res) => {
        if (res.data.success === true) 
        {
          let processedList = res.data.payload;
          let dataForTable = this.convertStatusRobotListToTableData(processedList);

          this.setState({
            statusRobotListTableData: dataForTable,
            message: res.data.message,
          });
        } else {
          this.setState({
            statusRobotListTableData: [],
            message: res.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({ statusRobotListTableData: [], message: error.message });
      });
  }
}

StatusRobotList.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  customTabsRef: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatusRobotList);
