import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import withStyles from "@material-ui/core/styles/withStyles";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DeleteIcon from '@material-ui/icons/Delete';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { axiosApiCaller } from 'layouts/AxiosService';
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import * as AuthService from 'services/AuthService.js';


class ListaCarichePage extends React.Component {
    
    constructor(props) {
        super(props);
        this.caricheInput=React.createRef();
        this.state=this.getInitialState();
        this.addCariche=this.addCariche.bind(this);
        this.onChangeCariche=this.onChangeCariche.bind(this);
        this.caricheList = [];
        // this.data = this.data(this.props.itemForDetail);
        this.deleteAlert=this.deleteAlert.bind(this);
        this.hideAlert=this.hideAlert.bind(this);
        this.deleteCariche=this.deleteCariche.bind(this);
        this.delete=this.delete.bind(this)


     }

      getInitialState() {
        return {
            caricheList:[],
            cariche:"",
            caricheList: [],
        }
    
    }


componentDidMount(){
      axiosApiCaller.get(AuthService.postBackendApiCariche())
      
      .then((res) => {
         
        this.setState({ caricheList: res.data.payload })

        }).catch((error) => {
        });
}



deleteAlert(primaryMsg, secondaryMsg, key) {
        console.log("called alert")
        this.setState({
            alert:
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                                    color="danger"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <Cancel style={{ fontSize: '28px' }} />&nbsp;Annulla
                      </Button>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                                    color="success"
                                    onClick={() => { this.deleteCariche(key) }}
                                    round>
                                    <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                      </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
    }



    hideAlert(redirectToListView) {
        this.setState({ alert: null });
    }
    

delete(key) {

        this.deleteAlert("Sei sicuro di voler eliminare questa cariche", "", key)
    }


    deleteCariche(key,id){
        let cl = this.state.caricheList
        cl.splice(key, 1);
        this.setState({ caricheList: cl })
        let requestObject = this.state.cariche
        let headerWithAuth = AuthService.getAuthHeaderAsObj();

    axiosApiCaller.post(AuthService.deleteBackendApiCariche(),{"id":id}).then((res) => {
      
      console.log(res)
    }).catch((error) => {
      console.log(error)
    })
  
    }

    addCariche(event){
    let tempcl=this.state.caricheList
    tempcl.push({"name":this.state.cariche})
    this.setState({caricheList:tempcl})
    this.caricheInput.current.value=""
    axiosApiCaller.post(AuthService.postBackendApiCariche()+"?name="+this.state.cariche).then((res) => {
      console.log(res)
    }).catch((error) => {
      console.log(error)
    })
    }

onChangeCariche(event){
    let tempCariche=event.target.value;
    this.setState({cariche:tempCariche})

}
 
    render(){
    const { classes } = this.props;


    
       return(
                    <Card>
                        <CardHeader>
                            <h3 className={classes.cardTitle}><b>Cariche</b></h3>

                        </CardHeader>
                        <CardBody>
                     {this.state.caricheList.map((cariche, key) => {
                         
                       return <div style={{width:"50%",height:"40px",boxShadow: "0 1px 4px 0 rgb(0 0 0 / 10%)",marginBottom:"10px"}}><div name={key}> 
                     <p style={{paddingTop:"0px",display:"inline-block",paddingLeft:"15px"}}>{cariche.name}</p> 
                      <IconButton style={{width:"10px",float:"right",display:"inline-block",padding:"0px",paddingTop:"8px",paddingRight:"20px"}} color="secondary" aria-label="elimina"  >
                      <DeleteIcon onClick={(event) => this.deleteCariche(key, cariche.id)} />
                      </IconButton>
                       </div>
                      
                      </div>
                                })}
                        <Button
                  style={{ fontSize: '15px', margin: '0px 0px 0px 0px' }}
                  color="success"
                  onClick={
                      
                      (event) => this.addCariche(event)
                    }
                  round

                >
                <FontAwesomeIcon color="green" icon={faPlusSquare} size="3x" style={{ display: "inline", float: "left", paddingRight: "10px", paddingTop: "13px" }} />
                  </Button>
                  <input style={{ width: "auto", marginLeft: '5px',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={this.caricheInput}
                  onChange={(event)=>this.onChangeCariche(event)}>
                  
                  </input>
                        </CardBody>
                        </Card>

        );

    }

    
    
}
export default withStyles(withStyles)(ListaCarichePage);
