import CheckCircle from "@material-ui/icons/CheckCircle";
import MenuImgClosed from 'components/COMMON/png/menu-closed.png';
import MenuImgOpened from 'components/COMMON/png/menu-opened2.png';
import Button from "components/CustomButtons/Button.js";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

class SmallMenu extends React.Component{
    constructor(props){
        super();
        this.state={
            alert: null,
            isMenuVisible: false,
            div1Hovering: false,
            div2Hovering: false,
            div3Hovering: false,
            div4Hovering: false,
            div5Hovering: false,
            div6Hovering: false,
            div7Hovering: false,
            div8Hovering: false
        };

        this.changeVisibility = this.changeVisibility.bind(this);
        this.setHighlight = this.setHighlight.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    changeVisibility(){
        this.setState(prevState => {
            return { isMenuVisible: !prevState.isMenuVisible};
        });
    }

    setHighlight(number, hovering){
        if(number == 1){
            this.setState( {div1Hovering: hovering} );
        }else if(number == 2){
            this.setState( {div2Hovering: hovering} );
        }else if(number == 3){
            this.setState( {div3Hovering: hovering} );
        }else if(number == 4){
            this.setState( {div4Hovering: hovering} );
        }else if(number == 5){
            this.setState( {div5Hovering: hovering} );
        }else if(number == 6){
            this.setState( {div6Hovering: hovering} );
        }else if(number == 7){
            this.setState( {div7Hovering: hovering} );
        }else if(number == 8){
            this.setState( {div8Hovering: hovering} );
        }
    }

    prepareEmailSending(pText){
        let primaryMsg = "Conferma Richiesta";
        // let secondaryMsg = "Stai richiedendo "+ pText +" per la società "+ this.props.ragioneSociale;
        let secondaryMsg = "Confermando la richiesta verrà addebitato il prezzo previsto dal contratto in essere legato alla sua utenza.";
        
        this.showEmailSendingAlert(primaryMsg, secondaryMsg);
    }

    showEmailSendingAlert(primaryMsg, secondaryMsg){
        this.setState({alert: 
          <SweetAlert
            info
            style={{ display: "block", marginTop: "140px" }}
            title={primaryMsg}
            customButtons={
              <React.Fragment>
                  <div style={{margin: 'auto', textAlign: "center"}}>
                    

                  <Button 
                      style={{fontSize: '13px', margin: '0px 0px 0px 10px'}}
                      color="secondary"
                      onClick={ this.hideAlert }
                      round>
                        <CheckCircle style={{fontSize: '28px'}} />&nbsp;Cancel
                    </Button>

                    <Button 
                      style={{fontSize: '13px', margin: '0px 0px 0px 10px'}}
                      color="success"
                      onClick={ this.hideAlert }
                      round>
                        <CheckCircle style={{fontSize: '28px'}} />&nbsp;Send
                    </Button>
                    
                  </div>
              </React.Fragment>
            }
          >
            {secondaryMsg}<br/>
          </SweetAlert>  
        });
      }

      hideAlert(){
          this.setState( {alert : null} );
      }

    render(){
        // const imgStyle = { position: "relative", top:"4px", left:"10px", cursor:"pointer"}

        let lv1DivStyle = {
            display: this.state.isMenuVisible? "inline-block" : "none",

            padding: "4px",
            width:"170px",
            position: "absolute",
            left: this.props.menuPosition =="inDetails"? "-185px" : "42px",
            // bottom: this.props.menuPosition =="inDetails"? "-100px" : "-29px",
            bottom: this.props.menuPosition =="inDetails"? "-233px" : "-162px",

            background: "white",
            borderRadius: "3px",
            boxShadow: "3px 3px 8px rgb(121, 121, 121)",

            fontSize: "11px",
            fontWeight: "450",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",

            zIndex: "3"
        }

        let lv2DivStyle = {
            padding:"4px",
            color:"rgb(75,75,75)",
            cursor:"pointer",
            transition: "background 0.3s ease",
            position: "relative"
        }

        let lv2DivStyleHovered = {
            padding:"4px",
            background:"rgb(160, 198, 77)",
            borderRadius: "1px",
            color:"white",
            cursor:"pointer"
        }

        let pStyle = {marginTop: "0", marginBottom: "0", padding:"4px 3px 4px 3px", textTransform: "uppercase"};

        let p1Text = "Contatta analista";
        let p2Text = "Cambia timing monitoraggio";
        let p3Text = "Documentazione ufficiale";
        let p4Text = "Ricostruisci catena TEE";
        let p5Text = "Approfondimenti kyc";
        let p6Text = "Verifiche sanction list";
        let p7Text = "Richiedi BPO";
        let p8Text = "Stampa la pratica";
        
        // let borderColor = this.state.isMenuVisible? "rgb(233,233,233)" : "rgb(245,245,245)";
        // let border = "1px solid "+borderColor;
        let imgContainerStyle={
            cursor:"pointer",
            display:"inline-block",
            width:"35px",
            height:"35px",
            background:this.state.isMenuVisible? "rgb(240,240,240)" : "white",
            borderRadius:"50%",
        }

        let imgStyle={
            position:"relative",
            top:"7px",
            left:"15px"
        }
        return(
            <span>
                {this.state.alert}
                <span style={{position: "relative"}}>
                    <span style={imgContainerStyle} onClick={() => this.changeVisibility()}>
                        <img style={imgStyle} src={this.state.isMenuVisible? MenuImgOpened : MenuImgClosed}/>
                    </span>
                    
                    <div style={lv1DivStyle}>

                        <div style={this.state.div1Hovering? lv2DivStyleHovered : lv2DivStyle}>
                            <p style={pStyle}
                                onClick={() => this.prepareEmailSending(p1Text)}
                                onMouseEnter={() => this.setHighlight(1, true)}
                                onMouseLeave={() => this.setHighlight(1, false)} >
                                {p1Text}
                            </p>
                        </div>
                        
                        <div style={this.state.div2Hovering? lv2DivStyleHovered : lv2DivStyle}>
                            <p style={pStyle}
                                onClick={() => this.prepareEmailSending(p2Text)}
                                onMouseEnter={() => this.setHighlight(2, true)}
                                onMouseLeave={() => this.setHighlight(2, false)} >
                                {p2Text}
                            </p>
                        </div>
                        
                        <div style={this.state.div3Hovering? lv2DivStyleHovered : lv2DivStyle}>
                            <p style={pStyle}
                                onClick={() => this.prepareEmailSending(p3Text)}
                                onMouseEnter={() => this.setHighlight(3, true)}
                                onMouseLeave={() => this.setHighlight(3, false)} >
                                {p3Text}
                            </p>
                        </div>

                        <div style={this.state.div4Hovering? lv2DivStyleHovered : lv2DivStyle}>
                            <p style={pStyle}
                                onClick={() => this.prepareEmailSending(p4Text)}
                                onMouseEnter={() => this.setHighlight(4, true)}
                                onMouseLeave={() => this.setHighlight(4, false)} >
                                {p4Text}
                            </p>
                        </div>

                        <div style={this.state.div5Hovering? lv2DivStyleHovered : lv2DivStyle}>
                            <p style={pStyle}
                                onClick={() => this.prepareEmailSending(p5Text)}
                                onMouseEnter={() => this.setHighlight(5, true)}
                                onMouseLeave={() => this.setHighlight(5, false)} >
                                {p5Text}
                            </p>
                        </div> 

                        <div style={this.state.div6Hovering? lv2DivStyleHovered : lv2DivStyle}>
                            <p style={pStyle}
                                onClick={() => this.prepareEmailSending(p6Text)}
                                onMouseEnter={() => this.setHighlight(6, true)}
                                onMouseLeave={() => this.setHighlight(6, false)} >
                                {p6Text}
                            </p>
                        </div>

                        <div style={this.state.div7Hovering? lv2DivStyleHovered : lv2DivStyle}>
                            <p style={pStyle}
                                onClick={() => this.prepareEmailSending(p7Text)}
                                onMouseEnter={() => this.setHighlight(7, true)}
                                onMouseLeave={() => this.setHighlight(7, false)} >
                                {p7Text}
                            </p>
                        </div>

                        <div style={this.state.div8Hovering? lv2DivStyleHovered : lv2DivStyle}>
                            <p style={pStyle}
                                onClick={() => this.prepareEmailSending(p8Text)}
                                onMouseEnter={() => this.setHighlight(8, true)}
                                onMouseLeave={() => this.setHighlight(8, false)} >
                                {p8Text}
                            </p>
                        </div>

                    </div>
                </span>
            </span>
            
        );
    }
}

export default SmallMenu;