import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import SweetAlert from 'react-bootstrap-sweetalert'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Button from 'components/CustomButtons/Button.js'
import Cancel from '@material-ui/icons/Cancel'
class SocietaCollegateEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      societaCollegateLivello: props.societaCollegate
        ? props.societaCollegate.livello
        : '',
      societaCollegateAnagraphy: props.societaCollegate
        ? props.societaCollegate.anagrafy
        : '',
      societaCollegateShare: props.societaCollegate
        ? props.societaCollegate.sharesPerc
        : '',
      anagrphyList: props.anagrphyList,
      societaCollegate: {}
    }
  }

  componentDidUpdate(propsPrecedenti){
      if(propsPrecedenti!=this.props){
            if(propsPrecedenti.societaCollegate && this.props.societaCollegate && propsPrecedenti.societaCollegate.livello!=this.props.societaCollegate.livello){
             this.setState({
                  societaCollegateLivello: this.props.societaCollegate.livello
                })
            }
            if(propsPrecedenti.societaCollegate && this.props.societaCollegate && propsPrecedenti.societaCollegate.sharesPerc!=this.props.societaCollegate.sharesPerc){
                     this.setState({
                          societaCollegateShare: this.props.societaCollegate.sharesPerc
                        })
            }
      }
  }
  handleFormChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }
  handleSubmit = event => {
    let societaCollegateAnagraphy = this.state.societaCollegateAnagraphy
    let societaCollegate = {}
    societaCollegate.country = societaCollegateAnagraphy
      ? societaCollegateAnagraphy.stato
      : ''
    societaCollegate.countryCode = ''
    societaCollegate.formaGiuridica = societaCollegateAnagraphy
      ? societaCollegateAnagraphy.forma_giuridica
      : ''
    societaCollegate.formaGiuridicaDesc = societaCollegateAnagraphy
      ? societaCollegateAnagraphy.descrizione_forma_giuridica
      : ''
    societaCollegate.indirizzo = societaCollegateAnagraphy
      ? societaCollegateAnagraphy.indirizzo
      : ''
    societaCollegate.livello = this.state.societaCollegateLivello
    societaCollegate.post_code = societaCollegateAnagraphy
      ? societaCollegateAnagraphy.post_code
      : ''
    societaCollegate.ragioneSociale = societaCollegateAnagraphy
      ? societaCollegateAnagraphy.ragione_sociale
      : ''
    societaCollegate.sharesPerc = this.state.societaCollegateShare

    this.props.addSocietaCollegate(societaCollegate)
    
    this.setState({
      societaCollegateLivello: '',
      societaCollegateAnagraphy: '',
      societaCollegateShare: ''
    })
  }

  render () {
    return (
      <div
        style={{
          display: 'flex',
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          marginBottom: '4px'
        }}
      >
        {!this.props.disabled ? (
          <FontAwesomeIcon
            color='green'
            icon={faPlusSquare}
            size='2x'
            style={{
              display: 'inline',
              float: 'left',
              paddingRight: '10px',
              paddingLeft: '6px',
              paddingTop: '6px'
            }}
           
          />
        ) : null}

        <div
          style={{
            float: 'left',
            display: 'flex',
            width: '100%',
            marginBottom: '4px',
            height: '32px'
          }}
        >
          <input
            id= {`societaCollegateLivello${this.props.index+""+this.props.indexParent}`}
            type='number'
            name='societaCollegateLivello'
            value={this.state.societaCollegateLivello}
            label='Livello'
            style={{
              width: '10%',
              marginLeft: '10px',
              marginTop: '8px',
              border: 'none',
              borderBottom: '1px solid #858585',
              height: '20px'
              //   minHeight: '40px'
            }}
            onChange={this.handleFormChange}
            disabled={this.props.disabled}
          />

          {!this.props.disabled ? (
            <Select
              id= {`societaCollegateAnagraphy${this.props.index+""+this.props.indexParent}`}
              labelId='demo-simple-select-label'
              label='societaCollegateAnagraphy'
              name='societaCollegateAnagraphy'
              value={this.state.societaCollegateAnagraphy}
              style={{
                width: '40%',
                marginLeft: '2%',
                marginTop: '9px',
                height: '20px'
              }}
              onChange={this.handleFormChange}
              // disabled={this.props.disabled}
            >
              {this.props.anagrphyList != null
                ? this.props.anagrphyList.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {value.ragione_sociale}
                      </MenuItem>
                    )
                  })
                : ''}
            </Select>
          ) : (
            <input
              name='societaCollegateAnagraphy'
              id= {`societaCollegateAnagraphy${this.props.index+""+this.props.indexParent}`}
              value={
                this.props.societaCollegateAnagraphy
                  ? this.props.societaCollegateAnagraphy.ragione_sociale
                  : null
              }
              label='societaCollegateAnagraphy'
              style={{
                width: '40%',
                marginLeft: '10px',
                marginTop: '8px',
                border: 'none',
                borderBottom: '1px solid #858585',
                height: '20px'
                //   minHeight: '40px'
              }}
              onChange={this.handleFormChange}
              disabled={this.props.disabled}
            />
          )}

          <input
            id= {`societaCollegateShare${this.props.index+""+this.props.indexParent}`}
            name='societaCollegateShare'
            value={this.state.societaCollegateShare}
            style={{
              width: '40%',
              marginLeft: '10px',
              border: 'none',
              borderBottom: '1px solid #858585',
              height: '20px',
              marginTop: '8px'
            }}
            label='%'
            onChange={this.handleFormChange}
            disabled={this.props.disabled}
          />

          {this.props.disabled && this.props.editMode ? (
            <IconButton id= {`deleteIcon${this.props.index+""+this.props.indexParent}`} color='secondary' aria-label='elimina' style={{float:"right",position:"relative"}}>
              <DeleteIcon
                size='2x'
                onClick={event =>
                  this.props.deleteSocietaCollegateAlert(
                    event,
                    this.props.index
                  )
                }
              />
            </IconButton>
          ) : null}

          {/* <FontAwesomeIcon
            color='green'
            icon={faSave}
            size='2x'
            style={{
              display: 'inline',
              float: 'left',
              paddingRight: '10px',
              paddingLeft: '6px',
              paddingTop: '6px'
            }}
          /> */}
           {!this.props.disabled && this.props.editMode && this.state.societaCollegateAnagraphy!='' && this.state.societaCollegateShare!='' && this.state.societaCollegateLivello!=''?  <SaveIcon id= {`saveIcon${this.props.index+""+this.props.indexParent}`} style={{color:"green",marginTop:"7px",float:"right",position:"relative",marginRight:"10px",marginLeft:"13px"}}
            onClick={this.handleSubmit}
           >

          </SaveIcon>: null}

{/* {!this.props.disabled && this.props.editMode ?<Button
           style={{ fontSize: '11px', margin: '6px 10px 0px 10px',width:"63px",height:"24px",borderRadius: '4px'}}
           color='success'
           round
          >
            Salva
          </Button>: null} */}

        </div>
      </div>
    )
  }
}
export default SocietaCollegateEditForm
