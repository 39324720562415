import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios"
import { axiosApiCaller } from 'layouts/AxiosService';
import { HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED } from 'layouts/AxiosService';
import * as AuthService from 'services/AuthService.js';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import Button from '@material-ui/core/Button';
import useRef from "react";
import { TextField } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import SweetAlert from "react-bootstrap-sweetalert";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";

class Documenti extends React.Component{

       constructor(props) {
        super(props);
        this.documentiInput=React.createRef();
        this.state=this.getInitialState();
        this.addDocumenti=this.addDocumenti.bind(this);
        this.onChangeDocumenti=this.onChangeDocumenti.bind(this);
        this.documentiList = [];
        // this.data = this.data(this.props.itemForDetail);
        this.deleteAlert=this.deleteAlert.bind(this);
        this.hideAlert=this.hideAlert.bind(this);
        this.deleteDocumenti=this.deleteDocumenti.bind(this);
        this.delete=this.delete.bind(this)


     }

      getInitialState() {
        return {
            documentiList:[],
            documenti:"",
            nome_documento:null,
        }
    
    }


componentDidMount(){
      axiosApiCaller.get(AuthService.postBackendApiDocumenti())
      
      .then((res) => {
         
        this.setState({ documentiList: res.data.payload })

        }).catch((error) => {
        });
}



deleteAlert(primaryMsg, secondaryMsg, key) {
        this.setState({
            alert:
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                                    color="danger"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <Cancel style={{ fontSize: '28px' }} />&nbsp;Annulla
                                     </Button>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                                    color="success"
                                    onClick={() => { this.deleteDoc(key) }}
                                    round>
                                    <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                                 </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
    }



    hideAlert(redirectToListView) {
        this.setState({ alert: null });
    }
    

delete(key) {

        this.deleteAlert("Sei sicuro di voler eliminare questo documento?", "", key)
    }


    deleteDocumenti(key,id){
        let docList = this.state.documentiList
        docList.splice(key, 1);
        this.setState({ documentiList: docList })
        let requestObject = this.state.documenti
        let headerWithAuth = AuthService.getAuthHeaderAsObj();

    axiosApiCaller.post(AuthService.deleteBackendApiDocumenti(),{"id":id}).then((res) => {
      
    }).catch((error) => {
    })
  
    }

    addDocumenti(event){
    let tempDocList=this.state.documentiList
    tempDocList.push({"nome_documento":this.state.documenti})
    this.setState({documentiList:tempDocList})
    this.documentiInput.current.value=""
    axiosApiCaller.post(AuthService.postBackendApiDocumenti()+ "?nome_documento="+this.state.documenti).then((res) => {
    }).catch((error) => {
    })
    }

onChangeDocumenti(event){
    let tempDocumenti=event.target.value;
    this.setState({documenti:tempDocumenti})

}
 
    render(){
    const { classes } = this.props;


    
       return(
                    <Card>
                        <CardHeader style={{textAlign:"center"}}>
                            <h3 className={classes.cardTitle}><b>Lista Documenti</b></h3>

                        </CardHeader>
                        <CardBody>
                     {this.state.documentiList.map((documenti, key) => {
                         
                       return <div style={{width:"50%",height:"40px",boxShadow: "0 1px 4px 0 rgb(0 0 0 / 10%)",marginBottom:"10px"}}><div nome_documento={key}> 
                     <p style={{paddingTop:"0px",display:"inline-block",paddingLeft:"15px"}}>{documenti.nome_documento}</p> 
                      <IconButton style={{width:"10px",float:"right",display:"inline-block",padding:"0px",paddingTop:"8px",paddingRight:"20px"}} color="secondary" aria-label="elimina"  >
                      <DeleteIcon onClick={(event) => this.deleteDocumenti(key, documenti.id)} />
                      </IconButton>
                       </div>
                      
                      </div>
                                })}
                        <Button
                  style={{ fontSize: '15px', margin: '0px 0px 0px 0px' }}
                  color="success"
                  onClick={
                      
                      (event) => this.addDocumenti(event)
                    }
                  round

                >
                <FontAwesomeIcon color="green" icon={faPlusSquare} size="3x" style={{ display: "inline", float: "left", paddingRight: "10px", paddingTop: "13px" }} />
                  </Button>
                  <input style={{ width: "auto", marginLeft: '5px',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={this.documentiInput}
                  onChange={(event)=>this.onChangeDocumenti(event)}>
                  </input>
                    </CardBody>
                    </Card>

        );

    }

    
    
}

export default withStyles(withStyles)(Documenti);