
export const LOGGED_USER_ITEM_NAME = 'loggedUser';
export const USER_APPLICATION_NAME = 'applicationName';
export const USER_APPLICATION_DESCRIPTION = 'applicationDescription';
export const AUTHORIZATION_JWT_TOKEN_PREFIX = 'Sintegra Analytics - ';
export const LOGGED_USER_JWT_TOKEN_ITEM_NAME = 'loggedToken';
export const LOGGED_USER_CURRENT_SESSION_ITEM_NAME = 'currentSession';
export const LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME = 'previousSession';
export const APPLICATION_DESCRIPTION = 'Administration Panel';
export const AUTHORIZATION_HEADER_NAME = 'Authorization';
export const TOKEN_REFRESH = 'TokenRefresh';
export const LOGGED_USER_JWT_TOKEN_REFRESH_ITEM_NAME = "loggedRefreshToken";
export const NULL_AUTHORIZATION_INFO = null;
export const SELECTED_APPLICATION_NAME = 'applicationName';
export const ADMINISTRATIVE_ROLE_NAME = 'Administration Panel Administrator';
export const COORDINATIVE_ROLE_NAME = 'TEE Coordinator';
export const OPERATIVE_ROLE_NAME = 'TEE Operator';
export const VOLKSBANK_ADMINISTRATIVE_ROLE_NAME = 'VOLKSBANK Administrator';
export const VOLKSBANK_COORDINATIVE_ROLE_NAME = 'VOLKSBANK Coordinator';
export const VOLKSBANK_OPERATIVE_ROLE_NAME = 'VOLKSBANK Operator';

export function isUserAuthenticathed() {
    if ((getLoggedUserObject() === NULL_AUTHORIZATION_INFO) ||
        (getLoggedUserJwtToken() === NULL_AUTHORIZATION_INFO)) {
        return false;
    } else {
        return true;
    }
}

export function setLoggedUserInfos(newUserInfo, newUserToken, jwtTokenRefresh) {
    this.setLoggedUserObject(newUserInfo);
    this.setLoggedUserJwtToken(newUserToken);
    this.setLoggedUserJwtTokenRefresh(jwtTokenRefresh);
}

export function setLoggedUserObject(newUserInfo) {
    localStorage.setItem(LOGGED_USER_ITEM_NAME, JSON.stringify(newUserInfo));
    if (newUserInfo.sessionHistory) {
        let counter = 0;
        newUserInfo.sessionHistory.forEach(sessionElement => {
            if (counter === 0) {
                this.setLoggedUserCurrentSession(sessionElement);
            } else if (counter === 1) {
                this.setLoggedUserPreviousSession(sessionElement);
            } else {
                return;
            }
            counter++;
        });
    }
}

export function setLoggedUserJwtToken(newUserToken) {
    localStorage.setItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME, AUTHORIZATION_JWT_TOKEN_PREFIX + newUserToken);
}

export function setLoggedUserJwtTokenRefresh(jwtTokenRefresh) {
    localStorage.setItem(LOGGED_USER_JWT_TOKEN_REFRESH_ITEM_NAME,AUTHORIZATION_JWT_TOKEN_PREFIX + jwtTokenRefresh);
}

export function setLoggedUserCurrentSession(userSession) {
    localStorage.setItem(LOGGED_USER_CURRENT_SESSION_ITEM_NAME, JSON.stringify(userSession));
}

export function setLoggedUserPreviousSession(userSession) {
    localStorage.setItem(LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME, JSON.stringify(userSession));
}

export function getLoggedUserObject() {
    if (localStorage.getItem(LOGGED_USER_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return JSON.parse(localStorage.getItem(LOGGED_USER_ITEM_NAME));
    }
}

export function getLoggedUserJwtToken() {
    if (localStorage.getItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return localStorage.getItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME);
    }
}

export function getLoggedUserJwtTokenRefresh(){
    if (localStorage.getItem(LOGGED_USER_JWT_TOKEN_REFRESH_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return localStorage.getItem(LOGGED_USER_JWT_TOKEN_REFRESH_ITEM_NAME);
    }      
}

export function getLoggedUserCurrentSession() {
    if (localStorage.getItem(LOGGED_USER_CURRENT_SESSION_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return JSON.parse(localStorage.getItem(LOGGED_USER_CURRENT_SESSION_ITEM_NAME));
    }
}

export function getLoggedUserPreviousSession() {
    if (localStorage.getItem(LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return JSON.parse(localStorage.getItem(LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME));
    }
}

export function clearLoggedUserInfos() {
    localStorage.removeItem(LOGGED_USER_ITEM_NAME);
    localStorage.removeItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME);
    localStorage.removeItem(LOGGED_USER_CURRENT_SESSION_ITEM_NAME);
    localStorage.removeItem(LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME);
    localStorage.clear();
}

export function getAuthHeaderName() {
    return AUTHORIZATION_HEADER_NAME;
}

export function getAuthHeaderAsObj() {
    let authHeaders = {};
    authHeaders["Accept"] = 'application/json';
    authHeaders["Content-Type"] = 'application/json';
    authHeaders[AUTHORIZATION_HEADER_NAME] = this.getLoggedUserJwtToken();
    authHeaders[TOKEN_REFRESH] = this.getLoggedUserJwtTokenRefresh(); 
    return authHeaders;
}

export function authenticathedUserCanManageCustomersScoring() {
    return authenticathedUserHasAtLeastOneRole([
        ADMINISTRATIVE_ROLE_NAME,
        VOLKSBANK_ADMINISTRATIVE_ROLE_NAME
    ]);
}

export function authenticathedUserCanManageUsers() {
    return authenticathedUserHasAtLeastOneRole([
        ADMINISTRATIVE_ROLE_NAME,
        VOLKSBANK_ADMINISTRATIVE_ROLE_NAME
    ]);
}

export function authenticathedUserCanViewUsers() {
    return authenticathedUserHasAtLeastOneRole([
        ADMINISTRATIVE_ROLE_NAME,
        COORDINATIVE_ROLE_NAME,
        VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
        VOLKSBANK_COORDINATIVE_ROLE_NAME
    ]);
}

export function authenticathedUserHasAtLeastOneRole(roles) {
    if ((isUserAuthenticathed() === false) || (!roles) || (roles === null)) {
        return false;
    } else {
        let currentUser = getLoggedUserObject();
        let hasAtLeastOne = false;
        if (currentUser.appRoleNames) {
            // Se ha almeno uno (OR) dei ruoli indicati...
            currentUser.appRoleNames.forEach(userRole => {
                roles.forEach(goodRole => {
                    if (userRole === goodRole) {
                        hasAtLeastOne = true;
                    }
                });
            });
            return hasAtLeastOne;
        } else {
            return false;
        }
    }
}

export function filterRoutesByLoggedRole(allRoutes) {
    let currentUser = getLoggedUserObject();
    let loggedRoles = currentUser.appRoleNames;
    let allowed = [];
    if (allRoutes) {
        allRoutes.forEach(currentRoute => {
            let currentRouteAllowed = false;
            if (currentRoute.roles) {
                currentRoute.roles.forEach(currentRouteAllowedRole => {
                    loggedRoles.forEach(currentLoggedRole => {
                        if (currentLoggedRole === currentRouteAllowedRole) {
                            currentRouteAllowed = true;
                            return;
                        }
                    });
                    if (currentRouteAllowed) {
                        return;
                    }
                });
            }
            if (currentRouteAllowed) {
                allowed.push(currentRoute);
            }
        });
    }
    return allowed;
}

export function getApplicationName() 
{
    if (localStorage.getItem(LOGGED_USER_ITEM_NAME) === null)
        return "ADMINISTRATION_PANEL";
    else
        return localStorage.getItem(SELECTED_APPLICATION_NAME);
}

export function getApplicationNameForLogin() {
    return "ADMINISTRATION_PANEL";
}

export function getApplicationDescription() {
    if (localStorage.getItem(LOGGED_USER_ITEM_NAME) === null) {
        return "ADMINISTRATION_PANEL";
    } else {
        return JSON.parse(localStorage.getItem(LOGGED_USER_ITEM_NAME)).appDescription;
    }
}

export function getBackendApiBaseUrl() {
    return process.env.REACT_APP_BACKEND_HOST;
}
export function getBackendApiLogin() {
    return process.env.REACT_APP_BACKEND_HOST + '/login';
}
export function getBackendApiLogout() {
    return process.env.REACT_APP_BACKEND_HOST + '/logout';
}
export function getBackendApiVerifyCode() {
    return process.env.REACT_APP_BACKEND_HOST + '/verify';
}
export function getApprofondimentoType() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/approfondimenti/approfondimento-types';
}
export function getApiElaborationsState() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/administration/get-all-elaborations-state-list';
}
export function postApiForUploadDocumentForApprofondimentoRequest() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/administration/upload';
}
export function getBackendApiForApprofondimentoRequestFileDownload() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/administration/download-file-of-approfondimento-request';
}
export function getApiForUploadDocumentForListApprofondimentoRequestDocuments() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/administration/list-file-of-approfondimento-request';
}
export function postApiForUpdateApprofondimentoRequest() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/administration/update-approfondimento-request';
}
export function postApiForDeleteListDocumentOfApprofondimentoRequest() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/administration/delete-list-document';
}
export function getBackendApiApplications() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/administration/get-all-application-list';
}
export function getBackendApiTeeApprofondimentiList() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/approfondimenti/filter-approfondimenti-requests';
}
export function getBackendApiApplicationLogin() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/authentication/app-login';
}
export function getBackendApiApplicationUsersRoles() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/get-all-by-application';
}
export function getBackendApiApplicationRoles() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/roles/get-all-by-application';
}
export function getBackendApiEnableUserByApplication() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/enable-by-application';
}
export function getBackendApiDisableUserByApplication() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/disable-by-application';
}
export function getBackendApiDeleteUserByApplication() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/remove-by-application';
}
export function getBackendApiAddUserToApplication() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/signup-by-application';
}
export function getBackendApiUserDetail() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/get-details';
}
export function getBackendApiEditUserToApplication() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/update-by-application';
}
export function getBackendApiEditProfileToApplication() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/edit-profile-by-application';
}
export function getBackendApiUtpSubjectList() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/scoring/utp/get-all-requested-by-application';
}
export function getBackendApiUtpPersonDetail() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/scoring/utp/get-last-processed-of-person';
}
export function getBackendApiUtpCompanyDetail() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/scoring/utp/get-last-processed-of-company';
}
export function getBackendApiTeeDiscoveredSubjects() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/get-all-discovered-subjects-from-requests';
}
export function getBackendApiTeeUsersSessions() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/users/sessions/get-by-application';
}
export function getBackendApiTeeNewRequest() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/new-request';
}
export function getBackendApiTeeNewFileRequest() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/new-file-request';
}
export function getBackendApiTeeAccountingList() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/accounting/get-requests';
}
export function getBackendApiTeeExternalSources() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee';
}
export function getBackendApiTeeRequestFileDownload() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/download-file-request';
}
export function getBackendApiTeeResponseFileDownload() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/download-file-results';
}
export function getBackendApiTeeRequestResult() {
    return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/search-in-request';
}
export function getBackendApiTeeDiscoveredJuridicalSubjectBoxUpdateUrl() {
    return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/update-juridical-subject-box-by-platformId-and-idRichiesta';
}
export function synchronizeRequest() {
    return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/synchronize-request';
}
export function getSuspendedReqAddress() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/process-suspended-requests';
}
export function downloadIncompleteRequests() {
    return (
        process.env.REACT_APP_BACKEND_HOST +
        "/data-analysis/internal/analysis/discovering/tee/download-incomplete-requests"
    );
}

export function getBackendApiForAnalysisRequestResultFileDownload() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/download-file-of-analysis-request-result-by-nameOnS3';
}

export function postApiForUploadDocumentForTeeJuridicalSubject() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/upload-document-on-analysis-request-result';
}

export function getAllTeeDiscoveredSubjectDocumentsByPlatformId() {
    return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/list-document-of-analysis-request-result-by-platformId';
}

export function postApiForDeleteDocumentsForTeeJuridicalSubject() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/delete-list-document';
}

export function getBackendApiForOutput(){
    // return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/output';
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/download-input-file-in-period-with-platformId";
  }

export function getBackendApiForSearchRobot(){
    // return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/output';
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/search-robot";
  }

export function getBackendApiForSearchRobotList(){
    // return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/output';
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/processed-crawling/get-all-processed";
  }
export function getBackendApiForSendMassiveJson(){
    return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/send-massive-json';
  }
  
export function getTipologyPieChartData(){
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/charts/tee/tipology-chart";
  } 

  export function postAnagraphy(){
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/anagraphy";
  } 

  export function updateAnagraphy(){
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/update-anagraphy";
  } 
  export function deleteAnagraphy(){
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/delete-anagraphy";
  } 
  export function getTeeDiscoveredSubjectJHistoryByPlatformIdAndIdRichiesta(){
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/get-teeDiscoveredSubject-by-platformId-idRichiesta";
  } 
  export function addSocietaCollegate(){
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/societaCollegate";
  }
  export function getGlobalList(){
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/get-global-list";
  }  

  
/*
export function getAuthHeaderAsString() {
    return 'Accept: application/json, ' +
           'Content-Type: application/json, ' +
           'Authorization' + ': ' + this.getLoggedUserJwtToken();
}
*/

/*
export function logOut() {
    localStorage.removeItem(LOGGED_USER_ITEM_NAME);
    localStorage.removeItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME);
    //return axios.post(BACKEND_API_BASE_URL + 'logout', {}, this.getAuthHeader());
}
*/

export function downloadInputWithPlatformId() {
    return (
        process.env.REACT_APP_BACKEND_HOST +
        "/data-analysis/internal/analysis/discovering/tee/download-input-file-with-platformId"
    );
}

export function getBackendApiForAnalysisRequestResultFileDownloadBase64() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/download-base64-file-of-analysis-request-result-by-nameOnS3';
}

export function getBackendApiForApprofondimentoRequestFileDownloadBase64() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/administration/download-file-of-approfondimento-request-base-64';
}


export function getBackendApiTeeSendJsonOnS3() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/output';
}

export function exportJson() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/sendJson';
}

export function getRefreshedToken (){
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/get-refreshed-token';
}
export function postBackendApiCariche() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/cariche';
    
}
export function deleteBackendApiCariche() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/delete-cariche';
    
}
export function addContract() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/contract/add-contract';
    
}
export function getContracts() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/contract/get-contracts'; 
}
export function getContractById() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/contract/get-contracts-with-contract-id';  
}
export function deleteContractById() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/contract/delete-contracts';
}

export function postBackendApiDocumenti() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/documenti';
    
}
export function deleteBackendApiDocumenti() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/delete-documenti';
    
}
export function updateTeeCollegate() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/update-tee-collegate';
    
}
export function getAllTeeDiscoveredSubjectsByPlatformId() {
    return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/get-teeDiscoveredSubjects-history-by-platformId';
}
export function ripristinoTeeDJS() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/ripristino-teeDJS';  
}
export function confermaTeeDJS() {
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/conferma-teeDJS';  
}
export function getBackendApiForReloadPositions(){
    // return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/output';
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/reload-positions";
  }

  export function postApiForDownloadDocumentForTeeJuridicalSubject(){
    return process.env.REACT_APP_BACKEND_HOST+ "/data-analysis/internal/analysis/discovering/tee/generate-and-upload-document-on-analysis-request-result";

  }