import withStyles from "@material-ui/core/styles/withStyles";
import PersonPin from "@material-ui/icons/PersonPin";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import List from "@material-ui/icons/ViewList";
import CustomTabs from "components/COMMON/Tabs/CustomTabs";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import ContractEdit from "views/COMMON/ContractEdit";
import ContractList from "views/COMMON/ContractList";
const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "26px",
    fontWeight: "450",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

export const CONTRACT_LIST_TAB_INDEX = 0;
export const CONTRACT_CREATE_TAB_INDEX = 1;
export const CONTRACT_UPDATE_TAB_INDEX = 2;
class Contract extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.customTabsRef = React.createRef();


  }
  getInitialState() {
    return {
      selectedTab: (CONTRACT_LIST_TAB_INDEX),
    }

  }

  getDynamicTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Dettagli contratto",
        tabIcon: PersonPin,
        tabContent: (
          <ContractEdit readOnly={!this.state.currentLoggedUserCanManageUsers}
            createOrModify={false}
            editLogged={false}
            customTabsRef={this.customTabsRef} />
        )
      }
    );
    return tabs;
  }

  getActiveTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Lista contratti",
        tabIcon: List,
        tabContent: (
          <ContractList readOnly={!this.state.currentLoggedUserCanManageUsers} customTabsRef={this.customTabsRef} />
        )
      }
    );

    tabs.push(
      {
        tabName: "Crea nuovo Contratto",
        tabIcon: PlaylistAddIcon,
        tabContent: (
          <ContractEdit readOnly={false}
            createOrModify={true}
            editLogged={true}
            customTabsRef={this.customTabsRef} />
        )
      }
    );


    return tabs;
  }




  formatSessionDateTime(dateTime) {
    var date = new Date(dateTime);
    return new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(date);
  }

  render() {
    return (
      <GridContainer>



        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs style={styles.cardTitleWhite}
            selectedTabIndex={this.state.selectedTab}
            headerColor="info"
            tabs={this.getActiveTabs()}
            dynamicTabs={this.getDynamicTabs()}
            showDynamicTabs={this.state.showUserUpdateTab}
            itemForDynamicTabs={this.state.selectedUserForEdit}
            ref={this.customTabsRef}
          />
        </GridItem>
      </GridContainer>
    );
  }
}
export default withStyles(withStyles)(Contract);