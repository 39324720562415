import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { VisibilityRounded } from "@material-ui/icons";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import styles from "assets/jss/material-dashboard-pro-react/components/accordionStyle.js";
import PDFViewer from 'pdf-viewer-reactjs';
import PropTypes from "prop-types";
import React, { useEffect } from "react";









const useStyles = makeStyles(styles);

export default function DocumentiAccordion(props) {
    const [active, setActive] = React.useState(props.active);
    const [editMode, setEditMode] = React.useState(props.editMode);
    const handleChange = panel => (event, expanded) => {
        setActive(expanded ? panel : -1);
    };
    const [isListaDocumentiChanged, setIsListaDocumentiChanged] = React.useState(props.isListaDocumentiChanged);
    const classes = useStyles();
    //let { collapses } = props;
    let [value, setValue] = React.useState(props.collapses);
    const [selectedItem, setSelectedItem] = React.useState();
    let [pdfData, setPdfData] = React.useState(props.pdfData);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        setValue(props.collapses);
        if (props.pdfData !== pdfData) {
            setPdfData(props.pdfData);
        }
    }, [props.isListaDocumentiChanged, props.pdfData]); // checks for changes in the values in this array

    function deleteDocumento(documento) {
        let nuovoArray = [];
        value.forEach(element => {
            if (element.nameOnS3 !== documento.nameOnS3) {
                nuovoArray.push(element);
            }
        });
        setValue(value = nuovoArray); // update the state to force render
        props.onChange(value, documento);
    };

    function visualizzaDocumento(documento, index) {
        setPdfData('');
        if (index === selectedItem) {
            setSelectedItem(-1);
            //setPdfData('');
        } else {
            setSelectedItem(index);
        }
        props.click(value, documento);
    };

    function downloadDocumento(documento, index) {
        setPdfData('');
        if (index === selectedItem) {
            setSelectedItem(-1);
            //setPdfData('');
        } else {
            setSelectedItem(index);
        }
        props.download(value, documento);
    };

    function stoppaPropagazione(event) {
        event.preventDefault();
    }

    return (
        <div className={classes.root}>
            {value.map((prop, key) => {
                if (prop) {
                    if (!props.editMode) {
                        return (
                            <div className={classes.root}>
                                <List component="nav" aria-label="main mailbox folders">
                                    <ListItem button onClick={() => { prop?.filename.endsWith("pdf") ? visualizzaDocumento(prop, key) : downloadDocumento(prop, key) ;  }}>

                                        <ListItemIcon >
                                            {prop?.filename.endsWith("pdf") ? <VisibilityRounded /> : <CloudDownloadIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={(prop.filename)+(prop.tipo_documento != null && prop.tipo_documento != ''?"     ("+prop.tipo_documento +")":'')} secondary={(prop.dataCaricamento != null && prop.dataCaricamento != '' ? (new Date(Date.parse(prop.dataCaricamento)).toLocaleString()) : '-')}/>
                                        {prop.filename?.endsWith("pdf") ?
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => { downloadDocumento(prop, key); }} edge="end" aria-label="download">
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction> : null}
                                    </ListItem>
                                </List>
                                <div onClick={stoppaPropagazione}><ViewPDF selectedIndex={selectedItem} currentIndex={key} base64String={pdfData} /></div>
                            </div>
                        );
                    } else {
                        return (
                            <div className={classes.root}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <DescriptionIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={(prop.filename)+(prop.tipo_documento != null && prop.tipo_documento != ''?"     ("+prop.tipo_documento +")":'')}
                                            secondary={prop.dataCaricamento != null && prop.dataCaricamento != '' ? (new Date(Date.parse(prop.dataCaricamento)).toLocaleString()) : '-'}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton color="secondary" edge="end" aria-label="delete" onClick={() => { deleteDocumento(prop); }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </div>
                        );
                    }
                }
            })}
        </div>
    );
}

const ViewPDF = ({ base64String, selectedIndex, currentIndex }) => {
    if (currentIndex === selectedIndex) { //show only for the selected item
        if (base64String) {
            return <PDFViewer
                document={{
                    base64: base64String
                }}
                loader="CARICAMENTO IN CORSO"
            />
        }
    }
    return null;
}

DocumentiAccordion.defaultProps = {
    active: -1
};

DocumentiAccordion.propTypes = {
    // index of the default active collapse
    active: PropTypes.number,
    editMode: PropTypes.bool
};



