import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import Business from "@material-ui/icons/Business";
import EditIcon from '@material-ui/icons/Edit';
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import PropTypes from 'prop-types';
import React from 'react';
import * as AuthService from 'services/AuthService.js';
import CommonCustomIcon from "views/COMMON/svgicons/CommonCustomIcon.js";
import * as Dashboard from 'views/TEE/Dashboard';








const localStyles = {
    icon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "21px",
        height: "21px",
        top: "0px",
        left: "2px",
        position: "relative"
    },
    selectIcon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "21px",
        height: "21px",
        top: "0px",
        marginLeft: "1px",
        marginRight: "6px",
        position: "relative"
    },
    actionButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        width: "30px",
        height: "30px",
        minWidth: "auto",
        fontSize: "20px",
    },
    dialogConfirmButton: {
        margin: "0 0 0 5px",
        padding: "20px",
        width: "80px",
        height: "auto",
        minWidth: "auto"
    },
    dialogCancelButton: {
        margin: "0 0 0 5px",
        padding: "7px",
        width: "auto",
        height: "auto",
        minWidth: "auto"
    },
    actionIconButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        minWidth: "auto",
        fontSize: "20px",
        background: "none",
        cursor: "pointer"
    },
}

const styles = {
    ...localStyles,
    ...extendedTablesStyle,
    ...customSelectStyle
}

class FilterableApprofondimentiList extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        let initialState = {
            currentLoggedUserCanManageVisualizeResult: (AuthService.authenticathedUserCanManageUsers()),
            //tableData: [],
            message: '',
            alert: null
        };
        return initialState;
    }

    
    componentWillMount() {
    }

    componentDidMount() {
        
    }


formatSessionDateTime(dateTime) {
  var date = new Date(dateTime);
  return (new Intl.DateTimeFormat("it", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }).format(date));
}

    render() {
        let filteredDataTable = this.extractList();
        const { classes } = this.props;


        let tableHead = this.props.headersList;

        let actionButtonsColumnClasses = [];
        let actionButtonsColumn = [];
        let actionButtonsColumnHeaderClasses = [];
        let actionButtonsColumnHeader = [];
        return (
            <div style={{ width: "99%", marginLeft: "3px" }}>

                <Divider style={{ width: '98%' }} />
                <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Table
                            tableHeaderColor="info"
                            tableHead={tableHead}
                            tableData={filteredDataTable}
                            customCellClasses={actionButtonsColumnClasses}
                            customClassesForCells={actionButtonsColumn}
                            customHeadCellClasses={actionButtonsColumnHeaderClasses}
                            customHeadClassesForCells={actionButtonsColumnHeader}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

    doActionForEnabledUsers(event, buttonIndex, descriptKeyForActions, objKeyForActions) {
        let isControlPressed = false;
        if (event) {
            if (event.ctrlKey) {
                isControlPressed = true;
            }
        }
        let type = null;
        let icon = null;
        let key = null;
            type = Dashboard.PERSONA_GIURIDICA_LABEL;
            icon = Business;

        if (objKeyForActions.id) {
            key = objKeyForActions.id;
        }
        this.props.customTabsRef.current.showDynamicTab(key, descriptKeyForActions, icon, objKeyForActions, isControlPressed);
    }


    getButtonsGroupForEnabledUsers(descriptKeyForActions, objKeyForActions, strategia, dataInserimento) {
        return (
            <Tooltip
                id="tooltip-x"
                title="Visualizza dettagli della posizione"
                placement="bottom"
                classes={styles.tooltip}
            >
                <div>
                    <a
                        style={localStyles.actionIconButton}
                        onClick={(e) => { this.doActionForEnabledUsers(e, 1, descriptKeyForActions, objKeyForActions) }}
                    >
                        <CommonCustomIcon icon={"Posizione"} />
                    </a>
                    <div style={{ color: 'black', fontSize: '12px' }}>{dataInserimento ? ((new Date(dataInserimento)).toLocaleDateString()) : ""}</div>
                </div>
            </Tooltip>
        );
    }

    convertCustomersListToTableData(jsonUsersData,fields) {
        let descriptiveKeyForActions = '';
        let objectKeyForActions = null;
        let idRequest = null;
        let retArray = [];
        jsonUsersData.forEach(element => {
            objectKeyForActions = element;
            descriptiveKeyForActions = (element.id && element.id!=null) ? element.id : null;
            descriptiveKeyForActions += (element.applicazione &&  element.applicazione!=null)? ' (' + element.applicazione + ')': '';
            
            let singleArray = [];

            let fieldsList=this.props.fieldsList;
            fieldsList.forEach(field => {
                singleArray.push((element[field]) ? element[field] : '');
            });
            singleArray.push(this.addEditButton( descriptiveKeyForActions, objectKeyForActions) );
            retArray.push(singleArray);

        });
        return retArray;
    }

    addEditButton(descriptKeyForActions, objKeyForActions){
        return ( 
            <div>
                <IconButton aria-label="edit" onClick={(e) => { this.doActionForEnabledUsers(e, 1, descriptKeyForActions, objKeyForActions) }}>
                <EditIcon />
                </IconButton>
            </div>
        );
    }

    filterList() {
        let allItemsList = this.props.itemsList;
        let filteredList = [];
        if ((allItemsList !== null) && (allItemsList.length > 0)) {
            let listLength = allItemsList.length;
            for (let item of allItemsList) {
                let filterInItem = true;

                if(this.props.showGeoName==null || this.props.showTipologiaName==null){
                    filterInItem=true;
                }else{
                if (this.props.showMonitored === false) {
                    if (item.monitoraggio != "NO") {
                        filterInItem = filterInItem && false;
                    } else {
                        filterInItem = filterInItem && true;
                    }
                }
                if (this.props.showNotMonitored === false) {
                    if (item.monitoraggio == "NO") {
                        filterInItem = filterInItem && false;
                    } else {
                        filterInItem = filterInItem && true;
                    }
                }
                //if (this.state.filterType !== null) {

                    if (this.props.showGeoName.toUpperCase() === (Dashboard.ALL_CITTA_LABEL).toUpperCase()) {
                        filterInItem = filterInItem && true;
                    } else if (item.paeseProvincia != null) {
                        if (item.paeseProvincia.toUpperCase() === this.props.showGeoName.toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    } else {
                        filterInItem = filterInItem && false;
                    }
                    //END MANAGER GEO FILTER

                    if (this.props.showTipologiaName.toUpperCase() === (Dashboard.ALL_TIPOLOGIA_LABEL).toUpperCase()) {
                        filterInItem = filterInItem && true;
                    } else if (item.monitoraggioType != null) {
                        if (item.monitoraggioType.toUpperCase() === this.props.showTipologiaName.toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    } else {
                        filterInItem = filterInItem && false;
                    }
                    }
                //}//END FILTER
                if (filterInItem) {
                    filteredList.push(item);
                }
            }
        }
        return filteredList;
    }


    extractList() {
        let dataForTable = this.convertCustomersListToTableData(this.filterList());
        //this.setState({tableData: dataForTable, message: ''});  
        return dataForTable;
    }


}

FilterableApprofondimentiList.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    customTabsRef: PropTypes.object.isRequired,
    itemsList: PropTypes.arrayOf(
        PropTypes.shape({
            personaFisica: PropTypes.bool,
            ragioneSociale: PropTypes.string,
            codiceFiscale: PropTypes.string,
            comune: PropTypes.string,
            dataCaricamento: PropTypes.string,
            statoLavorazione: PropTypes.string,
            monitoraggioAttivo: PropTypes.bool,
            strategia: PropTypes.string
        })
    ).isRequired,
    showMonitored: PropTypes.bool.isRequired,
    showNotMonitored: PropTypes.bool.isRequired,
    showGeoName: PropTypes.string.isRequired,
    showTipologiaName: PropTypes.string.isRequired,
    showStatoName: PropTypes.string.isRequired,
    showStrategiaName: PropTypes.string.isRequired
};

export default withStyles(styles)(FilterableApprofondimentiList);