import Divider from '@material-ui/core/Divider';
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import Business from "@material-ui/icons/Business";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import PropTypes from 'prop-types';
import React from 'react';
import SmallMenu from "views/COMMON/SmallMenu";
import CommonCustomIcon from "views/COMMON/svgicons/CommonCustomIcon.js";
import * as Dashboard from 'views/TEE/Dashboard';

const localStyles = {
    icon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "21px",
        height: "21px",
        top: "0px",
        left: "2px",
        position: "relative"
    },
    selectIcon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "21px",
        height: "21px",
        top: "0px",
        marginLeft: "1px",
        marginRight: "6px",
        position: "relative"
    },
    actionButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        width: "30px",
        height: "30px",
        minWidth: "auto",
        fontSize: "20px",
    },
    dialogConfirmButton: {
        margin: "0 0 0 5px",
        padding: "20px",
        width: "80px",
        height: "auto",
        minWidth: "auto"
    },
    dialogCancelButton: {
        margin: "0 0 0 5px",
        padding: "7px",
        width: "auto",
        height: "auto",
        minWidth: "auto"
    },
    actionIconButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        minWidth: "auto",
        fontSize: "20px",
        background: "none",
        cursor: "pointer"
    },
    completedRound: {
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        background:"#34a853",
        float:"left",
        marginTop:"4px",
        marginRight:"4px"
    },waitingRound:{
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        background:"grey",
        float:"left",
        marginTop:"4px",
        marginRight:"4px"
    },
    warningRound: {
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        background:"#fba706",
        float:"left",
        marginTop:"4px",
        marginRight:"4px"
    },
    errorRound: {
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        background:"#ea4335",
        float:"left",
        marginTop:"4px",
        marginRight:"4px"
    },
    partialRound: {
        background:"grey",
        maxWidth:"130px",
        padding:"10px"
    }
}

const styles = {
    ...localStyles,
    ...extendedTablesStyle,
    ...customSelectStyle
}

class FilterableMultiCustomersList extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        let initialState = {
            message: '',
            alert: null
        };
        return initialState;
    }

formatSessionDateTime(dateTime) {
  var date = new Date(dateTime);
  return (new Intl.DateTimeFormat("it", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }).format(date));
}

    render() {
        let filteredDataTable = this.extractList();
        const { classes } = this.props;


        let tableHead = this.props.headersList;

        let actionButtonsColumnClasses = [];
        let actionButtonsColumn = [];
        let actionButtonsColumnHeaderClasses = [];
        let actionButtonsColumnHeader = [];


        return (
            <div style={{ width: "99%", marginLeft: "3px" }}>

                <Divider style={{ width: '98%' }} />
                <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Table
                            tableHeaderColor="info"
                            tableHead={tableHead}
                            tableData={filteredDataTable}
                            customCellClasses={actionButtonsColumnClasses}
                            customClassesForCells={actionButtonsColumn}
                            customHeadCellClasses={actionButtonsColumnHeaderClasses}
                            customHeadClassesForCells={actionButtonsColumnHeader}
                            order={this.props.order}
                            orderBy={this.props.orderBy}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

    doActionForEnabledUsers(event, buttonIndex, descriptKeyForActions, objKeyForActions) {
        let isControlPressed = false;
        if (event) {
            if (event.ctrlKey) {
                isControlPressed = true;
            }
        }
        let type = null;
        let icon = null;
        let key = null;
            type = Dashboard.PERSONA_GIURIDICA_LABEL;
            icon = Business;

        if (objKeyForActions.ragioneSociale) {
            key = objKeyForActions.ragioneSociale;
        }
        this.props.customTabsRef.current.showDynamicTab(key, descriptKeyForActions, icon, objKeyForActions, isControlPressed);
    }

    formatSessionDateTime(dateTime) {
        var date = new Date(dateTime);
        if (date instanceof Date && isFinite(date)) {
            return (new Intl.DateTimeFormat("it", {
                year: "numeric",
                month: "numeric",
                day: "numeric"
            }).format(date));
        } else {
            return dateTime;
        }
    }

    getButtonsGroupForEnabledUsers(descriptKeyForActions, objKeyForActions, strategia, dataInserimento) {
        return (
            <Tooltip
                id="tooltip-x"
                title="Visualizza dettagli della posizione"
                placement="bottom"
                classes={styles.tooltip}
            >
                <div>
                    <a
                        style={localStyles.actionIconButton}
                        onClick={(e) => { this.doActionForEnabledUsers(e, 1, descriptKeyForActions, objKeyForActions) }}
                    >
                        <CommonCustomIcon icon={"Posizione"} />
                    </a>
                    <div style={{ color: 'black', fontSize: '12px' }}>{dataInserimento ? (this.formatSessionDateTime(dataInserimento)) : ""}</div>
                </div>
            </Tooltip>
        );
    }

    getIconStatoLavorazione(statoLavorazione) {
        return (
            <div>
                <CommonCustomIcon icon={statoLavorazione} />
            </div>
        );
    }

    convertCustomersListToTableData(jsonUsersData,fields) {
        let descriptiveKeyForActions = '';
        let objectKeyForActions = null;
        let retArray = [];
        jsonUsersData.forEach(element => {
            objectKeyForActions = element;
            descriptiveKeyForActions = (element.ragioneSociale && element.ragioneSociale!=null)? element.ragioneSociale: element.idSoggetto;
            descriptiveKeyForActions += (element.paeseProvincia &&  element.paeseProvincia!=null)? ' (' + element.paeseProvincia + ')': '';

            let singleArray = [];
                singleArray.push(this.getButtonsGroupForEnabledUsers(descriptiveKeyForActions, objectKeyForActions, element.strategia, (element.dataCaricamento != null || element.dataCaricamento != undefined) ? element.dataCaricamento : element.dataAggiornamento));



            let fieldsList=this.props.fieldsList;
            
            fieldsList.forEach(field => {
                if(field=="ragioneSociale"){
                    singleArray.push(<span>{element[field]}</span> );
                }else if(field=="elaborationState"){
                    if(element[field]=="COMPLETATA"){
                        singleArray.push(
                                <div><div style={localStyles.completedRound}></div><div style={{float:'left',marginRight:'2px', color:"#34a853"}}> <b>{element[field]}</b></div></div>
                        );
                    }else
                    if(element[field]=="APPROFONDIRE"){
                        singleArray.push( (typeof(element.note)) != "undefined" && element.note != null  && element.note != ""? 
                            <Tooltip
                                id="tooltip-note"
                                title={element.note}
                                placement="bottom"
                                classes={styles.tooltip}
                            >
                                <div style={{paddingBottom:"5px"}}><div style={localStyles.warningRound}></div><div style={{float:'left',marginRight:'2px', color:"#fba706"}}> <b> {element[field]}</b></div></div>
                            </Tooltip>
                            :
                            <div><div style={localStyles.warningRound}></div><div style={{float:'left',marginRight:'2px', color:"#fba706"}}> <b>{element[field]}</b></div></div>
                        );


                    }else
                    if(element[field]=="ERRORE"){
                        singleArray.push( (typeof(element.note)) != "undefined" && element.note != null  && element.note != ""?
                            <Tooltip
                                id="tooltip-note"
                                title={element.note}
                                placement="bottom"
                                classes={styles.tooltip}
                            >
                                <div style={{paddingBottom:"5px"}}><div style={localStyles.errorRound}></div><div style={{float:'left',marginRight:'2px', color:"#ea4335"}}>  <b>{element[field]}</b></div></div>
                            </Tooltip>
                            :
                            <div><div style={localStyles.errorRound}></div><div style={{float:'left',marginRight:'2px', color:"#ea4335"}}> <b>{element[field]}</b></div></div>
                            );
                    }else
                    if(element[field]=="ANNULLATA"){
                        singleArray.push( (typeof(element.note)) != "undefined" && element.note != null  && element.note != ""?
                            <Tooltip
                                id="tooltip-note"
                                title={element.note}
                                placement="bottom"
                                classes={styles.tooltip}
                            >
                                <div style={{paddingBottom:"5px"}}><div style={localStyles.errorRound}></div><div style={{float:'left',marginRight:'2px', color:"#ea4335"}}>  <b>{element[field]}</b></div></div>
                            </Tooltip>
                            :
                            <div><div style={localStyles.errorRound}></div><div style={{float:'left',marginRight:'2px', color:"#ea4335"}}> <b>{element[field]}</b></div></div>
                            );
                    }else
                    if(element[field]=="SOSPESA"){
                        singleArray.push( (typeof(element.note)) != "undefined" && element.note != null  && element.note != ""?
                            <Tooltip
                                id="tooltip-note"
                                title={element.note}
                                placement="bottom"
                                classes={styles.tooltip}
                            >
                                <div style={{paddingBottom:"5px"}}><div style={localStyles.errorRound}></div><div style={{float:'left',marginRight:'2px', color:"#FFD700"}}>  <b>{element[field]}</b></div></div>
                            </Tooltip>
                            :
                            <div><div style={localStyles.warningRound}></div><div style={{float:'left',marginRight:'2px', color:"#FFD700"}}> <b>{element[field]}</b></div></div>
                            );
                    }else
                    if(element[field]=="ELABORAZIONE"){
                        singleArray.push( (typeof(element.note)) != "undefined" && element.note != null  && element.note != ""?
                            <Tooltip
                                id="tooltip-note"
                                title={element.note}
                                placement="bottom"
                                classes={styles.tooltip}
                            >
                                <div style={{paddingBottom:"5px"}}><div style={localStyles.partialRound}></div><div style={{float:'left',marginRight:'2px', color:"#B2B2B2"}}>  <b>{element[field]}</b></div></div>
                        </Tooltip>
                        :
                        <div style={{minWidth: "140px"}}><div style={localStyles.waitingRound}></div><div style={{float:'left',marginRight:'2px', color:"#B2B2B2"}}> <b>{element[field]}</b></div></div>
                        );
                    }else{
                        singleArray.push(element[field]);
                        }
                }else if(field=="menu"){
                    singleArray.push(<div>
                        <span style={{paddingLeft: "50px"}}><SmallMenu ragioneSociale = {element.ragioneSociale} /></span>
                        <span style={{display:"inline-block", width:"210px"}}></span>
                        </div> );
                }
                else{
                    singleArray.push((element[field]) ? element[field] : '');
                }

            });
            retArray.push(singleArray);

        });
        return retArray;//<SmallMenu ragioneSociale = {element.ragioneSociale} />
    }

    filterList() {
        let allItemsList = this.props.itemsList;
        let filteredList = [];
        if ((allItemsList !== null) && (allItemsList.length > 0)) {
            let listLength = allItemsList.length;
            for (let item of allItemsList) {
                let filterInItem = true;

                if(this.props.showGeoName==null || this.props.showTipologiaName==null){
                    filterInItem=true;
                }else{
                if (this.props.showMonitored === false) {
                    if (item.monitoraggio != "NO") {
                        filterInItem = filterInItem && false;
                    } else {
                        filterInItem = filterInItem && true;
                    }
                }
                if (this.props.showNotMonitored === false) {
                    if (item.monitoraggio == "NO") {
                        filterInItem = filterInItem && false;
                    } else {
                        filterInItem = filterInItem && true;
                    }
                }
                //if (this.state.filterType !== null) {

                    if (this.props.showGeoName.toUpperCase() === (Dashboard.ALL_CITTA_LABEL).toUpperCase()) {
                        filterInItem = filterInItem && true;
                    } else if (item.paeseProvincia != null) {
                        if (item.paeseProvincia.toUpperCase() === this.props.showGeoName.toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    } else {
                        filterInItem = filterInItem && false;
                    }
                    //END MANAGER GEO FILTER

                    if (this.props.showTipologiaName.toUpperCase() === (Dashboard.ALL_TIPOLOGIA_LABEL).toUpperCase()) {
                        filterInItem = filterInItem && true;
                    } else if (item.monitoraggioType != null) {
                        if (item.monitoraggioType.toUpperCase() === this.props.showTipologiaName.toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    } else {
                        filterInItem = filterInItem && false;
                    }
                    }
                    
                    //END MANAGER TIPOLOGIA FILTER


                    if(typeof this.props.showStatoName != 'undefined'){
                        if (this.props.showStatoName.toUpperCase() === (Dashboard.ALL_STATI_LABEL).toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else if (item.elaborationState != null) {
                            if (item.elaborationState.toUpperCase() === this.props.showStatoName.toUpperCase()) {
                                filterInItem = filterInItem && true;
                            } else {
                                filterInItem = filterInItem && false;
                            }
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    }
                    //END MANAGER STATE FILTER
                    
                //}//END FILTER
                if (filterInItem) {
                    filteredList.push(item);
                }
            }
        }
        return filteredList;
    }


    extractList() {
        let dataForTable = this.convertCustomersListToTableData(this.filterList());
        //this.setState({tableData: dataForTable, message: ''});  
        return dataForTable;
    }


}

FilterableMultiCustomersList.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    customTabsRef: PropTypes.object.isRequired,
    itemsList: PropTypes.arrayOf(
        PropTypes.shape({
            personaFisica: PropTypes.bool,
            ragioneSociale: PropTypes.string,
            codiceFiscale: PropTypes.string,
            comune: PropTypes.string,
            dataCaricamento: PropTypes.string,
            statoLavorazione: PropTypes.string,
            monitoraggioAttivo: PropTypes.bool,
            strategia: PropTypes.string
        })
    ).isRequired,
    showMonitored: PropTypes.bool.isRequired,
    showNotMonitored: PropTypes.bool.isRequired,
    showGeoName: PropTypes.string.isRequired,
    showTipologiaName: PropTypes.string.isRequired,
    showStatoName: PropTypes.string.isRequired,
    showStrategiaName: PropTypes.string.isRequired
};

export default withStyles(styles)(FilterableMultiCustomersList);