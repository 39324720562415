import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from "@material-ui/core/styles/withStyles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from "@material-ui/icons/Search";
import ToggleOn from "@material-ui/icons/ToggleOn";
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import axios from "axios";
import FilterableApprofondimentiManagement from "components/COMMON/FilterableApprofondimentiManagement";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { axiosApiCaller, HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED } from 'layouts/AxiosService';
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from 'react-router-dom';
import * as AuthService from 'services/AuthService.js';
import Swal from 'sweetalert2';









const localStyles = {
    icon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "23px",
        height: "23px",
        top: "0px",
        left: "2px",
        position: "relative"
    },
    actionButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        width: "30px",
        height: "30px",
        minWidth: "auto",
        fontSize: "20px"
    },
    dialogConfirmButton: {
        margin: "0 0 0 5px",
        padding: "20px",
        width: "80px",
        height: "auto",
        minWidth: "auto"
    },
    dialogCancelButton: {
        margin: "0 0 0 5px",
        padding: "7px",
        width: "auto",
        height: "auto",
        minWidth: "auto"
    }
}

const styles = {
    ...localStyles,
    ...dashboardStyles,
    ...checkBoxStyles
}

// COSTANTI PER I VALORI NON DEFINITI O NULLI
export const NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE = '';
export const NULL_STRATEGIA_SUBSTITUTION_VALUE = 'Nessuna Strategia';
export const NULL_STATO_SUBSTITUTION_VALUE = 'Nessun Stato di Elaborazione';

const AUTO_CLOSE_TIME_DENIED_OPERATION = 4000;

class ApprofondimentiListPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.lastRefreshTime = new Date();
        this.onKeyUp = this.onKeyUp.bind(this);
        this.setCurrentIncompletedReqList = this.setCurrentIncompletedReqList.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.handleShowMonitoredChange = this.handleShowMonitoredChange.bind(this);
        this.deniedOperation = this.deniedOperation.bind(this);
        this.handleApprofondimentoTypeChange = this.handleApprofondimentoTypeChange.bind(this);
        this.handleElaborationStateChange = this.handleElaborationStateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.getApprofondimentoTypes();
        this.extractAllStatiApprofondimento();
    }

    onKeyUp(event) {
        if (event.charCode === 13) {
            this.extractAllApprofondimentiListFromBackend();
        }
    }

    getInitialState() {
        return {
            alert: null,
            usersSessionsList: [],
            customersList: [],
            currentIncompletedRequestList: [],
            currentIncompletedRequestId: "",
            incompletesReqDivIsVisible: false,
            usersSessionsChartLabels: [],
            usersSessionsChartSeries: [],
            usersSessionsChartMax: 100,
            currentMinutesRefreshDelay: 0,
            currentLoggedUserCanManageVisualizeResult: (AuthService.authenticathedUserCanManageUsers()),
            forzaInvio: false,
            startDate: null,
            endDate: null,
            creatoreForFiltering: null,            
            listApprofondimenti: [],
            selectedApprofondimento: null,            
            listStato: [],
            selectedStato: null,
        };
    }

    hideAlert() {
        this.setState({ alert: null });
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.extractAllApprofondimentiListFromBackend();
    }

    componentWillUnmount() {
    }


    handleChangeName(event) {
        let newValue = event.target.value.trim();
        this.setState({ name: newValue });
        if (newValue != null) {
            if (newValue.length < 3) {
                this.setState({ nameValid: false });
                this.setState({ errorMessage: 'La lunghezza della denominazione non è valida' });
            } else {
                this.setState({ nameValid: true });
                this.setState({ errorMessage: null });
            }
        } else {
            this.setState({ nameValid: false });
            this.setState({ errorMessage: 'La denominazione non può essere vuota' });
        }
    }
    handleShowMonitoredChange(event) {
        this.setState({ showMonitored: event.target.checked });
        if (event.target.checked) {
            this.showMonitoredLabel = "Includi le posizioni monitorate";
        } else {
            this.showMonitoredLabel = "Escludi le posizioni monitorate";
        }
    };


    handleFiltering(filter) {
    }

    resetFilters() {
        this.setState(
            {
                showMonitored: true
            }
        );
    }

    render() {
        const { classes } = this.props;
        const uploadDivStyle = { margin: "40px 0px 40px 8px", paddingBottom: "28px", background: "white", boxShadow: "0px 0px 6px rgb(210, 210, 210)", borderRadius: "7px" };
        const uploadDivHeaderStyle = { margin: "auto", position: "relative", bottom: "16px", width: "97%", height: "45px", background: "linear-gradient(to right, rgb(140, 177, 73),rgb(192, 213, 149))", borderRadius: "4px" };
        const pHeaderStyle = { paddingTop: "10px", color: "white", fontSize: "15px", fontWeight: "450", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", textAlign: "center" };
        //Attenzione il bottone dettaglio non viene passato nella lista dei fields
        //let headersList=["Dowload file", "Responses", "ID Request","State","Creation Date", "Last Modified","Requests Number","Results Number", "Results"];
        let headersList;
        if (this.state.currentLoggedUserCanManageVisualizeResult) {
            headersList = ["Applicazione", "Stato", "Data caricamento", "Creatore", "Tipo approfondimento", "Azienda"];
        } else {
            headersList = ["Applicazione", "Stato", "Data caricamento", "Creatore", "Tipo approfondimento", "Azienda"];
        }
        //let fieldsList=["clickableId","applicazione",  "elaborationState","creationDatetime","creatore","tipo", "responseDownload"];
        let fieldsList = ["applicazione", "elaborationState", "creationDatetime", "creatore", "tipo", "businessName"];
        

        let showMonitoredControl = null;
        if (this.state.showMonitored === true) {
            showMonitoredControl = (<a href="#"><ToggleOn onClick={(event) => this.handleShowNotMonitoredChange(event)} style={{ float: 'right', margin: '0px 0px 0px 0px', width: '34px', height: '34px' }} /></a>);
        }

        let incompletesDivStyle = {
            display: this.state.incompletesReqDivIsVisible ? "block" : "none"
        }

        const incompletesHeaderStyle = {
            margin: "auto",
            position: "relative",
            bottom: "16px",
            width: "97%",
            height: "45px",
            background: "linear-gradient(to right, rgb(23, 105, 255),rgb(156, 191, 255))",
            borderRadius: "4px"
        };

        let annulledList = this.state.currentIncompletedRequestList.filter(req => req.elaborationState == "ANNULLATA")
        let rowsAnnulledList = [];
        rowsAnnulledList = annulledList.map(req =>
            <tr>
                <td style={{ paddingBottom: "10px" }}>{req.ragioneSociale == null || req.ragioneSociale == "" ? <b style={{ color: "red" }}>MISSING</b> : req.ragioneSociale}</td>&nbsp;&nbsp;&nbsp;
                <td style={{ paddingBottom: "10px" }}>{req.customerId == null || req.ragioneSociale == "" ? <b style={{ color: "red" }}>MISSING</b> : req.customerId}</td>&nbsp;&nbsp;&nbsp;
                <td style={{ paddingBottom: "10px" }}>{req.elaborationMessage}</td>&nbsp;&nbsp;&nbsp;
            </tr>
        );

        let annulledReqTable = <table style={{ fontSize: "0.9em" }}>
            <tr>
                <th style={{ paddingBottom: "14px" }}>Ragione Sociale</th>&nbsp;&nbsp;&nbsp;
                <th style={{ paddingBottom: "14px" }}>Customer ID</th>&nbsp;&nbsp;&nbsp;
                <th style={{ paddingBottom: "14px" }}>Causa Annullamento</th>&nbsp;&nbsp;&nbsp;
            </tr>
            {rowsAnnulledList}
        </table>


        let suspendedList = this.state.currentIncompletedRequestList.filter(req => req.elaborationState == "SOSPESA")
        let rowsSuspendedList = [];
        rowsSuspendedList = suspendedList.map(req =>
            <tr>
                <td style={{ paddingBottom: "10px" }}>{req.ragioneSociale == null || req.ragioneSociale == "" ? <b style={{ color: "red" }}>MISSING</b> : req.ragioneSociale}</td>&nbsp;&nbsp;&nbsp;
                <td style={{ paddingBottom: "10px" }}>{req.paeseProvincia == null || req.paeseProvincia == "" ? <b style={{ color: "red" }}>MISSING</b> : req.paeseProvincia}</td>&nbsp;&nbsp;&nbsp;
                <td style={{ paddingBottom: "10px" }}>{req.localita == null || req.localita == "" ? <b style={{ color: "red" }}>MISSING</b> : req.localita}</td>&nbsp;&nbsp;&nbsp;
                <td style={{ paddingBottom: "10px" }}>{req.indirizzo == null || req.indirizzo == "" ? <b style={{ color: "red" }}>MISSING</b> : req.indirizzo}</td>&nbsp;&nbsp;&nbsp;
            </tr>
        );

        let suspendedReqTable = <table style={{ fontSize: "0.9em" }}>
            <tr>
                <th style={{ paddingBottom: "14px" }}>Ragione Sociale</th>&nbsp;&nbsp;&nbsp;
                <th style={{ paddingBottom: "14px" }}>Paese</th>&nbsp;&nbsp;&nbsp;
                <th style={{ paddingBottom: "14px" }}>Località</th>&nbsp;&nbsp;&nbsp;
                <th style={{ paddingBottom: "14px" }}>Indirizzo</th>&nbsp;&nbsp;&nbsp;
            </tr>
            {rowsSuspendedList}
        </table>

        let anulledRequestCommentP = <p style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "0.95em", fontWeight: "bold" }}>
            Non sono presenti richieste annullate
                                    </p>
        let suspendedRequestCommentP = <p style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "0.95em", fontWeight: "bold" }}>
            Non sono presenti richieste sospese
                                    </p>
        return (
            <div>
                {this.state.alert}

                <div style={incompletesDivStyle} className="incompletes-div-style">

                    <div style={incompletesHeaderStyle}>
                        <p style={pHeaderStyle}>LISTA RICHIESTE INCOMPLETE</p>
                    </div>

                    <p style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "1.03em", fontWeight: "bold", textAlign: "center" }}>RICHIESTE ANNULLATE:</p>
                    {annulledList.length > 0 ? annulledReqTable : anulledRequestCommentP}

                    <br />

                    <p style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "1.03em", fontWeight: "bold", textAlign: "center" }}>RICHIESTE SOSPESE:</p>
                    {suspendedList.length > 0 ? suspendedReqTable : suspendedRequestCommentP}


                    <br />
                    <div style={{ display: suspendedList.length > 0 ? "block" : "none" }}>
                        <p style={{ textAlign: "center", fontSize: "0.9em", fontWeight: "bold" }}>
                            Vuoi processare le richieste sospese nonostante i dati siano incompleti?
                        </p>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Button
                                style={{ marginLeft: "70px", fontSize: '13px' }}
                                color="secondary"
                                onClick={() => { this.setState({ incompletesReqDivIsVisible: false }) }}
                                round>
                                Chiudi&nbsp;
                            </Button>
                            &nbsp;&nbsp;&nbsp;

                            <Button
                                disabled={this.state.forzaInvio}
                                style={{ marginRight: "70px", fontSize: '13px' }}
                                color="behance"
                                onClick={() => {
                                    this.sendSuspendedRequests(this.state.currentIncompletedRequestId)
                                    this.setState({ forzaInvio: true })
                                }}
                                round>
                                Forza Invio&nbsp;
                             </Button>
                        </div>
                    </div>
                    <div style={{ display: suspendedList.length > 0 ? "none" : "block", textAlign: "center" }}>
                        <Button
                            style={{ fontSize: '13px' }}
                            color="secondary"
                            onClick={() => { this.setState({ incompletesReqDivIsVisible: false }) }}
                            round>
                            Chiudi&nbsp;
                            </Button>
                    </div>
                </div>
                {/*
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <div style={uploadDivStyle}>
                            <div style={uploadDivHeaderStyle}>
                                    <p style={pHeaderStyle}>CARICAMENTO FILE SOCI ED EXECUTIVES</p>
                            </div>      
                            <div style={{textAlign: "center"}}>
                                <Button 
                                    style={{marginRight: "25px", fontSize: '15px'}}                   
                                    color="linkedin"
                                    onClick={() => { this.uploadAndSend("-partners") }}
                                    round>
                                    Upload Soci&nbsp;&nbsp;&nbsp;<UploadFile style={{fontSize: '28px'}} />
                                </Button>
                                
                                
                                <Button 
                                    style={{marginLeft: "25px", fontSize: '15px'}}                   
                                    color="linkedin"
                                    onClick={() => { this.uploadAndSend("-executives") }}
                                    round>
                                    Upload Executives&nbsp;&nbsp;&nbsp;<UploadFile style={{fontSize: '28px'}} />
                                </Button> 
                            </div>           
                        </div>
                    </GridItem>
                 </GridContainer> 

                */}

                <GridContainer>
                    <FormControl fullWidth
                        onKeyPress={this.onKeyUp}>
                        <GridItem xs={12} sm={12} md={12} lg={12}
                            style={{ fontSize: '15px', margin: '0px 30px 0px 30px' }}>
                            <CustomInput
                                success={this.state.nameValid == true}
                                error={this.state.nameValid == false}
                                labelText="Creatore"
                                inputProps={{
                                    value: this.state.creatoreForFiltering,
                                    disabled: (this.props.readOnly),
                                    type: "text",
                                    onChange: event => { this.handleApprofondimentoCreatorChange(event) }
                                }}
                                customStyle={{ width: '200px' }}
                            />
                            <FormControl style={{ width: '18%', paddingTop: '11px', paddingLeft: '3%' }} className={classes.formControl}>
                                <InputLabel style={{ paddingTop: '8px', marginLeft: '16%' }} id="select-approfondimento">Tipo Approfondimento</InputLabel>
                                <Select
                                    labelId="select-approfondimento-type"
                                    id="select-approfondimento-type"
                                    value={this.state.selectedApprofondimento}
                                    onChange={this.handleApprofondimentoTypeChange}
                                >
                                    <MenuItem key={-1} value="TUTTI">TUTTI</MenuItem>
                                    {this.state.listApprofondimenti.map((e, keyIndex) => {
                                        return (<MenuItem key={keyIndex} value={e.name}>{e.name}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl style={{ width: '18%', paddingTop: '11px', paddingLeft: '3%' }} className={classes.formControl}>
                                <InputLabel style={{ paddingTop: '8px', marginLeft: '16%' }} id="select-approfondimento">Stato Approfondimento</InputLabel>
                                <Select
                                    labelId="select-stato-approfondimento"
                                    id="select-stato-approfondimento"
                                    value={this.state.selectedStato}
                                    onChange={this.handleElaborationStateChange}
                                >
                                    <MenuItem key={-1} value="TUTTI">TUTTI</MenuItem>
                                    {this.state.listStato.map((e, keyIndex) => {
                                        return (<MenuItem key={keyIndex} value={e}>{e}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                style={{marginTop: '11px', marginLeft: '3%', width:'140px'}}
                                id="start-date-picker-dialog"
                                label="Data di inizio"
                                format="dd/MM/yyyy"
                                value={this.state.startDate}
                                /*
                                onChange={this.handleStartDateChange}
                                */
                               onChange={date => this.handleStartDateChange(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                style={{marginTop: '11px', marginLeft: '3%', width:'140px'}}
                                id="end-date-picker-dialog"
                                label="Data di fine"
                                format="dd/MM/yyyy"
                                value={this.state.endDate}
                               onChange={date => this.handleEndDateChange(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            </MuiPickersUtilsProvider>
                            <Button
                                style={{ fontSize: '15px', margin: '0px 20px 0px 10px' }}
                                color="success"
                                onClick={() => { this.extractAllApprofondimentiListFromBackend() }}
                                onK
                                round>
                                Filtra&nbsp;&nbsp;&nbsp;<SearchIcon style={{ fontSize: '28px' }} />
                            </Button>
                        </GridItem>
                    </FormControl>
                    <GridItem xs={12} sm={12} md={12}>
                        <FilterableApprofondimentiManagement
                            allItemsList={this.state.customersList}
                            headersList={headersList}
                            fieldsList={fieldsList}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    handleStartDateChange(date){   
        this.setState({ startDate: date });
    }

    handleEndDateChange(date){ 
        this.setState({ endDate: date });
    }

    handleApprofondimentoTypeChange(event) {
        this.setState({ selectedApprofondimento: event.target.value });
    }

    handleElaborationStateChange(event) {
        this.setState({ selectedStato: event.target.value });
    }

    handleApprofondimentoCreatorChange(event) {
        this.setState({ creatoreForFiltering: event.target.value });
    }

    deniedOperation(primaryMsg, secondaryMsg) {
        this.setState({
            alert:
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                                    color="success"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                    </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
        setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
    }

    sendSuspendedRequests(suspendedReqId) {
        let address = AuthService.getSuspendedReqAddress();

        axiosApiCaller.get(address, {
            params:
            {
                idRequest: suspendedReqId,
                applicationName: AuthService.getApplicationName()
            }
        }).then((res) => {
            console.log(res.data);
            return window.location.href = 'storico-richieste';

        })
            .catch((error) => {
                console.log(error);
            });
    }

    extractAllStatiApprofondimento() {
        axiosApiCaller.get(AuthService.getApiElaborationsState())
            .then((res) => {
                if (res.data.success === true) {
                    this.setState({ listStato: res.data.payload });
                } else {
                    console.log('ERROR: ' + res.data.message);
                }

            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
            });
    }

    getApprofondimentoTypes() {
        axiosApiCaller.get(AuthService.getApprofondimentoType())
            .then((res) => {
                if (res.data.success === true) {
                    this.setState({ listApprofondimenti: res.data.payload });
                } else {
                    console.log('ERROR: ' + res.data.message);
                }

            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
            });
    }

    extractAllApprofondimentiListFromBackend() {
        let querySearch = this.state.name; //querySearch è quello che ha inserito l'utente in alto a sx (il periodo)
        let approfondimentiRequestFilter = {};
        let appName = AuthService.getApplicationName();
        approfondimentiRequestFilter.applicationName = appName;
        approfondimentiRequestFilter.user = this.state.creatoreForFiltering;
        approfondimentiRequestFilter.startDate = this.state.startDate;
        approfondimentiRequestFilter.endDate = this.state.endDate;
        approfondimentiRequestFilter.elaborationState = this.state.selectedStato;
        if(this.state.selectedApprofondimento === "TUTTI"){
            approfondimentiRequestFilter.approfondimentoType = null;
        } else{
            approfondimentiRequestFilter.approfondimentoType = this.state.selectedApprofondimento;
        }
        if(this.state.selectedStato === "TUTTI"){
            approfondimentiRequestFilter.elaborationState = null;
        } else{
            approfondimentiRequestFilter.elaborationState = this.state.selectedStato;
        }
        //approfondimentiRequestFilter.approfondimentoType = this.state.selectedApprofondimento;

        axiosApiCaller.post(AuthService.getBackendApiTeeApprofondimentiList(), approfondimentiRequestFilter)
            .then((res) => {//il then/catch è quello della vecchia fetch, va cambiato (eventualmente)
                if (res.data.success === true) {
                    console.log('POSITION LIST SUCCESSFULLY........');
                    console.log(res.data.payload);
                    //let customers = this.cleanData(res.data.payload);
                    let customers = this.formatData(res.data.payload);
                    customers.forEach(element => {
                        element.creatore = element.creator != null ? (element.creator.firstName + " " + element.creator.lastName) : NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                        element.tipo = element.approfondimentoType != null ? (element.approfondimentoType.name) : NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                        element.applicazione = element.application != null ? (element.application.name) : NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    });
                    //customers = this.setModifyButton(customers);
                    /*
                    if(this.state.currentLoggedUserCanManageVisualizeResult){
                        customers = this.setRequestClickable(customers);
                    }
                    */
                    this.setState({ customersList: customers });
                    this.resetFilters();
                    this.setState({ currentMinutesRefreshDelay: 0 });
                    this.lastRefreshTime = new Date();
                    setInterval(() => {
                        this.setState({ currentMinutesRefreshDelay: Math.floor((((new Date()) - this.lastRefreshTime) / 1000) / 60) });
                    }, 30001);
                    this.hideAlert();
                } else {
                    console.log('FLAG ERROR ON RESPONSE: ' + res.data.message);
                    this.deniedOperation('Impossibile visualizzare le informazioni riguardanti gli approfondimenti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');
                }
            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
                this.deniedOperation('Impossibile visualizzare le informazioni riguardanti gli approfondimenti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
            });
    }

    // CLEAN DATA FROM NULL VALUE
    cleanData(rawData) {
        let cleanedData = [];
        if (!rawData || (rawData == null)) {
            return cleanedData;
        }
        rawData.forEach(rawElement => {
            if (!rawElement.ragioneSociale || (rawElement.ragioneSociale == null)) {
                rawElement.ragioneSociale = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
            }
            if (!rawElement.indirizzo || (rawElement.indirizzo == null)) {
                rawElement.indirizzo = "";
            }
            if (!rawElement.localita || (rawElement.localita == null)) {
                rawElement.localita = "";
            }
            if (!rawElement.cap || (rawElement.cap == null)) {
                rawElement.cap = "";
            }
            if (!rawElement.paeseProvincia || (rawElement.paeseProvincia == null)) {
                rawElement.paeseProvincia = "";
            }
            if (!rawElement.partitaIva || (rawElement.partitaIva == null)) {
                rawElement.partitaIva = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
            }
            if (!rawElement.dataAggiornamento || (rawElement.dataAggiornamento == null)) {
                rawElement.dataAggiornamento = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                rawElement.aggiornato = 'No';
            } else {
                var d1 = Date.parse(rawElement.dataAggiornamento);
                if (AuthService.getLoggedUserPreviousSession() != null && AuthService.getLoggedUserPreviousSession().startTime != null) {
                    var d2 = Date.parse(AuthService.getLoggedUserPreviousSession().startTime);
                    rawElement.aggiornato = d1 > d2 ? 'Si' : 'No';
                } else {
                    rawElement.aggiornato = 'Si';
                }
                rawElement.dtAggiornamento = this.formatSessionDateTime(rawElement.dataAggiornamento);
            }
            if (!rawElement.monitoraggio || (rawElement.monitoraggio == null)) {
                rawElement.monitoraggio = "No";
            }

            if (!rawElement.foreignActualOwners || (rawElement.foreignActualOwners == null)) {
                rawElement.foreignActualOwnersCount = 0;
            } else {
                rawElement.foreignActualOwnersCount = rawElement.foreignActualOwners.length;
                rawElement.foreignActualOwners.forEach(ownerElement => {
                    if (!ownerElement.name || (ownerElement.name == null)) {
                        ownerElement.name = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.role || (ownerElement.role == null)) {
                        ownerElement.role = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.dateOfBirth || (ownerElement.dateOfBirth == null)) {
                        ownerElement.dateOfBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.appointedNotifiedOn || (ownerElement.appointedNotifiedOn == null)) {
                        ownerElement.appointedNotifiedOn = "";
                    }
                    if (!ownerElement.nationality || (ownerElement.nationality == null)) {
                        ownerElement.nationality = "";
                    }
                    if (!ownerElement.countryOfResidence || (ownerElement.countryOfResidence == null)) {
                        ownerElement.countryOfResidence = "";
                    }
                    if (!ownerElement.societaCollegate || (ownerElement.societaCollegate == null)) {
                        ownerElement.societaCollegate = "";
                    }
                    if (!ownerElement.note || (ownerElement.note == null)) {
                        ownerElement.note = "";
                    }
                });
            }
            cleanedData.push(rawElement);
        });
        return cleanedData;
    }

    formatData(list) {
        for (let i = 0; i < list.length; i++) {
            list[i].creationDatetime = this.formatSessionDateTime(list[i].creationDatetime);
            list[i].lastEditDatetime = this.formatSessionDateTime(list[i].lastEditDatetime);
        }
        // list[0].creationDatetime = this.formatSessionDateTime(list[0].creationDatetime);
        // list[0].lastEditDatetime = this.formatSessionDateTime(list[0].lastEditDatetime);

        return list;
    }

    formatSessionDateTime(dateTime) {
        //console.log(JSON.stringify(dateTime));
        var date = new Date(dateTime);
        return (new Intl.DateTimeFormat("it", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
        }).format(date));
    }

    setRequestAndResponseNumber(list) {
        for (let i = 0; i < list.length; i++) {
            //add requestsNumber and responsesNumber
            list[i].requestsNumber = list[i].requestBody.length;

            //i have to take just the completed requests.
            let completedRequests = list[i].responseBody.filter(elem => elem.elaborationState == "COMPLETATA" || elem.elaborationState == "APPROFONDIRE" || elem.elaborationState == "ERRORE" || elem.elaborationState == "ELABORAZIONE");
            list[i].completedRequests = completedRequests.length;
            let incompletedRequests = list[i].responseBody.filter(elem => elem.elaborationState == "SOSPESA" || elem.elaborationState == "ANNULLATA");
            list[i].incompletedRequests = incompletedRequests.length;
        }
        return list;
    }

    setIncompleteRequestButton(list) {
        for (let i = 0; i < list.length; i++) {
            list[i].incompletedRequestButton = <div style={{ minWidth: "140px" }}>
                {/* <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{list[i].incompletedRequests}</div> */}
                <span>{list[i].incompletedRequests}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Button
                    style={{ fontSize: '10px' }}
                    color="info"
                    size="sm"
                    onClick={() => { this.setCurrentIncompletedReqList(list[i].responseBody, list[i].id) }}
                    round>
                    Visualizza
                                                    </Button>
            </div>
        }

        return list;
    }

    setCurrentIncompletedReqList(list, id) {
        let incompletedReqList = list.filter(req => req.elaborationState == "SOSPESA" || req.elaborationState == "ANNULLATA")

        this.setState({ currentIncompletedRequestList: incompletedReqList, currentIncompletedRequestId: id }, () => {

            this.setState({ incompletesReqDivIsVisible: true })
        });
    }

    setRequestClickable(list) {

        for (let i = 0; i < list.length; i++) {

            list[i].clickableId = <Link to={{ pathname: '/consolle/request-result', state: { idRequest: list[i].id } }}>
                <span>{list[i].id}</span>
            </Link>
        }

        return list;
    }

    setModifyButton(list) {
        for (let i = 0; i < list.length; i++) {
            list[i].responseDownload = <IconButton color="primary"
                aria-label="modify" onClick={() => { this.modifyApprofondimento(list[i].id) }}>
                <CreateIcon />
            </IconButton>
        }
        return list;
    }

    modifyApprofondimento(idApprofondimento) {
        console.log("Approfondimento selezionato " + idApprofondimento);
    }

    getFileWithResults(idRequest) {
        axios.get(AuthService.getBackendApiTeeResponseFileDownload() + "?idRequest=" + idRequest,
            {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/octet-stream',
                    'Authorization': AuthService.getLoggedUserJwtToken(),
                    'TokenRefresh' : AuthService.getLoggedUserJwtTokenRefresh()
                }
            })
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                //document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
    }

    formatSessionDateTime(dateTime) {
        //console.log(JSON.stringify(dateTime));
        var date = new Date(dateTime);
        return (new Intl.DateTimeFormat("it", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
        }).format(date));
    }

    uploadAndSend(contenutoFile) {
        (async () => {

            const { value: file } = await Swal.fire({
                title: 'Upload file...',
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Upload',
                reverseButtons: true,
                input: 'file',
                inputAttributes: {
                    // 'accept': 'text/*',
                    'accept': '.xlsx',
                    'aria-label': 'Upload file'
                }
            })

            if (file) {

                let msg1 = "Attenzione";
                let msg2 = "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

                this.showTimeNoticeAlert(msg1, msg2);
                let formData = new FormData();
                formData.append("file", file);

                let headerWithAuth = AuthService.getAuthHeaderAsObj();
                headerWithAuth["Content-Type"] = "multipart/form-data";

                let address3 = AuthService.getBackendApiTeeExternalSources() + "/file-upload" + contenutoFile;
                axios.post(address3, formData, {
                    headers: headerWithAuth
                })
                    .then(res => {
                        if (res.data.success === true) {
                            console.log('il campo success è true ');
                            let title = res.data.message;
                            let message = '';
                            if (this.state.alert !== null) {
                                this.hideAlert();
                            }
                            this.showRedirectAlert(title, message);
                        } else {
                            console.log('il campo success è false');
                        }
                    })
                    .catch((error) => {
                        console.log('cluasola catch')
                        console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                        console.log(JSON.stringify(error.message));
                        if(error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
                            AuthService.setLoggedUserJwtToken(null);
                            return window.location.href = '/home'; 
                        }    
                    });

            }
        })()

    }

    showTimeNoticeAlert(primaryMsg, secondaryMsg) {
        this.setState({
            alert:
                <SweetAlert
                    info
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>

                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                                    color="success"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                </Button>

                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
    }

    showRedirectAlert(primaryMsg, secondaryMsg) {
        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Link to='/consolle/storico-richieste'>
                                    <Button
                                        style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                                        color="success"
                                        onClick={() => { this.hideAlert() }}
                                        round>
                                        <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                </Button>
                                </Link>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
    }

}



export default withStyles(styles)(ApprofondimentiListPage);
