import React from 'react';
import * as AuthService from 'services/AuthService.js';

const AuthContext = React.createContext();

class AuthProvider extends React.Component {

  constructor(props) {
    super();
    //this.state={isAuth: props.isAuth};
    //this.state={isAuth: false};
    this.state = { isAuth: AuthService.isUserAuthenticathed() };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  login() {
    //setTimeout(() => this.setState({ isAuth: true }), 1000)
    this.setState({ isAuth: true });
  }

  logout() {
    this.setState({ isAuth: false });
  }

  componentDidMount() {
    this.setState({ isAuth: AuthService.isUserAuthenticathed() });
  }
  componentWillUnmount() {
    this.setState({ isAuth: AuthService.isUserAuthenticathed() })
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}


const AuthConsumer = AuthContext.Consumer;

export { AuthConsumer, AuthProvider }