
import Description from "@material-ui/icons/Description";
import Anagrafica from 'assets/img/TEE/anagrafica.svg';
import Logo from 'assets/img/TEE/logo.svg';
import Posizione from 'assets/img/TEE/posizione.svg';
import Processo from 'assets/img/TEE/processo.svg';
import Soci from 'assets/img/TEE/soci.svg';
import React from "react";

function CommonCustomIcon(props) {

    const scoringImages = [
        { name: 'Description', icon: Description, "scoreType": 0, "id": 1 },
        { name: 'Logo', icon: Logo, "scoreType": 0, "id": 2 },
        { name: 'Soci', icon: Soci, "scoreType": 0, "id": 3 },
        { name: 'Processo', icon: Processo, "scoreType": 0, "id": 4 },
        { name: 'Posizione', icon: Posizione, "scoreType": 0, "id": 5 },
        { name: 'Anagrafica', icon: Anagrafica, "scoreType": 0, "id": 6 },

    ];


    function getScoringImage(icon) {
        for (var i = 0; i < scoringImages.length; i++) {
            if (icon == scoringImages[i].name) {
                return scoringImages[i].icon;
            }
        }
    }

    function getSizeImage(type) {
        if (type != null && type == "large") {
            return { width: '230px', height: '69px' };
        }
        return { width: '140px', height: 'auto' };
    }

    return (
        <img src={getScoringImage(props.icon)}  style={getSizeImage(props.type)} />
    );

}

export default CommonCustomIcon;