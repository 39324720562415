import AccountBox from "@material-ui/icons/AccountBox";
import CustomTabs from "components/COMMON/Tabs/CustomTabs";
import UserEdit from "components/COMMON/Users/UserEdit";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "26px",
    fontWeight: "450",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

export const USER_PROFILE_TAB_INDEX = 0;

class ProfileEditing extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.customTabsRef = React.createRef();
  }

  getInitialState() {
    return {
      selectedTab: (USER_PROFILE_TAB_INDEX)
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }


  getDynamicTabs() {
    let tabs = [];
    return tabs;
  }

  getActiveTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Modifica profilo personale",
        tabIcon: AccountBox,
        tabContent: (
          <UserEdit readOnly={false} 
                    createOrModify={false}
                    editLogged={true}
                    customTabsRef={this.customTabsRef}/>
        )
      }
    );    
    return tabs;
  }

 
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>   
          <CustomTabs style={styles.cardTitleWhite}
            selectedTabIndex={this.state.selectedTab}
            headerColor="primary"
            tabs={this.getActiveTabs()}
            dynamicTabs={this.getDynamicTabs()}
            showDynamicTabs={false}
            itemForDynamicTabs={null}
            ref={this.customTabsRef}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default ProfileEditing;
