import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "context/AuthContext";

function PrivateRoute({ component: Component, ...rest }) {
  return(
    <AuthConsumer>
      {({ isAuth }) => (
        <Route
          render={props =>
            isAuth ? <Component {...props} /> : <Redirect to="/login" />
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
}

export default PrivateRoute;