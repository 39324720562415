import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Edit from "@material-ui/icons/Edit";
import Pageview from "@material-ui/icons/Pageview";
import Work from "@material-ui/icons/Work";
import WorkOff from "@material-ui/icons/WorkOff";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import { axiosApiCaller } from 'layouts/AxiosService';
import PropTypes from 'prop-types';
import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import * as AuthService from 'services/AuthService.js';
import * as UserManagementTabs from 'views/COMMON/UsersManagement.js';








const localStyles = {
  icon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "23px",
    height: "23px",
    top: "0px",
    left: "2px",
    position: "relative"
  },
  actionButton: {
    margin: "5px 5px 5px 5px",
    padding: "0px",
    width: "30px",
    height: "30px",
    minWidth: "auto",
    fontSize: "20px"
  },  
  dialogConfirmButton: {
    margin: "0 0 0 5px",
    padding: "20px", 
    width: "80px",
    height: "auto",
    minWidth: "auto"
  },
  dialogCancelButton: {
    margin: "0 0 0 5px",
    padding: "7px", 
    width: "auto",
    height: "auto",
    minWidth: "auto"
  }
}

const styles = {
  ...localStyles,
  ...extendedTablesStyle
}


class UsersList extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.warningWithConfirmMessage = this.warningWithConfirmMessage.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
  }

  getInitialState() {
    return {usersTableData: [], 
            currentLoggedUser: AuthService.getLoggedUserObject(),
            currentLoggedToken: AuthService.getLoggedUserJwtToken(),
            currentAppName: AuthService.getApplicationName(),
            message: '',
            alert: null};
  }

  componentDidMount() {
    this.extractAllUsersList();  
  }

  componentWillUnmount() {
  }

  warningWithConfirmMessage (rowKeyForActions)  {
    let confirmationText = "Sei sicuro di voler eliminare l'utente '" + rowKeyForActions + "' ?"
    this.setState({alert: 
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "140px" }}
        title={confirmationText}
        customButtons={
          <React.Fragment>
              <div style={{margin: 'auto', textAlign: "center"}}>   
                <Button 
                  style={{fontSize: '13px', margin: '0px 30px 0px 0px'}}                   
                  color="warning"
                  onClick={() => { this.hideAlert() }}
                  round>
                    <Cancel style={{fontSize: '28px'}} />&nbsp;Annulla
                </Button>               
                <Button 
                  style={{fontSize: '13px', margin: '0px 0px 0px 0px'}}                   
                  color="success"
                  onClick={() => { this.deleteUser(rowKeyForActions) }}
                  round>
                    <CheckCircle style={{fontSize: '28px'}} />&nbsp;Conferma
                </Button>                                            
              </div>
          </React.Fragment>
        }
      >
        L'utente non sarà più associato all'applicazione. Per ripristinarlo occorrerà rivolgersi all'amministratore della piattaforma.<br/>
      </SweetAlert>
    });     
  };

  
  hideAlert () {
    this.setState({alert: null});  
  };

  successDelete ()  {
    let confirmationText = "Eliminazione dell'utente completata con successo"
    this.setState({alert: 
      <SweetAlert
        success
        style={{ display: "block", marginTop: "140px" }}
        title={confirmationText}
        customButtons={
          <React.Fragment>
              <div style={{margin: 'auto', textAlign: "center"}}>                
                <Button 
                  style={{fontSize: '13px', margin: '0px 0px 0px 0px'}}                   
                  color="success"
                  onClick={() => { this.hideAlert() }}
                  round>
                    <CheckCircle style={{fontSize: '28px'}} />&nbsp;Ok
                </Button>                                            
              </div>
          </React.Fragment>
        }
      >
        L'utente non è più associato all'applicazione. Per ripristinarlo occorrerà rivolgersi all'amministratore della piattaforma.<br/>
      </SweetAlert>  
    });
    setTimeout(this.hideAlert, 1800); 
  };


  render() {
    const { classes } = this.props;
    let tableHead = [];
    tableHead.push("Azioni");
    tableHead.push("Nome e cognome");
    tableHead.push("E-mail di login");
    tableHead.push("Data di nascita");
    tableHead.push("Ruoli");
    let actionButtonsColumnClasses = null;
    let actionButtonsColumnHeaderClasses = null;
    if (this.props.readOnly) {
      actionButtonsColumnClasses = [classes.centeredSingleActionButtonsColumn];
      actionButtonsColumnHeaderClasses = [classes.centeredSingleActionButtonsColumnHead];
    } else {
      actionButtonsColumnClasses = [classes.centeredTripleActionButtonsColumn];
      actionButtonsColumnHeaderClasses = [classes.centeredTripleActionButtonsColumnHead];
    }
    return (
      <div style={{width: "93%", marginLeft: "3px"}}>
        {this.state.alert}
        <Table
          tableHeaderColor="warning"
          tableHead={tableHead}
          tableData={this.state.usersTableData}
          customCellClasses={actionButtonsColumnClasses}
          customClassesForCells={[0]}
          customHeadCellClasses={actionButtonsColumnHeaderClasses}
          customHeadClassesForCells={[0]}  
        />
      </div>
    )
  }

  getButtonsGroupForEnabledUsers(rowKeyForActions) {
    return [
      { color: "success", icon: WorkOff, tooltipText: "Disabilita l'utente" },
      { color: "info", icon: Edit, tooltipText: "Modifica l'utente" },
      { color: "warning", icon: Cancel, tooltipText: "Elimina l'utente" }
    ].map((prop, key) => {
      return (
        <Tooltip
          id="tooltip-x"
          title={prop.tooltipText}
          placement="bottom"
          classes={styles.tooltip}
        >        
          <Button
            color={prop.color}
            style={localStyles.actionButton}
            key={key}
            onClick={() => { this.doActionForEnabledUsers(key, rowKeyForActions) }}          
          >
            <prop.icon style={localStyles.icon} />
          </Button>
        </Tooltip>          
      );
    });
  }

  getButtonsGroupForDisabledUsers(rowKeyForActions) {
    return [
      { color: "danger", icon: Work, tooltipText: "Abilita l'utente" },
      { color: "info", icon: Edit, tooltipText: "Modifica l'utente" },
      { color: "warning", icon: Cancel, tooltipText: "Elimina l'utente" }
    ].map((prop, key) => {
      return (
        <Tooltip
          id="tooltip-x"
          title={prop.tooltipText}
          placement="bottom"
          classes={styles.tooltip }
        > 
          <Button
            color={prop.color}
            style={localStyles.actionButton}
            key={key}
            onClick={() => { this.doActionForDisabledUsers(key, rowKeyForActions) }}
          >
            <prop.icon style={localStyles.icon} />
          </Button>
        </Tooltip>          
      );
    });
  }

  getButtonsGroupForReadOnlyUsers(rowKeyForActions) {
    return [
      { color: "info", icon: Pageview, tooltipText: "Visualizza i dettagli dell'utente" }
    ].map((prop, key) => {
      return (
        <Tooltip
          id="tooltip-x"
          title={prop.tooltipText}
          placement="bottom"
          classes={styles.tooltip}
        >        
          <Button
            color={prop.color}
            style={localStyles.actionButton}
            key={key}
            onClick={() => { this.doActionForReadOnlyUsers(key, rowKeyForActions) }}          
          >
            <prop.icon style={localStyles.icon} />
          </Button>
        </Tooltip>          
      );
    });
  }
  formatSessionDateTime(dateTime) {
    var date = new Date(dateTime);
    return (new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }).format(date));
  }

  convertUsersListToTableData(jsonUsersData) {
    let rowKeyForActions = '';
    let retArray = [];
    jsonUsersData.forEach(element => {
      rowKeyForActions = element.loginEmail;
      let singleArray = [];
      if (!this.props.readOnly) {
        if (element.enabled === true) {
          singleArray.push(this.getButtonsGroupForEnabledUsers(rowKeyForActions));
        } else {
          singleArray.push(this.getButtonsGroupForDisabledUsers(rowKeyForActions));
        }
      } else {
        singleArray.push(this.getButtonsGroupForReadOnlyUsers(rowKeyForActions));
      }
      singleArray.push((element.fullName)? element.fullName: '');
      singleArray.push((element.loginEmail)? element.loginEmail: '');
      singleArray.push((element.birthDate)?<p type="date">{this.formatSessionDateTime(element.birthDate)}</p> : <p type="date"></p>);      
      let roleCounter = 0;
      let tempString = '';
      if (element.appRoleNames) {
        element.appRoleNames.forEach(r => {
          roleCounter++;
          if (roleCounter > 1) {
            tempString += ', ';
          }
          tempString += r;
        });
      }
      singleArray.push(tempString);
      if (element.loginEmail !== this.state.currentLoggedUser.loginEmail) {
        retArray.push(singleArray);
      }   
      for (var key in singleArray){
        var attrName = key;
        var attrValue = singleArray[key];
      }
    });

    return retArray;
  }

  doActionForDisabledUsers(buttonIndex, rowKeyForActions) {
    if (buttonIndex === 0) {
      this.enableUser(rowKeyForActions);
    } else if (buttonIndex === 1) {
      this.props.customTabsRef.current.setItemForDynamicTabs(rowKeyForActions);
      this.props.customTabsRef.current.setSelectedTab(UserManagementTabs.USER_UPDATE_TAB_INDEX);
      this.props.customTabsRef.current.showDynamicTabs();
    } else if (buttonIndex === 2) {
      this.warningWithConfirmMessage(rowKeyForActions);
    }
  }

  doActionForEnabledUsers(buttonIndex, rowKeyForActions) {
    if (buttonIndex === 0) {
      this.disableUser(rowKeyForActions);
    } else if (buttonIndex === 1) {
      this.props.customTabsRef.current.setItemForDynamicTabs(rowKeyForActions);
      this.props.customTabsRef.current.setSelectedTab(UserManagementTabs.USER_UPDATE_TAB_INDEX);
      this.props.customTabsRef.current.showDynamicTabs();
    } else if (buttonIndex === 2) {
      this.warningWithConfirmMessage(rowKeyForActions);
    }
  }

  doActionForReadOnlyUsers(buttonIndex, rowKeyForActions) {
    if (buttonIndex === 0) {
      this.props.customTabsRef.current.setItemForDynamicTabs(rowKeyForActions);
      this.props.customTabsRef.current.setSelectedTab(UserManagementTabs.USER_UPDATE_TAB_INDEX - 1);
      this.props.customTabsRef.current.showDynamicTabs();
    }
  }

  enableUser(userIdentifier) {
    axiosApiCaller.post(AuthService.getBackendApiEnableUserByApplication(), {
      loginEmail: userIdentifier,
      applicationName: this.state.currentAppName
    })
    .then((res) => {
      if (res.data.success === true) {
        let usersList = res.data.payload;  
        let dataForTable = this.convertUsersListToTableData(usersList);          
        this.setState({usersTableData: dataForTable, message: res.data.message});  
      } else {
        this.setState({usersTableData: [], message: res.data.message});  
      }       
    })
    .catch((error) => {
      console.log(JSON.stringify(error.message));
      this.setState({usersTableData: [], message: error.message});
    });
  } 

  disableUser(userIdentifier) {
    //let headerWithAuth = AuthService.getAuthHeaderAsObj();
    // fetch(AuthService.BACKEND_API_DISABLE_USER_BY_APPLICATION, {
    //   method: 'POST',
    //   headers: headerWithAuth,
    //   body: JSON.stringify({
    //     loginEmail: userIdentifier,
    //     applicationName: this.state.currentAppName
    //   })
    // })
    axiosApiCaller.post(AuthService.getBackendApiDisableUserByApplication(),{
      loginEmail: userIdentifier,
      applicationName: this.state.currentAppName
    })
    .then((res) => {
      if (res.data.success === true) {
        let usersList = res.data.payload;  
        let dataForTable = this.convertUsersListToTableData(usersList);          
        this.setState({usersTableData: dataForTable, message: res.data.message});  
      } else {
        this.setState({usersTableData: [], message: res.data.message});  
      }       
    })
    .catch((error) => {
      console.log(JSON.stringify(error.message));
      this.setState({usersTableData: [], message: error.message});
    });
  }

  deleteUser(userIdentifier) {
    //let headerWithAuth = AuthService.getAuthHeaderAsObj();
    // fetch(AuthService.BACKEND_API_DELETE_USER_BY_APPLICATION, {
    //   method: 'POST',
    //   headers: headerWithAuth,
    //   body: JSON.stringify({
    //     loginEmail: userIdentifier,
    //     applicationName: this.state.currentAppName
    //   })
    // })
    axiosApiCaller.post(AuthService.getBackendApiDeleteUserByApplication(),{
      loginEmail: userIdentifier,
      applicationName: this.state.currentAppName
    })
    .then((res) => {
      if (res.data.success === true) {
        let usersList = res.data.payload;  
        let dataForTable = this.convertUsersListToTableData(usersList);          
        this.setState({usersTableData: dataForTable, message: res.data.message});  
        this.successDelete();
      } else {
        this.setState({usersTableData: [], message: res.data.message});  
      }       
    })
    .catch((error) => {
      console.log(JSON.stringify(error.message));
      this.setState({usersTableData: [], message: error.message});
    });
  }

  extractAllUsersList() {
    // let headerWithAuth = AuthService.getAuthHeaderAsObj();
    // fetch(AuthService.BACKEND_API_APPLICATION_USERS_ROLES, {
    //   method: 'POST',
    //   headers: headerWithAuth,
    //   body: JSON.stringify({
    //     applicationName: this.state.currentAppName
    //   })
    // })
    axiosApiCaller.post(AuthService.getBackendApiApplicationUsersRoles(),{ applicationName: this.state.currentAppName} )
    .then((res) => {
      if (res.data.success === true) {
        let usersList = res.data.payload;  
        let dataForTable = this.convertUsersListToTableData(usersList);          
        this.setState({usersTableData: dataForTable, message: res.data.message});  
      } else {
        this.setState({usersTableData: [], message: res.data.message});  
      }       
    })
    .catch((error) => {
      console.log(JSON.stringify(error.message));
      this.setState({usersTableData: [], message: error.message});
    });
  }
};

UsersList.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  customTabsRef: PropTypes.object.isRequired
};

export default withStyles(styles)(UsersList);