import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import Business from '@material-ui/icons/Business'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js'
import axios from 'axios'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Table from 'components/Table/Table.js'
import { axiosApiCaller, HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED } from 'layouts/AxiosService'
import PropTypes from 'prop-types'
import React from 'react'
import { LoopCircleLoading } from 'react-loadingg'
import { Link } from 'react-router-dom'
import * as AuthService from 'services/AuthService.js'
import CommonCustomIcon from 'views/COMMON/svgicons/CommonCustomIcon.js'
import * as Dashboard from 'views/TEE/Dashboard'

const localStyles = {
  icon: {
    verticalAlign: 'middle',
    textAlign: 'center',
    width: '21px',
    height: '21px',
    top: '0px',
    left: '2px',
    position: 'relative'
  },
  selectIcon: {
    verticalAlign: 'middle',
    textAlign: 'center',
    width: '21px',
    height: '21px',
    top: '0px',
    marginLeft: '1px',
    marginRight: '6px',
    position: 'relative'
  },
  actionButton: {
    margin: '5px 5px 5px 5px',
    padding: '0px',
    width: '30px',
    height: '30px',
    minWidth: 'auto',
    fontSize: '20px'
  },
  dialogConfirmButton: {
    margin: '0 0 0 5px',
    padding: '20px',
    width: '80px',
    height: 'auto',
    minWidth: 'auto'
  },
  dialogCancelButton: {
    margin: '0 0 0 5px',
    padding: '7px',
    width: 'auto',
    height: 'auto',
    minWidth: 'auto'
  },
  actionIconButton: {
    margin: '5px 5px 5px 5px',
    padding: '0px',
    minWidth: 'auto',
    fontSize: '20px',
    background: 'none',
    cursor: 'pointer'
  }
}

const styles = {
  ...localStyles,
  ...extendedTablesStyle,
  ...customSelectStyle
}

class FilterableMultiRequestsList extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.getInitialState()
    this.convertCustomersListToTableData = this.convertCustomersListToTableData.bind(this)
  }

  getInitialState () {
    let initialState = {
      currentLoggedUserCanManageVisualizeResult: AuthService.authenticathedUserCanManageUsers(),
      message: '',
      alert: null,
      sent: null,
      content: this.props.content
    }
    return initialState
  }

  componentWillMount () {}

  componentDidMount () {}

  componentDidUpdate(propsPrecedenti){
    if(propsPrecedenti!=this.props){
      if(propsPrecedenti.content!=this.props.content){
          this.setState({
            content: this.props.content
          })
      }   
    }
  }

  formatSessionDateTime (dateTime) {
    var date = new Date(dateTime)
    return new Intl.DateTimeFormat('it', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).format(date)
  }

  render () {
    let filteredDataTable = this.extractList()
    const { classes } = this.props

    let tableHead = this.props.headersList

    let actionButtonsColumnClasses = []
    let actionButtonsColumn = []
    let actionButtonsColumnHeaderClasses = []
    let actionButtonsColumnHeader = []
    
    if (!this.state.content) {
     return (
      <div style={{height:'100px'}}>
      <LoopCircleLoading />
      </div>
      ); 
    }
    return (
      <div style={{ width: '99%', marginLeft: '3px' }}>
        <Divider style={{ width: '98%' }} />
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={12} style={{ minHeight: '500px' }}>
            <Table
              tableHeaderColor='info'
              tableHead={tableHead}
              tableData={filteredDataTable}
              customCellClasses={actionButtonsColumnClasses}
              customClassesForCells={actionButtonsColumn}
              customHeadCellClasses={actionButtonsColumnHeaderClasses}
              customHeadClassesForCells={actionButtonsColumnHeader}
              listener={this.props.listener}
              totalElements={this.props.totalElements}
              offset={this.props.offSet}
              pageNumber={this.props.pageNumber}
              order={this.props.order}
              orderBy={this.props.orderBy}
              numberQueryResults={this.props.numberQueryResults}
            />
          </GridItem>
        </GridContainer>
      </div>
    )
  }

  doActionForEnabledUsers (
    event,
    buttonIndex,
    descriptKeyForActions,
    objKeyForActions
  ) {
    let isControlPressed = false
    if (event) {
      if (event.ctrlKey) {
        isControlPressed = true
      }
    }
    let type = null
    let icon = null
    let key = null
    type = Dashboard.PERSONA_GIURIDICA_LABEL
    icon = Business

    if (objKeyForActions.ragioneSociale) {
      key = objKeyForActions.ragioneSociale
    }
    this.props.customTabsRef.current.showDynamicTab(
      key,
      descriptKeyForActions,
      icon,
      objKeyForActions,
      isControlPressed
    )
  }

  getButtonsGroupForEnabledUsers (
    descriptKeyForActions,
    objKeyForActions,
    strategia,
    dataInserimento
  ) {
    return (
      <Tooltip
        id='tooltip-x'
        title='Visualizza dettagli della posizione'
        placement='bottom'
        classes={styles.tooltip}
      >
        <div>
          <a
            style={localStyles.actionIconButton}
            onClick={e => {
              this.doActionForEnabledUsers(
                e,
                1,
                descriptKeyForActions,
                objKeyForActions
              )
            }}
          >
            <CommonCustomIcon icon={'Posizione'} />
          </a>
          <div style={{ color: 'black', fontSize: '12px' }}>
            {dataInserimento
              ? new Date(dataInserimento).toLocaleDateString()
              : ''}
          </div>
        </div>
      </Tooltip>
    )
  }

  addDownloadButton (idRequest) {
    return (
      <div>
        <IconButton
          aria-label='download'
          onClick={() => {
            this.downloadFileOfRequest(idRequest)
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
      </div>
    )
  }
  addDownloadInputWithPlatformId (idRequest) {
    return (
      <div>
        <IconButton
          aria-label='download'
          onClick={() => {
            this.downloadInpuWithPlatformId(idRequest)
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
      </div>
    )
  }

  downloadInpuWithPlatformId (idRequest) {
    axios
      .get(
        AuthService.downloadInputWithPlatformId() + '?idRequest=' + idRequest,
        {
          responseType: 'blob',
          headers: {
            Accept: 'application/octet-stream',
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Input_with_platformId.xlsx')
        link.click()
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config
          originalRequest._retry = true
          let refreshToken2 = localStorage.getItem('loggedRefreshToken')
          let tokenVecchio2 = localStorage.getItem('loggedToken')
          let username2 = localStorage.getItem('loginEmail')
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem('loggedToken')
              localStorage.removeItem('loggedRefreshToken')
              localStorage.setItem('loggedToken', rest.data.payload.jwtToken)
              localStorage.setItem(
                'loggedRefreshToken',
                rest.data.payload.jwtTokenRefresh
              )
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken)
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              )
              originalRequest.headers['Authorization'] =
                rest.data.payload.jwtToken
              originalRequest.headers['TokenRefresh'] =
                rest.data.payload.jwtTokenRefresh
              axios.defaults.headers['Authorization'] =
                rest.data.payload.jwtToken
              axios
                .get(
                  AuthService.downloadInputWithPlatformId() +
                    '?idRequest=' +
                    idRequest,
                  {
                    responseType: 'blob',
                    headers: {
                      Accept: 'application/octet-stream',
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  )
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', 'Input_with_platformId.xlsx')
                  link.click()
                })
                .catch(error => {
                  AuthService.setLoggedUserJwtToken(null)
                  AuthService.setLoggedUserJwtTokenRefresh(null)
                  return (window.location.href = '/home')
                })
            })
            .catch(error => {
              AuthService.setLoggedUserJwtToken(null)
              AuthService.setLoggedUserJwtTokenRefresh(null)
              return (window.location.href = '/home')
            })
        }
      })
  }

  downloadFileOfRequest (idRequest) {
    axios
      .get(
        AuthService.getBackendApiTeeRequestFileDownload() +
          '?idRequest=' +
          idRequest,
        {
          responseType: 'blob',
          headers: {
            Accept: 'application/octet-stream',
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Input.xlsx')
        link.click()
      })
      .catch(error => {
        if (error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
          const originalRequest = error.config
          originalRequest._retry = true
          let refreshToken2 = localStorage.getItem('loggedRefreshToken')
          let tokenVecchio2 = localStorage.getItem('loggedToken')
          let username2 = localStorage.getItem('loginEmail')
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem('loggedToken')
              localStorage.removeItem('loggedRefreshToken')
              localStorage.setItem('loggedToken', rest.data.payload.jwtToken)
              localStorage.setItem(
                'loggedRefreshToken',
                rest.data.payload.jwtTokenRefresh
              )
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken)
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              )
              originalRequest.headers['Authorization'] =
                rest.data.payload.jwtToken
              originalRequest.headers['TokenRefresh'] =
                rest.data.payload.jwtTokenRefresh
              axios.defaults.headers['Authorization'] =
                rest.data.payload.jwtToken
              axios
                .get(
                  AuthService.getBackendApiTeeRequestFileDownload() +
                    '?idRequest=' +
                    idRequest,
                  {
                    responseType: 'blob',
                    headers: {
                      Accept: 'application/octet-stream',
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  )
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', 'Input.xlsx')
                  link.click()
                })
            })
            .catch(error => {
              AuthService.setLoggedUserJwtToken(null)
              AuthService.setLoggedUserJwtTokenRefresh(null)
              return (window.location.href = '/home')
            })
        }
      })
  }

  getIconStatoLavorazione (statoLavorazione) {
    return (
      <div>
        <CommonCustomIcon icon={statoLavorazione} />
      </div>
    )
  }

  convertCustomersListToTableData (jsonUsersData, fields) {
    let descriptiveKeyForActions = ''
    let objectKeyForActions = null
    let idRequest = null
    let retArray = []
    let sentVariable = null

    jsonUsersData.forEach((element, index) => 
    {
      objectKeyForActions = element
      descriptiveKeyForActions = element.ragioneSociale && element.ragioneSociale != null ? element.ragioneSociale : element.idSoggetto
      descriptiveKeyForActions += element.paeseProvincia && element.paeseProvincia != null ? ' (' + element.paeseProvincia + ')' : '' 
      idRequest = element.id
      
      let singleArray = []

      let fieldsList = this.props.fieldsList
      fieldsList.forEach(field => {
        if (field === 'creationDatetime' ) {

          singleArray.push(element[field])
        
        } else if (field === 'lastEditDatetime'){
            singleArray.push(element[field])
        } else if (field === 'elaborationState'){
          if(element['elaborationMessage']){
            singleArray.push(
              <div style={{ display: 'table' }}>         
                <Tooltip
                  id='tooltip-message'
                  title={element['elaborationMessage']}
                  placement='bottom'
                  classes={styles.tooltip}
                >
                  <div>
                    <a style={localStyles.actionIconButton}>   
                    </a>
                    {element[field]}
                  </div>  
                </Tooltip>
            </div>)
          }
          else{
            singleArray.push(element[field])
          }

        } else if (field === 'exported') {
          if (element[field] == true) {
            singleArray.push(<FontAwesomeIcon color='green' icon={faCheck} />)
          } else {
            singleArray.push(
              <FontAwesomeIcon color='red' size='1x' icon={faTimes} />
            )
          }
        } else if (field === 'sendJson') {
          if (
            element['elaborationState'] != 'RICHIESTA' &&
            element['elaborationState'] != 'ANNULLATA'
          ) {
            if (element['sent'] == true) {
              sentVariable = true
              singleArray.push(
                <span>
                  <FontAwesomeIcon color='green' icon={faCheck} />
                </span>
              )
            } else {
              sentVariable = false
              singleArray.push(
                <IconButton
                  aria-label='download'
                  onClick={() => {
                    this.props.listener.sendJsonConfirmationAlert(
                      jsonUsersData[index].id
                    )
                  }}
                >
                  <UnarchiveIcon />
                </IconButton>
              )
            }
          } else {
            singleArray.push(
              <FontAwesomeIcon color='red' size='1x' icon={faTimes} />
            )
            sentVariable = false
          }
        } else if (field === 'country'){
          singleArray.push(element[field])
        } 
        else if (field === 'monitoraggioUguale'){
          if(element[field] == true){
            singleArray.push("true")
          }
          else{
            singleArray.push("false")
          }       
        } else {
          singleArray.push(
            element[field] !== null || element[field] !== undefined
              ? element[field]
              : ''
          )
        }
      })

      
      if (singleArray) {
        for (let i = 0; i < singleArray.length && i < 6; i++) {
          singleArray[i] = (
            <Link
              style={{
                textDecoration: 'none',
                color: 'black',
                padding: '20px'
              }}
              type={i === 2 || i === 3 ? 'date' : null}
              to={{
                pathname: '/consolle/request-result',

                state: {
                  idRequest: idRequest,
                  sendJson: sentVariable
                }
              }}
            >
              {singleArray[i]}
            </Link>
          )
        }
      }

      retArray.push(singleArray)
    })

    console.log(retArray);
    return retArray
  }

  filterList () {
    let allItemsList = this.props.itemsList
    let filteredList = []
    if (allItemsList !== null && allItemsList.length > 0) {
      let listLength = allItemsList.length
      for (let item of allItemsList) {
        let filterInItem = true

        if (
          this.props.showGeoName == null ||
          this.props.showTipologiaName == null
        ) {
          filterInItem = true
        } else {
          if (this.props.showMonitored === false) {
            if (item.monitoraggio != 'NO') {
              filterInItem = filterInItem && false
            } else {
              filterInItem = filterInItem && true
            }
          }
          if (this.props.showNotMonitored === false) {
            if (item.monitoraggio == 'NO') {
              filterInItem = filterInItem && false
            } else {
              filterInItem = filterInItem && true
            }
          }
          //if (this.state.filterType !== null) {

          if (
            this.props.showGeoName.toUpperCase() ===
            Dashboard.ALL_CITTA_LABEL.toUpperCase()
          ) {
            filterInItem = filterInItem && true
          } else if (item.paeseProvincia != null) {
            if (
              item.paeseProvincia.toUpperCase() ===
              this.props.showGeoName.toUpperCase()
            ) {
              filterInItem = filterInItem && true
            } else {
              filterInItem = filterInItem && false
            }
          } else {
            filterInItem = filterInItem && false
          }
          //END MANAGER GEO FILTER

          if (
            this.props.showTipologiaName.toUpperCase() ===
            Dashboard.ALL_TIPOLOGIA_LABEL.toUpperCase()
          ) {
            filterInItem = filterInItem && true
          } else if (item.monitoraggioType != null) {
            if (
              item.monitoraggioType.toUpperCase() ===
              this.props.showTipologiaName.toUpperCase()
            ) {
              filterInItem = filterInItem && true
            } else {
              filterInItem = filterInItem && false
            }
          } else {
            filterInItem = filterInItem && false
          }
        }
        if (filterInItem) {
          filteredList.push(item)
        }
      }
    }
    return filteredList
  }

  extractList () {
    let dataForTable = this.convertCustomersListToTableData(this.filterList())
    return dataForTable
  }
}

FilterableMultiRequestsList.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  customTabsRef: PropTypes.object.isRequired,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      personaFisica: PropTypes.bool,
      ragioneSociale: PropTypes.string,
      codiceFiscale: PropTypes.string,
      comune: PropTypes.string,
      dataCaricamento: PropTypes.string,
      statoLavorazione: PropTypes.string,
      monitoraggioAttivo: PropTypes.bool,
      strategia: PropTypes.string
    })
  ).isRequired,
  showMonitored: PropTypes.bool.isRequired,
  showNotMonitored: PropTypes.bool.isRequired,
  showGeoName: PropTypes.string.isRequired,
  showTipologiaName: PropTypes.string.isRequired,
  showStatoName: PropTypes.string.isRequired,
  showStrategiaName: PropTypes.string.isRequired
}

export default withStyles(styles)(FilterableMultiRequestsList)
