import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import styles from "assets/jss/material-dashboard-pro-react/components/customTabsStyle.js";
import classNames from "classnames";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ApprofondimentiMultiVisualizer from 'components/COMMON/ApprofondimentiMultiVisualizer';
import PropTypes from "prop-types";
import React from "react";

export const DETAILS_TAB_PRE_TITLE = "";

class FilterableMultiApprofondimentiCustomTabs extends React.Component {

  constructor(props) {
    super(props);
    //this.classes = props;
    this.state = this.getInitialState();
    this.numberOfStandardTabs = this.props.fixedTabs.length;
    this.handleChange = this.handleChange.bind(this);
  }

  getInitialState() {
    return {      
      value: (this.props.selectedTabIndex),
      dynTabsKeyMap: [],
      dynTabsCount: 0,
      dynTabSelected: 0,
      dynTabsObjs: [],
      dynTabsIcons: [],
      dynTabsDescrs: [],
      //showDynamicTabs: (this.props.showDynamicTabs),
      //itemDescriptionForDynamicTabs: (this.props.itemDescriptionForDynamicTabs),
      //itemObjForDynamicTabs: (this.props.itemObjForDynamicTabs),
      //iconForDynamicTabs: (this.props.iconForDynamicTabs),
      filteredItemsList: (this.props.itemsList),
      isEditModeUsable: this.props.isEditModeUsable ? this.props.isEditModeUsable : false
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }


  showDynamicTab(key, description, icon, obj, stayOnFixed) {
    if (key && description && icon && obj) {
      let keyLen = this.state.dynTabsKeyMap.length;
      let desLen = this.state.dynTabsDescrs.length;
      let iconLen = this.state.dynTabsIcons.length;
      let objLen = this.state.dynTabsObjs.length;
      if ((keyLen === desLen) && (keyLen === iconLen) && (keyLen === objLen)) {
        let keyArray = this.state.dynTabsKeyMap;
        let indexOfKey = -1;
        // trova l'indice della chiave
        for (let i=0; i<keyLen; i++) {
          if (keyArray[i] === key) {
            indexOfKey = i;
            break;
          }
        }
        if (indexOfKey > -1) {
          if (stayOnFixed === false) {
            let newValue = this.props.fixedTabs.length + indexOfKey;
            this.setState({
              value: newValue
            });          
          }
        } else {
          let newCountDynTabs = this.state.dynTabsCount + 1;
          let newValue = this.props.fixedTabs.length + newCountDynTabs - 1;
          let desArray = this.state.dynTabsDescrs;
          let icnArray = this.state.dynTabsIcons;
          let objArray = this.state.dynTabsObjs;
          keyArray.push(key);
          desArray.push(description);
          icnArray.push(icon);
          objArray.push(obj);
          if (stayOnFixed === false) {
            this.setState({
              value: newValue
            });
          }
          this.setState({
            dynTabsKeyMap: keyArray,
            dynTabsCount: newCountDynTabs,
            dynTabSelected: newCountDynTabs,
            dynTabsObjs: objArray,
            dynTabsIcons: icnArray,
            dynTabsDescrs: desArray
          });
        }  
      }
    }
  }

  hideDynamicTab(key) {
    if (key) {
      let keyLen = this.state.dynTabsKeyMap.length;
      let desLen = this.state.dynTabsDescrs.length;
      let iconLen = this.state.dynTabsIcons.length;
      let objLen = this.state.dynTabsObjs.length;
      if ((keyLen === desLen) && (keyLen === iconLen) && (keyLen === objLen)) {
        let keyArray = this.state.dynTabsKeyMap;
        let indexOfKey = -1;
        // trova l'indice della chiave
        for (let i=0; i<keyLen; i++) {
          if (keyArray[i] === key) {
            indexOfKey = i;
            break;
          }
        }
        if (indexOfKey > -1) {
          let newKeyArray = [];
          let newDesArray = [];
          let newIcnArray = [];
          let newObjArray = [];
          for (let i=0; i<indexOfKey; i++) {
            newKeyArray.push(this.state.dynTabsKeyMap[i]);
            newDesArray.push(this.state.dynTabsDescrs[i]);
            newIcnArray.push(this.state.dynTabsIcons[i]);
            newObjArray.push(this.state.dynTabsObjs[i]);
          }
          for (let i=(indexOfKey + 1); i<keyLen; i++) {
            newKeyArray.push(this.state.dynTabsKeyMap[i]);
            newDesArray.push(this.state.dynTabsDescrs[i]);
            newIcnArray.push(this.state.dynTabsIcons[i]);
            newObjArray.push(this.state.dynTabsObjs[i]);
          }
          this.setState({
            value: (this.props.selectedTabIndex),
            dynTabsKeyMap: newKeyArray,
            dynTabsCount: newKeyArray.length,
            dynTabSelected: 0,
            dynTabsObjs: newObjArray,
            dynTabsIcons: newIcnArray,
            dynTabsDescrs: newDesArray
          });
        }
      }
    }
  }

  setFilteredItemsList(list) {
    this.setState({filteredItemsList: list});
  }

  getFilteredItemsList() {
    return this.state.filteredItemsList;
  }

  
  setSelectedTab(tabIndex) {
    this.setState({value: tabIndex});
  }

  getSelectedTab() {
    return this.state.value;
  }

  handleChange (event, value) {
    if (value < this.numberOfStandardTabs) {
      this.setSelectedTab(value);
    } else {
      if (value === this.state.value) {
        this.hideDynamicTab(this.state.dynTabsKeyMap[value - this.numberOfStandardTabs]);
      } else {
        this.setSelectedTab(value);
      }
    }
  };

  render() {
    const { classes } = this.props;
    let cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: this.props.rtlActive
    });
    
    return (
      <Card plain={this.props.plainTabs}>
        <CardHeader color={this.props.headerColor} plain={this.props.plainTabs}>
          {this.props.title !== undefined ? <div className={cardTitle}>{this.props.title}</div> : null}
          

          <Tabs
            value={this.state.value}
            onChange={this.handleChange}                       
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayBlock      
            }}
          >
            {this.props.fixedTabs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />
                };
              }
              return (

               

                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}


            {(this.state.dynTabsCount > 0)? (
              this.state.dynTabsKeyMap.map((key, index) => {
              var icon = {};
              if (this.state.dynTabsIcons[index]) {
                let IconTab = this.state.dynTabsIcons[index];
                icon = {icon: <IconTab/>};

              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={index}
                  label={DETAILS_TAB_PRE_TITLE + this.state.dynTabsDescrs[index]}
                  {...icon}
                />
              );
            })
          ) : (null)}


          </Tabs>
                    
        </CardHeader>
        <CardBody>
          {this.props.fixedTabs.map((prop, key) => {
            if (key === this.state.value) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}


          {(this.state.dynTabsCount > 0)? (
            this.state.dynTabsKeyMap.map((key, index) => {
              if ((this.numberOfStandardTabs + index) === this.state.value) {
                return <div key={index}>
                          
                          <ApprofondimentiMultiVisualizer readOnly={true}
                                               itemKey={key}
                                               isEditModeUsable = {this.state.isEditModeUsable}
                                               itemObj={this.state.dynTabsObjs[index]}
                                               itemDescription={this.state.dynTabsDescrs[index]}
                                               itemIcon={this.state.dynTabsIcons[index]}
                          />                                                     
                
                        </div>;
              }
              return null;
            })
          ) : (null)}

        </CardBody>
      </Card>
    );
  }
}

FilterableMultiApprofondimentiCustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
    "lightGreen"
  ]),
  title: PropTypes.string,
  fixedTabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  selectedTabIndex: PropTypes.number,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      personaFisica: PropTypes.bool,
      ragioneSociale: PropTypes.string,
      codiceFiscale: PropTypes.string,
      comune: PropTypes.string,
      dataCaricamento: PropTypes.string,
      statoLavorazione: PropTypes.string,
      monitoraggioAttivo: PropTypes.bool,
      strategia: PropTypes.string
    })
  )
};

export default withStyles(styles)(FilterableMultiApprofondimentiCustomTabs);