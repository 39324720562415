// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle.js";
import cx from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import CommonCustomIcon from "views/COMMON/svgicons/CommonCustomIcon";

const useStyles = makeStyles(styles);

export default function DetailVisualizer(props) {
  const classes = useStyles();
  const { leftDetails, rightDetails, simple, showTitles } = props;
  const timelineClass =
    classes.timelineWithoutLine +
    " " +
    cx({
      [classes.timelineSimpleWithoutLine]: simple
    });
  return (
    <GridContainer xs={12} sm={12} md={12}>
      <GridItem xs={6} sm={6} md={6}>

        <ul className={timelineClass}>
          {leftDetails.map((prop, key) => {
            const panelClasses =
              classes.timelinePanel +
              " " +
              cx({
                [classes.timelinePanelInverted]: prop.inverted || simple,
                [classes.timelineSimplePanel]: simple
              });
            const timelineBadgeClasses =
              classes.timelineBadge +
              " " +
              " " +
              cx({
                [classes.timelineSimpleBadge]: simple
              });
            return (
              <li className={classes.item} key={key}>
              <div className={timelineBadgeClasses}>
                    <CommonCustomIcon icon={"Processo"}/>
                  {(prop.title && showTitles) ? 
                    ( <span >{prop.title}</span> ) : null
                  }
              </div>      


                <div className={panelClasses}>
                  <div className={classes.timelineBody}>{prop.body}</div>
                  {prop.footerTitle ? (
                    <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
                  ) : null}
                  {prop.footer ? <hr className={classes.footerLine} /> : null}
                  {prop.footer ? (
                    <div className={classes.timelineFooter}>{prop.footer}</div>
                  ) : null}
                </div>
              </li>
            );
          })}
        </ul>
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>

        <ul className={timelineClass}>
          {rightDetails.map((prop, key) => {
            const panelClasses =
              classes.timelinePanel +
              " " +
              cx({
                [classes.timelinePanelInverted]: prop.inverted || simple,
                [classes.timelineSimplePanel]: simple
              });
            const timelineBadgeClasses =
              classes.timelineBadge +
              " " +
              " " +
              cx({
                [classes.timelineSimpleBadge]: simple
              });
            return (
              <li className={classes.item} key={key}>
              <div className={timelineBadgeClasses}>
                    <CommonCustomIcon icon={"Anagrafica"}/>
              </div>      


                <div className={panelClasses}>
                  <div className={classes.timelineBody}>{prop.body}</div>
                  {prop.footerTitle ? (
                    <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
                  ) : null}
                  {prop.footer ? <hr className={classes.footerLine} /> : null}
                  {prop.footer ? (
                    <div className={classes.timelineFooter}>{prop.footer}</div>
                  ) : null}
                </div>
              </li>
            );
          })}
        </ul>


      </GridItem>

    </GridContainer>    
  );
}

DetailVisualizer.propTypes = {
  leftDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  leftDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
  showIcons: PropTypes.bool,
  showTitles: PropTypes.bool
};
