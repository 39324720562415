import { Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CachedIcon from '@material-ui/icons/Cached'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit'
import Print from '@material-ui/icons/Print'
import SaveIcon from '@material-ui/icons/Save'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import axios from 'axios'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { axiosApiCaller, HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED } from 'layouts/AxiosService'
import PropTypes from 'prop-types'
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import ReactDOM from 'react-dom'
import 'react-nice-dates/build/style.css'
import ReactToPrint from 'react-to-print'
import * as AuthService from 'services/AuthService.js'
import Swal from 'sweetalert2'
import * as CascoDashboard from 'views/TEE/Dashboard'
import CompanyReportVisualizer from './CompanyReportVisualizer'


const AUTO_CLOSE_TIME_DENIED_OPERATION = 4000

class ReportMultiVisualizer extends React.Component {

  constructor(props) {
    super(props)
    localStorage.setItem('itemsForDetail', JSON.stringify(this.props.itemObj))
    this.componentRef = React.createRef()
    this.state = this.getInitialState()
    this.getApprofondimentoTypes()

    console.log(this.state);

    if (this.state.itemForDetail)
      this.getListAnalysisRequestResultDocuments(this.state.itemForDetail.platformId);

    this.cancelMode = this.cancelMode.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.viewDocumentOfAnalysisRequestResult = this.viewDocumentOfAnalysisRequestResult.bind(this)
    this.downloadDocumentOfAnalysisRequestResult = this.downloadDocumentOfAnalysisRequestResult.bind(this)
    this.getDocumentTypeList = this.getDocumentTypeList.bind(this)
    this.openDialog = this.openDialog.bind(this)
    this.synchronizeData = this.synchronizeData.bind(this)
    this.hideAlert = this.hideAlert.bind(this);
  }

  getListAnalysisRequestResultDocuments(platformId) {
    let queryString = '?platformId=' + platformId + '&idRichiesta=' + this.state.itemForDetail.idRichiesta

    axiosApiCaller.get(AuthService.getAllTeeDiscoveredSubjectDocumentsByPlatformId() + queryString).then(res => {
      this.setState({ listaDocumenti: res.data })

    }).catch(error => { })
  }

  getInitialState() {
    return {
      alert: null,
      errorMessage: null,
      typeForDetail: CascoDashboard.PERSONA_FISICA_LABEL,
      itemForDetail: this.props.itemObj,
      listApprofondimenti: [],
      listaDocumenti: this.props.itemObj && this.props.itemObj.documenti ? this.props.itemObj.documenti : [],
      editMode: false,
      synchronized: false,
      isEditModeUsable: this.props.isEditModeUsable ? this.props.isEditModeUsable : false,
      specificScoreComponent: null,
      listaDocumenti: [],
      pdfData: null,
      ispdfDataChanged: false,
      isItemForDetailChanged: false,
      societaCollegateList: [],
      tipoDocumentiList: [],
    }
  }

  componentWillMount() {
    if (this.props.itemObj) {
      if (this.props.itemObj.codiceFiscale) {
        let positionDetail = this.extractPositionDetailFromBackend(
          this.props.itemObj.codiceFiscale
        )
      }
    }
  }

  getDocumentTypeList() {
    axiosApiCaller.get(AuthService.postBackendApiDocumenti())
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ tipoDocumentiList: res.data.payload })
        }
        else
          this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');

      })
      .catch((error) => {
        this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
      });
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView()
    this.getDocumentTypeList()

  }

  getApprofondimentoTypes() {
    axiosApiCaller
      .get(AuthService.getApprofondimentoType())
      .then(res => {
        if (res.data.success === true) {
          this.setState({ listApprofondimenti: res.data.payload })
        }
      })
      .catch(error => {
      })
  }

  changeMode() { debugger; this.setState({ editMode: !this.state.editMode }) }

  cancelMode() {
    let tmpItemForDetail = JSON.parse(localStorage.getItem('itemsForDetail'))
    this.setState({
      isItemForDetailChanged: !this.state.isItemForDetailChanged,
      editMode: !this.state.editMode
    })
    this.setState({ itemForDetail: tmpItemForDetail })
  }

  saveData() {
    let teeDiscoveredJuridicalSubjecBoxDaModificare = {}
    let listaDocumentiDaEliminare = this.componentRef.current.listaDocumentiDaEliminare
    teeDiscoveredJuridicalSubjecBoxDaModificare.cap = this.componentRef.current.cleanedData.cap
    teeDiscoveredJuridicalSubjecBoxDaModificare.customerId = this.componentRef.current.cleanedData.customerId
    teeDiscoveredJuridicalSubjecBoxDaModificare.elaborationMessage = this.componentRef.current.cleanedData.elaborationMessage
    teeDiscoveredJuridicalSubjecBoxDaModificare.elaborationState = this.componentRef.current.cleanedData.elaborationState
    teeDiscoveredJuridicalSubjecBoxDaModificare.foreignActualOwners = this.componentRef.current.cleanedData.foreignActualOwners
    teeDiscoveredJuridicalSubjecBoxDaModificare.indirizzo = this.componentRef.current.cleanedData.indirizzo
    teeDiscoveredJuridicalSubjecBoxDaModificare.localita = this.componentRef.current.cleanedData.localita
    teeDiscoveredJuridicalSubjecBoxDaModificare.paeseProvincia = this.componentRef.current.cleanedData.paeseProvincia
    teeDiscoveredJuridicalSubjecBoxDaModificare.partitaIva = this.componentRef.current.cleanedData.partitaIva
    teeDiscoveredJuridicalSubjecBoxDaModificare.ragioneSociale = this.componentRef.current.cleanedData.ragioneSociale
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggioType = this.componentRef.current.cleanedData.monitoraggioType
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggioFlag = this.componentRef.current.cleanedData.monitoraggioFlag
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggio = this.componentRef.current.cleanedData.monitoraggio
    teeDiscoveredJuridicalSubjecBoxDaModificare.documentazione = this.componentRef.current.cleanedData.documentazione
    teeDiscoveredJuridicalSubjecBoxDaModificare.note = this.componentRef.current.cleanedData.note
    teeDiscoveredJuridicalSubjecBoxDaModificare.platformId = this.componentRef.current.cleanedData.platformId
    teeDiscoveredJuridicalSubjecBoxDaModificare.societaCollegate = this.componentRef.current.cleanedData.societaCollegate
    teeDiscoveredJuridicalSubjecBoxDaModificare.modalita_evasione = this.componentRef.current.cleanedData.modalita_evasione
    teeDiscoveredJuridicalSubjecBoxDaModificare.motivo = this.componentRef.current.cleanedData.motivo
    teeDiscoveredJuridicalSubjecBoxDaModificare.esito = this.componentRef.current.cleanedData.esito
    teeDiscoveredJuridicalSubjecBoxDaModificare.notaMancatoCalcoloTEE = this.componentRef.current.cleanedData.notaMancatoCalcoloTEE
    teeDiscoveredJuridicalSubjecBoxDaModificare.notaMancatoCalcoloCubo = this.componentRef.current.cleanedData.notaMancatoCalcoloCubo
    teeDiscoveredJuridicalSubjecBoxDaModificare.accordo = this.componentRef.current.cleanedData.accordo
    teeDiscoveredJuridicalSubjecBoxDaModificare.rispostaMonitoraggio = this.componentRef.current.cleanedData.rispostaMonitoraggio
    teeDiscoveredJuridicalSubjecBoxDaModificare.societaCollegateList = this.componentRef.current.cleanedData.societaCollegateList
    teeDiscoveredJuridicalSubjecBoxDaModificare.anagrafiche = this.componentRef.current.state.anagrphyList
    teeDiscoveredJuridicalSubjecBoxDaModificare.robotId = this.componentRef.current.cleanedData.robotId
    teeDiscoveredJuridicalSubjecBoxDaModificare.idRichiesta = this.componentRef.current.cleanedData.idRichiesta

    let apiUrlToCall = AuthService.getBackendApiTeeDiscoveredJuridicalSubjectBoxUpdateUrl()

    axiosApiCaller.post(apiUrlToCall, teeDiscoveredJuridicalSubjecBoxDaModificare).then(res => {
      apiUrlToCall = AuthService.postApiForDeleteDocumentsForTeeJuridicalSubject() + '?platformId=' +
        teeDiscoveredJuridicalSubjecBoxDaModificare.platformId + '&idRichiesta=' +
        teeDiscoveredJuridicalSubjecBoxDaModificare.idRichiesta
      axiosApiCaller.post(apiUrlToCall, listaDocumentiDaEliminare).then(res => {
        this.setState({ listaDocumenti: res.data })
        this.componentRef.current.listaDocumentiDaEliminare = []
      }).catch(error => { })

    }).catch(error => { })

    this.changeMode()
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  openDialog(primaryMsg, secondButton, synchronize) {
    this.setState({
      alert: (
        <SweetAlert info style={{ display: "block", marginTop: "140px" }} title={primaryMsg}
          customButtons=
          {
            <React.Fragment>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }} color="success" round
                  onClick={() => {
                    if (synchronize)
                      this.synchronizeData();
                    else
                      this.hideAlert();
                  }}>
                  <CheckCircle style={{ fontSize: "28px" }} disabled={this.state.downoadAmount > 1000} />
                  Ok
                </Button>

                {
                  secondButton &&
                  <Button style={{ fontSize: "13px", marginLeft: "2%" }} color="cancel" onClick={() => { this.hideAlert(); }} round>
                    Cancel
                  </Button>
                }

              </div>
            </React.Fragment>
          }>
        </SweetAlert>
      ),
    });
  }

  synchronizeData() {
    let apiUrlToCall = AuthService.synchronizeRequest()
    let jsonToSend = {
      idRequest: this.componentRef.current.cleanedData.idRichiesta,
      idPlatform: this.componentRef.current.cleanedData.platformId
    }

    axiosApiCaller.post(apiUrlToCall, jsonToSend).then(res => {
      console.log("success", res);
      this.openDialog(res.data.payload, false, false);
      this.setState({ synchronized: res.data.succes ? true : false });

    }).catch(error => {
      console.log(error);
      this.openDialog(error.data.payload, false, false);
    })

  }

  addSocio() {
    ; (async () => {
      const { value: name, dismiss: dismiss } = await Swal.fire({
        title: 'Nuovo titolare estero effettivo',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Conferma',
        reverseButtons: true,
        input: 'text',
        inputLabel: 'Name',
        inputPlaceholder: 'Inserisci nome',
        inputValidator: value => {
          if (!value) {
            return 'Devi fornire almeno il nome!'
          }
        },

      })
      if (!dismiss) {
        let newForeignActualOwners = {
          completeName: name,
          appointedNotifiedOn: '',
          bnfcCountry: '',
          bnfcDateBirth: '',
          bnfcOwnerLegalForm: '',
          bnfcOwnerType: '',
          bnfcPostalCode: '',
          bnfcTown: '',
          countryOfResidence: '',
          dateOfBirth: '',
          documentazione: false,
          idSoggetto: '',
          livello: 0,
          nationality: '',
          note: '',
          progr: 1,
          role: '',
          societaCollegate: ''
        }
        this.state.itemForDetail.foreignActualOwners.push(
          newForeignActualOwners
        )
        this.setState({ itemForDetail: this.state.itemForDetail })
      }
    })()
  }

  render() {
    let message = 'Componente per la valutazione di persone giuridiche'
    this.specificScoreComponent = (
      <CompanyReportVisualizer
        itemForDetail={this.state.itemForDetail}
        errorMessage={this.state.errorMessage}
        editMode={this.state.editMode}
        mainIcon={this.props.itemIcon}
        listaDocumenti={this.state.listaDocumenti}
        click={this.viewDocumentOfAnalysisRequestResult}
        download={this.downloadDocumentOfAnalysisRequestResult}
        ref={this.componentRef}
        pdfData={this.state.pdfData}
        ispdfDataChanged={this.state.ispdfDataChanged}
        societaCollegateList={this.state.societaCollegateList}
        isItemForDetailChanged={this.state.isItemForDetailChanged}
      />
    )

    return (
      <div style={{ marginLeft: '10px' }}>

        {this.state.alert}

        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

            <ReactToPrint trigger={() => {
              return (
                <a href='#'>
                  <Print style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '30px', height: '30px' }} />
                </a>
              )
            }}

              content={() => this.componentRef} />

            {(() => {
              if (this.state.editMode) {
                return (
                  <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label='upload'
                    onClick={() => { this.uploadOnS3(this.componentRef.current.cleanedData.platformId, this.componentRef.current.cleanedData.idRichiesta) }}>
                    <CloudUploadIcon />
                  </IconButton>
                )
              }
            })()}
            {(() => {
              if (!this.state.editMode && this.state.isEditModeUsable) {
                if (!this.getSendJson()) {
                  return (<div>

                    {
                      this.state.synchronized && AuthService.getApplicationName() === "ADMINISTRATION_PANEL" &&
                      <Tooltip title="Sincronizzazione avvenuta con successo">
                        <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                          color='primary' aria-label='download'>
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    }

                    {
                      !this.state.synchronized &&
                      <Tooltip title="Modifica">
                        <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                          aria-label='download' onClick={() => { this.changeMode() }}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    }

                    {
                      !this.state.synchronized && AuthService.getApplicationName() === "ADMINISTRATION_PANEL" &&
                      <Tooltip title="Sincronizza dati">
                        <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                          aria-label='download'
                          onClick={() => { this.openDialog("Sei sicuro di voler sincronizzare le seguenti informazioni?", true, true) }} >
                          <CachedIcon />
                        </IconButton>
                      </Tooltip>
                    }

                    {(() => {
                      if (this.state.itemForDetail.elaborationState != "RICHIESTA" && !this.state.synchronized) {
                        return (
                          <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                            aria-label='download' onClick={() => { this.downloadPdf(this.state.itemForDetail.platformId, this.state.itemForDetail.idRichiesta) }}>
                            <PdfIcon />
                          </IconButton>)
                      }
                    })()}
                    {(() => {
                      if (this.state.itemForDetail.elaborationState != "RICHIESTA" && !this.state.synchronized) {
                        return (
                          <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                            aria-label='download' onClick={() => { this.sendJsonOnS3(this.state.itemForDetail.idRichiesta) }}>
                            <UnarchiveIcon />
                          </IconButton>)
                      }
                    })()}
                  </div>
                  )
                }
              }
              else if (this.state.editMode) {
                return (
                  <IconButton color='secondary' style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                    aria-label='download' onClick={() => { this.cancelMode() }}>
                    <CancelIcon id="cancelIcon" />
                  </IconButton>
                )
              }
            })()}
            {(() => {
              if (this.state.editMode) {
                return (
                  <IconButton color='primary' id="juridicalSubjectBoxUpdate" aria-label='download'
                    style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                    onClick={() => { this.saveData() }}>
                    <SaveIcon id="juridicalSubjectBoxUpdateSaveIcon" />
                  </IconButton>)
              }
            })()}
            {this.specificScoreComponent}
          </GridItem>
        </GridContainer>
        <br />
      </div>
    )
  }
  downloadPdf(platformId, idRichiesta) {
    let queryString = '?platformId=' + platformId + '&idRichiesta=' + idRichiesta;
    let headerWithAuth = AuthService.getAuthHeaderAsObj();

    axios.post(AuthService.postApiForDownloadDocumentForTeeJuridicalSubject() + queryString, { platformId, idRichiesta }, {
      headers: headerWithAuth
    })
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        this.deniedOperation(
          'Impossibile visualizzare le informazioni',
          'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.'
        )
      })
  }
  sendJsonOnS3(idRequest) {
    axios.get(AuthService.getBackendApiTeeSendJsonOnS3() + '?idRequest=' + idRequest,
      {
        headers: {
          Accept: 'application/json',
          Authorization: AuthService.getLoggedUserJwtToken()
        }
      }
    )
      .then(response => {
        if (response.data.success === true) {
          let title = 'Json file sent'
          let message = response.data.message
          this.redirectAfterSend(title, message)
        } else {
          let title = 'Error sending Json'
          let message = response.data.message
          this.errorSendAlert(title, message)
        }
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config
          originalRequest._retry = true
          let refreshToken2 = localStorage.getItem('loggedRefreshToken')
          let tokenVecchio2 = localStorage.getItem('loggedToken')
          let username2 = localStorage.getItem('loginEmail')
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem('loggedToken')
              localStorage.removeItem('loggedRefreshToken')
              localStorage.setItem('loggedToken', rest.data.payload.jwtToken)
              localStorage.setItem(
                'loggedRefreshToken',
                rest.data.payload.jwtTokenRefresh
              )
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken)
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              )
              originalRequest.headers['Authorization'] =
                rest.data.payload.jwtToken
              originalRequest.headers['TokenRefresh'] =
                rest.data.payload.jwtTokenRefresh
              axios.defaults.headers['Authorization'] =
                rest.data.payload.jwtToken
              axios
                .get(
                  AuthService.getBackendApiTeeSendJsonOnS3() +
                  '?idRequest=' +
                  idRequest,
                  {
                    headers: {
                      Accept: 'application/json',
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  if (response.data.success === true) {
                    let title = 'Json file sent'
                    let message = response.data.message
                    this.redirectAfterSend(title, message)
                  } else {
                    let title = 'Error sending Json'
                    let message = response.data.message
                    this.errorSendAlert(title, message)
                  }
                })
            })
            .catch(error => {
              console.log(error.response)
              AuthService.setLoggedUserJwtToken(null)
              AuthService.setLoggedUserJwtTokenRefresh(null)
              return (window.location.href = '/home')
            })
        }
      })
  }

  errorSendAlert(primaryMsg, secondaryMsg) {
    Swal.fire({
      title: primaryMsg,
      text: secondaryMsg,
      icon: "error",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      reverseButtons: true,
    })
  }

  redirectAfterSend(primaryMsg, secondaryMsg) {
    Swal.fire({
      title: primaryMsg,
      text: secondaryMsg,
      icon: "success",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      reverseButtons: true,
    }).then(() => {
      if (this.state.itemForDetail.rispostaMonitoraggio) {
        window.location.replace('/consolle/richieste-monitoraggio');
      } else {
        window.location.replace('/consolle/storico-richieste');
      }
    });
  }

  getSendJson() {
    if (localStorage.getItem('ValoreSent') === 'true') {
      return true
    }
    return false
  }

  uploadOnS3(platformId, idRichiesta) {
    let options = ""
    for (let tipoDocument of this.state.tipoDocumentiList) {
      options = options + '<option value="' + tipoDocument.nome_documento + '">' + tipoDocument.nome_documento + '</option>'
    }
    ; (async () => {
      const { value: file } = await Swal.fire({
        title: 'Upload File',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Upload',
        reverseButtons: true,
        input: 'file',
        html:
          '<div style="margin-left:-122px"><p style="padding-right:5px;display:inline-block">Choose document type</p><select style="font-size:large" name="doc_type" id="swal-input1" >' + options + '</select></div>',

      })

      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        // formData.append("tipoDocumento",document.getElementById('swal-input1').value)
        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";
        let queryString = '?platformId=' + platformId + '&idRichiesta=' + idRichiesta + '&tipoDocumento=' + document.getElementById('swal-input1').value;
        axios.post(AuthService.postApiForUploadDocumentForTeeJuridicalSubject() + queryString, formData, {
          headers: headerWithAuth
        })
          .then(res => {
            this.state.listaDocumenti.push(res.data)
            this.setState({ listaDocumenti: this.state.listaDocumenti })
            //this.showRedirectAlert(title, message);
          })
          .catch(error => {
            this.deniedOperation(
              'Impossibile visualizzare le informazioni riguardanti gli approfondimenti.',
              'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.'
            )
          })
      }
    })()
  }

  downloadDocumentOfAnalysisRequestResult(documentoDaScaricare) {
    let queryString =
      '?nameOnS3=' +
      documentoDaScaricare.nameOnS3 +
      '&nomeDefaultForDownloadDialogWindows=' +
      documentoDaScaricare.filename
    axios
      .get(
        AuthService.getBackendApiForAnalysisRequestResultFileDownload() +
        queryString,
        {
          responseType: 'blob',
          headers: {
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        var headers = new Headers(response.headers)
        var contentDisposition = headers.get('Content-Disposition')
        var indiceFilename = contentDisposition.search('=')
        var nomeDefault = contentDisposition.substring(
          indiceFilename + 1,
          contentDisposition.length
        )
        var extension = nomeDefault.substring(nomeDefault.lastIndexOf('.') + 1)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nomeDefault)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => console.log(error))
  }

  viewDocumentOfAnalysisRequestResult(documentoDaScaricare) {
    let queryString = '?nameOnS3=' + documentoDaScaricare.nameOnS3 + '&nomeDefaultForDownloadDialogWindows='
      + documentoDaScaricare.filename
    axios.get(AuthService.getBackendApiForAnalysisRequestResultFileDownloadBase64() + queryString,
      {
        headers: {
          Authorization: AuthService.getLoggedUserJwtToken()
        }
      }
    )
      .then(response => {
        var headers = new Headers(response.headers)
        var contentDisposition = headers.get('Content-Disposition')
        var indiceFilename = contentDisposition.search('=') //filename=nomeFileIniziale...Mi prendo l'indice dell'uguale e faccio substring
        var nomeDefault = contentDisposition.substring(
          indiceFilename + 1,
          contentDisposition.length
        )
        var extension = nomeDefault.substring(nomeDefault.lastIndexOf('.') + 1)
        if (extension && extension.localeCompare('pdf') !== 0) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', nomeDefault)
          document.body.appendChild(link)
          link.click()
        } else {
          this.setState({ pdfData: response.data })
          this.setState({ ispdfDataChanged: !this.state.ispdfDataChanged })
        }
      })
      .catch(error => console.log(error))
  }
  deniedOperation(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '140px' }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color='success'
                  onClick={() => {
                    this.hideAlert()
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: '28px' }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    })
    setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false)
  }

  extractPositionDetailFromBackend(positionFiscalCode) {
    let appName = AuthService.getApplicationName()
    let apiUrlToCall = ''

    if (this.props.itemObj.personaFisica && this.props.itemObj.personaFisica === true)
      apiUrlToCall = AuthService.getBackendApiUtpPersonDetail()
    else
      apiUrlToCall = AuthService.getBackendApiUtpCompanyDetail()

    axiosApiCaller.post(apiUrlToCall, { applicationName: appName, fiscalCode: positionFiscalCode }).then(res => {
      if (res.data.success === true) {
        this.setState({ itemForDetail: res.data.payload })
        if (
          res.data.payload.personaFisica &&
          res.data.payload.personaFisica === true
        ) {
          this.setState({
            typeForDetail: CascoDashboard.PERSONA_FISICA_LABEL
          })
        } else {
          this.setState({
            typeForDetail: CascoDashboard.PERSONA_GIURIDICA_LABEL
          })
        }
        this.setState({ errorMessage: null })
      }
      else
        this.setState({ errorMessage: res.data.message })
    })
      .catch(error => {
        this.setState({ errorMessage: error.message })
      })
  }
}

ReportMultiVisualizer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemObj: PropTypes.object.isRequired,
  itemDescription: PropTypes.string.isRequired,
  itemIcon: PropTypes.object.isRequired
}

export default ReportMultiVisualizer
