import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Family from "@material-ui/icons/People";
import Process from "@material-ui/icons/Settings";
import Warn from "@material-ui/icons/Warning";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import ApprofondimentiAccordion from "components/Accordion/ApprofondimentiAccordion.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import DetailVisualizer from "components/COMMON/DetailVisualizer.js";
import SingleColumnDetailVisualizer from "components/COMMON/SingleColumnDetailVisualizer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from 'prop-types';
import React from "react";
import Warning from "../Typography/Warning";




const localStyles = {
    allCentered: {
        margin: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
    },
    allCenteredMessage: {
        textAlign: 'center',
        height: '30px',
        lineHeigth: '30px',
        padding: '0px'
    },
    completedRound: {
        color: "#34a853"
    },
    warningRound: {
        color: "#fba706"
    },
    errorRound: {
        color: "#ea4335"
    },
    partialRound: {
        background: "grey",
        paddingTop: "12px",
        paddingBottom: "12px"
    }
}

const styles = {
    ...localStyles,
    ...dashboardStyles
}

class ApprofondimentoVisualizer extends React.Component {

    constructor(props) {
        super(props);
        this.cleanedData = this.cleanData(this.props.itemForDetail);
        this.state = this.getInitialState();
        this.listaDocumenti = this.props.listaDocumenti;
        this.listaDocumentiDaEliminare = [];
        this.handleChangeItem = this.handleChangeItem.bind(this);
        this.handleChangeDocumentInDeleteCaseFromChild = this.handleChangeDocumentInDeleteCaseFromChild.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleDownloadDocumentFromChild = this.handleDownloadDocumentFromChild.bind(this);
        this.handleViewDocumentFromChild = this.handleViewDocumentFromChild.bind(this);
    }

    componentDidUpdate(nextProps) {
        if (nextProps.listaDocumenti !== this.props.listaDocumenti) {
            this.setState({ listaDocumenti: this.props.listaDocumenti });
            this.setState({ isListaDocumentiChanged: !this.state.isListaDocumentiChanged });
        }
        if((nextProps.ispdfDataChanged !== this.props.ispdfDataChanged) ) {
            this.setState({pdfData: this.props.pdfData});
        }
      }

    getInitialState() {
        return {
            errorMessage: this.props.errorMessage,
            imageIndex: -1,
            editMode: false,
            documentazione: this.cleanedData.documentazione,
            listaDocumenti: this.props.listaDocumenti ? this.props.listaDocumenti : [],
            isListaDocumentiChanged: false,
            //pdfData: null,
            pdfData: this.props.pdfData
        };
    }

    handleChangeDocumentInDeleteCaseFromChild(newList, documentoDaEliminare) {
        //this.cleanedData.foreignActualOwners = newList;
        this.setState({ listaDocumenti: newList });
        this.setState({ isListaDocumentiChanged: !this.state.isListaDocumentiChanged });
        this.listaDocumentiDaEliminare.push(documentoDaEliminare);
    }

    handleViewDocumentFromChild(documentoDaScaricare) {
        this.setState({pdfData: null});
        this.props.click(documentoDaScaricare);
    }

    handleDownloadDocumentFromChild(documentoDaScaricare) {
        //this.setState({pdfData: null});
        this.props.download(documentoDaScaricare);
    }

    getItemForDetail() {
        return this.props.itemForDetail;
    }

    cleanData() {
        let theData = this.props.itemForDetail;
        return theData;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleChangeItem(event) {
        if (event.target.id == "note") {
            this.cleanedData.note = event.target.value;
        }
    }

    formatSessionDateTime(dateTime) {
        var date = new Date(dateTime);
        if (date instanceof Date && isFinite(date)) {
            return (new Intl.DateTimeFormat("it", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }).format(date));
        } else {
            return dateTime;
        }
    }

    handleChangeState(event) {
        this.cleanedData.elaborationState = event.target.value;
        this.setState({ editMode: this.state.editMode }); //Cambiamento fittizio
    }

    render() {
        const { classes } = this.props;
        this.cleanedData = this.cleanData(this.props.itemForDetail);
        let monitoraggioInfo = null;
        monitoraggioInfo = (<h4 style={{ margin: '0px 0px 15px 0px' }} className={classes.activatedCardTitle} ><b>{this.cleanedData.elaborationState}</b></h4>);
        
        /*
        if (this.cleanedData.monitoraggioFlag === true) {
            monitoraggioInfo = (<h4 style={{ margin: '0px 0px 15px 0px' }} className={classes.activatedCardTitle} ><b>Monitoraggio Attivo</b></h4>);
        } else {
            monitoraggioInfo = (<h4 style={{ margin: '0px 0px 15px 0px' }} className={classes.deactivatedCardTitle} ><b>Monitoraggio Non Attivo</b></h4>);
        }
        */

        let elaborationState = "";
        if (this.cleanedData.elaborationState == "COMPLETATA") {
            elaborationState = <div>
                <div style={localStyles.completedRound}>
                    <b>  {this.cleanedData.elaborationState}</b>
                </div>
            </div>
        } else
            if (this.cleanedData.elaborationState == "APPROFONDIRE") {
                elaborationState = <div>
                    <div style={localStyles.warningRound}>
                        <b> {this.cleanedData.elaborationState}</b>
                    </div>
                </div>
            } else
                if (this.cleanedData.elaborationState == "ERRORE") {
                    elaborationState = <div><div style={localStyles.errorRound}><b>{this.cleanedData.elaborationState}</b></div></div>
                } else
                    if (this.cleanedData.elaborationState == "ELABORAZIONE") {
                        elaborationState = <div><div style={localStyles.partialRound}><b style={{ color: "white" }}>{this.cleanedData.elaborationState}</b></div></div>
                    } else {
                        elaborationState = this.cleanedData.elaborationState;
                    }


        let labelMarginLeft = "";
        let labelBackgroundColor = "";
        let labelBorder = "";
        switch (this.cleanedData.elaborationState) {
            case "COMPLETATA":
                labelMarginLeft = "86%";
                labelBackgroundColor = "rgb(52, 168, 83)";
                labelBorder = "1px solid rgb(34, 112, 55)";
                break;
            case "APPROFONDIRE":
                labelMarginLeft = "88%";
                labelBackgroundColor = "rgb(255, 152, 0)";
                labelBorder = "1px solid rgb(170, 101, 0)";
                break;
            case "ERRORE":
                labelMarginLeft = "90%";
                labelBackgroundColor = "rgb(215, 2, 6)";
                labelBorder = "1px solid rgb(143, 1, 4)";
                break;
            case "ELABORAZIONE":
                labelMarginLeft = "81%";
                labelBackgroundColor = "rgb(178, 178, 178)";
                labelBorder = "1px solid rgb(118, 118, 118)";
                break;
            case "RICHIESTA":
                labelMarginLeft = "84%";
                labelBackgroundColor = "rgb(23, 105, 255)";
                labelBorder = "1px solid rgb(15, 70, 170)";
                break;
            case "SOSPESA":
                labelMarginLeft = "88%";
                labelBackgroundColor = "rgb(103, 17, 141)";
                labelBorder = "1px solid rgb(68, 11, 94)";
                break;
            case "ANNULLATA":
                labelMarginLeft = "88%";
                labelBackgroundColor = "rgb(5, 23, 56)";
                labelBorder = "1px solid rgb(3, 15, 37)";
                break;
        }

        let labelStyle = {
            display: "inline-block",
            display: "inline-block",
            marginLeft: labelMarginLeft,
            padding: "6px",
            padding: "6px",
            background: labelBackgroundColor,
            border: labelBorder,
            borderRadius: "4px"
        };
        const rightDetailsColumn = [
            {
                // Anagraphical data section
                inverted: false,
                badgeColor: "success",
                badgeIcon: this.props.mainIcon,
                title: "Dati Anagrafici",
                titleColor: "success",
                body: (
                    <div>
                        <h6 style={labelStyle} className={classes.cardTitle}>
                            <b style={{ color: "white" }}>  {this.cleanedData.elaborationState}</b>
                        </h6>
                        <div>
                            <h3 style={{ margin: '0px 0px 5px 0px', display: 'inline-block' }} className={classes.cardTitle}>
                                <b><span>{this.cleanedData.businessName}</span></b>
                            </h3>
                            {/*<span style={{ position: 'relative', bottom: '4px' }}><SmallMenu ragioneSociale={this.cleanedData.ragioneSociale} /></span> */}
                        </div>
                        {(() => {
                            if (!this.props.editMode) {
                                return <div>
                                    <h6 className={classes.cardTitle}>
                                        <b>Tipologia</b>: {this.cleanedData.tipo}
                                    </h6>
                                    <h6 className={classes.cardTitle}>
                                        <b>Creatore</b>: {this.cleanedData.creatore}
                                    </h6>
                                    <h6 className={classes.cardTitle}>
                                        <b>Note</b>: { this.cleanedData.note ? this.cleanedData.note : "(Non disponibili)"}
                                    </h6>
                                    <h6 className={classes.cardTitle}>
                                        <b>Applicazione</b>: {this.cleanedData.applicazione}
                                    </h6>
                                </div>
                            } else {
                                return <div>
                                    <div>
                                        <TextField
                                            style={{ width: "80%", float: "left", margin: '2%' }}
                                            id="note"
                                            label="Note"
                                            multiline
                                            defaultValue={this.cleanedData.note}
                                            rows={4}
                                            onChange={this.handleChangeItem}
                                            variant="outlined"
                                        />
                                    </div>                                    
                                    <br></br>
                                    <div style={{ margin: '2%' }}><GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Stato Elaborazione</InputLabel>
                                                <Select id="selectItem"
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    displayEmpty
                                                    value={this.cleanedData.elaborationState}
                                                    onChange={this.handleChangeState}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                >
                                                    <MenuItem key={1} id="1" value={"APPROFONDIRE"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'APPROFONDIRE'}
                                                    </MenuItem>
                                                    <MenuItem key={2} id="2" value={"ERRORE"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'ERRORE'}
                                                    </MenuItem>
                                                    <MenuItem key={3} id="3" value={"ELABORAZIONE"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'ELABORAZIONE'}
                                                    </MenuItem>
                                                    <MenuItem key={4} id="4" value={"COMPLETATA"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'COMPLETATA'}
                                                    </MenuItem>
                                                    <MenuItem key={5} id="5" value={"RICHIESTA"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'RICHIESTA'}
                                                    </MenuItem>
                                                   
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer> </div>
                                    <br></br>
                                </div>;
                            }
                        })()}
                    </div>
                )
            }
        ];

        const leftDetailsColumn = [
            {
                inverted: false,
                badgeColor: "danger",
                badgeIcon: Process,
                title: "Dati di Processo",
                titleColor: "warning",
                body: (
                    <div>
                        {monitoraggioInfo}
                        {/*
                        <h6 className={classes.cardTitle}>
                            <b>Monitoraggio</b>: {this.cleanedData.monitoraggio}
                        </h6>
                        <h6 className={classes.cardTitle}>
                            <b>Data di aggiornamento</b>: {this.formatSessionDateTime(this.cleanedData.dataAggiornamento)}
                        </h6>
                        */}
                    </div>
                ),
            }
        ];

        const ownersDetailsColumn = [
            {
                inverted: false,
                badgeColor: "warning",
                badgeIcon: Family,
                title: "Soci e Titolari",
                titleColor: "danger",
                body: (
                    <Card>
                        <CardHeader>
                            <h3 className={classes.cardTitle}><b>Documenti</b></h3>
                        </CardHeader>
                        <CardBody>
                            <ApprofondimentiAccordion
                                active={0}
                                editMode={this.props.editMode}
                                collapses={this.state.listaDocumenti}
                                isListaDocumentiChanged = {this.state.isListaDocumentiChanged}
                                onChange={this.handleChangeDocumentInDeleteCaseFromChild} 
                                click={this.handleViewDocumentFromChild}
                                download = {this.handleDownloadDocumentFromChild}
                                pdfData={this.state.pdfData}
                                tipoDocumentiList={this.props.tipoDocumentiList}

                            />
                        </CardBody>
                    </Card>
                )
            }
        ]

        let maybeErrorMsg = null;
        if (this.props.errorMessage !== null) {
            maybeErrorMsg = (<div className={classes.allCenteredMessage} >
                <Warning><Warn style={{ verticalAlign: "middle" }} />&nbsp;&nbsp;ATTENZIONE: {this.props.errorMessage} I dati mostrati sono quelli disponibili nella sezione generale.</Warning>
            </div>);
        }
        return (
            <div style={{ textAlign: "left" }}>
                {maybeErrorMsg}
                <GridContainer style={{ textAlign: "center" }}>
                    <GridItem xs={12} sm={12} md={12}>
                       <DetailVisualizer simple leftDetails={leftDetailsColumn} rightDetails={rightDetailsColumn} showIcons={true} showTitles={true} />
                       {/*<SingleColumnDetailVisualizer simple details={rightDetailsColumn} showIcons={true} showTitles={true} />  */}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <SingleColumnDetailVisualizer simple details={ownersDetailsColumn} showIcons={true} showTitles={true} />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

ApprofondimentoVisualizer.propTypes = {
    itemForDetail: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    mainIcon: PropTypes.object.isRequired,
}

export default withStyles(styles)(ApprofondimentoVisualizer);
