/*eslint-disable*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Button from "components/CustomButtons/Button.js";
import * as AuthService from 'services/AuthService.js';
import AccountBox from "@material-ui/icons/AccountBox";
import ExitToApp from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles(styles);

function formatSessionDateTime(dateTime) {
  //console.log(JSON.stringify(dateTime));
  var date = new Date(dateTime);
  return (new Intl.DateTimeFormat("it", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric", 
    minute: "numeric", 
    second: "numeric"
  }).format(date));
}

export default function FixedPlugin(props) {
  const [classes, setClasses] = React.useState("dropdown show");
  const [bg_checked, setBg_checked] = React.useState(true);
  const [bgImage, setBgImage] = React.useState(props.bgImage);
  const [showImage, setShowImage] = React.useState(true);



  const handleClick = () => {
    props.handleFixedClick();
  };
  const handleChange = name => event => {
    switch (name) {
      case "miniActive":
        props.sidebarMinimize();
        break;
      case "image":
        if (event.target.checked) {
          props.handleImageClick(bgImage);
        } else {
          props.handleImageClick();
        }
        setShowImage(event.target.checked);
        break;
      default:
        break;
    }
  };
  const classesObj = useStyles();
  let sessionDateTime;
  if (AuthService.getLoggedUserCurrentSession() && (AuthService.getLoggedUserCurrentSession() !== null)) {
    sessionDateTime = formatSessionDateTime(AuthService.getLoggedUserCurrentSession().startTime);
  } else {
    sessionDateTime = " ";
  }
  return (
    <div
      style={{marginTop: "-90px"}}
      className={"fixed-plugin" + (props.rtlActive ? " fixed-plugin-rtl" : "")}
    >
      <div id="fixedPluginClasses" className={props.fixedClasses}>
        <div onClick={handleClick}>
          <i className="fa fa-cog fa-2x" />
        </div>
        <ul className="dropdown-menu">
            <li className="header-title" style={{'textAlign':'left'}}>
              <div style={{textAlign:'center', marginTop: '12px'}}>
                Logged in:&nbsp;&nbsp;&nbsp;{sessionDateTime}
              </div>       
            </li>
            <li className="header-title">
              <div >
                <a href={'/consolle/profile'} style={{}}>
                  <div style={{'float':'left', 'height':'34px'}}>                      
                      <AccountBox style={{fontSize: '34px'}}/>                      
                  </div>
                  <div style={{float:"left", marginTop:"7px"}}>
                      &nbsp;&nbsp;{AuthService.getLoggedUserObject().dottedFullName}
                  </div>
                </a>
              </div>
              <div >
                  <div style={{'float':'right'}}>
                      <a href={'/consolle/logout'} style={{'height':'34px'}}>
                          <ExitToApp style={{fontSize: '34px'}}/>
                      </a>
                  </div>
                  <div style={{float:"right",marginTop:"7px"}}>
                      <a href={'/consolle/logout'} style={{'height':'34px'}}>Logout&nbsp;&nbsp;</a>
                  </div>
              </div>
            </li>
          <li className="header-title"></li>
          <li className="header-title"></li>
          <li className="header-title">Pulsanti dei menù</li>
          <li className="adjustments-line">
            <a className="switch-trigger active-color">
              <div className="badge-colors text-center">
                <span
                  className={
                    props.color === "purple"
                      ? "badge filter badge-purple active"
                      : "badge filter badge-purple"
                  }
                  data-color="purple"
                  onClick={() => {
                    props.handleColorClick("purple");
                  }}
                />
                <span
                  className={
                    props.color === "blue"
                      ? "badge filter badge-blue active"
                      : "badge filter badge-blue"
                  }
                  data-color="blue"
                  onClick={() => {
                    props.handleColorClick("blue");
                  }}
                />
                <span
                  className={
                    props.color === "green"
                      ? "badge filter badge-green active"
                      : "badge filter badge-green"
                  }
                  data-color="green"
                  onClick={() => {
                    props.handleColorClick("green");
                  }}
                />
                <span
                  className={
                    props.color === "red"
                      ? "badge filter badge-red active"
                      : "badge filter badge-red"
                  }
                  data-color="red"
                  onClick={() => {
                    props.handleColorClick("red");
                  }}
                />
                <span
                  className={
                    props.color === "orange"
                      ? "badge filter badge-orange active"
                      : "badge filter badge-orange"
                  }
                  data-color="orange"
                  onClick={() => {
                    props.handleColorClick("orange");
                  }}
                />
                <span
                  className={
                    props.color === "white"
                      ? "badge filter badge-white active"
                      : "badge filter badge-white"
                  }
                  data-color="orange"
                  onClick={() => {
                    props.handleColorClick("white");
                  }}
                />
                <span
                  className={
                    props.color === "rose"
                      ? "badge filter badge-rose active"
                      : "badge filter badge-rose"
                  }
                  data-color="orange"
                  onClick={() => {
                    props.handleColorClick("rose");
                  }}
                />
              </div>
              <div className="clearfix" />
            </a>
          </li>
          <li className="header-title">Sfondo barra dei menù</li>
          <li className="adjustments-line">
            <a className="switch-trigger active-color">
              <div className="badge-colors text-center">
                <span
                  className={
                    props.bgColor === "blue"
                      ? "badge filter badge-blue active"
                      : "badge filter badge-blue"
                  }
                  data-color="orange"
                  onClick={() => {
                    props.handleBgColorClick("blue");
                  }}
                />
                <span
                  className={
                    props.bgColor === "white"
                      ? "badge filter badge-white active"
                      : "badge filter badge-white"
                  }
                  data-color="orange"
                  onClick={() => {
                    props.handleBgColorClick("white");
                  }}
                />
                <span
                  className={
                    props.bgColor === "black"
                      ? "badge filter badge-black active"
                      : "badge filter badge-black"
                  }
                  data-color="orange"
                  onClick={() => {
                    props.handleBgColorClick("black");
                  }}
                />
              </div>
              <div className="clearfix" />
            </a>
          </li>
          <li className="adjustments-line">
            <a className="switch-trigger">
              <p className="switch-label" style={{margin: "10px 0px 0px 40px", fontSize: "14px"}}>Minimizza menù</p>
              <Switch
                checked={props.miniActive}
                onChange={handleChange("miniActive")}
                value="sidebarMini"
                classes={{
                  switchBase: classesObj.switchBase,
                  checked: classesObj.switchChecked,
                  thumb: classesObj.switchIcon,
                  track: classesObj.switchBar
                }}
              />
              <div className="clearfix" />
            </a>
          </li>
          <li className="adjustments-line">
            <a className="switch-trigger">
              <p className="switch-label" style={{margin: "10px 0px 0px 40px", fontSize: "14px"}}>Background menù</p>
              <Switch
                checked={showImage}
                onChange={handleChange("image")}
                value="sidebarMini"
                classes={{
                  switchBase: classesObj.switchBase,
                  checked: classesObj.switchChecked,
                  thumb: classesObj.switchIcon,
                  track: classesObj.switchBar
                }}
              />
              <div className="clearfix" />
            </a>
          </li>

        </ul>
      </div>
    </div>
  );
}

FixedPlugin.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  miniActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  handleBgColorClick: PropTypes.func,
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  rtlActive: PropTypes.bool
};
