import {
  infoColor,
  cascoInfoColor,
  appMainColors,
  utpGeoChartColors,
  utpTipologiaChartColors,
  utpStateChartColors,
  utpStrategiaChartColors,
  teeCardDangerColors,
  teeCardSuccessColors,
  teeCardWarningColors,
  primaryColor,
  successColor,
  dangerColor,
  warningColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
const timelineStyle = theme => ({
  timeline: {
    [theme.breakpoints.down("sm")]: {
      "&:before": {
        left: "5% !important"
      }
    },
    listStyle: "none",
    padding: "20px 0 20px",
    position: "relative",
    marginTop: "0px",
    "&:before": {
      top: "50px",
      bottom: "0",
      position: "absolute",
      content: '" "',
      width: "3px",
      backgroundColor: grayColor[11],
      left: "50%",
      marginLeft: "-1px"
    }
  },
  timelineWithoutLine: {
    [theme.breakpoints.down("sm")]: {
      "&:before": {
        left: "5% !important"
      }
    },
    listStyle: "none",
    padding: "20px 0 20px",
    position: "relative",
    marginTop: "0px"
  },
  timelineSimple: {
    marginTop: "0px",
    padding: "0 0 0 0",
    "&:before": {
      left: "5%"
    }
  },
  timelineSimpleWithoutLine: {
    marginTop: "0px",
    padding: "0 0 0 0"
  },
  item: {
    marginBottom: "10px",
    position: "relative",
    "&:before,&:after": {
      content: '" "',
      display: "table"
    },
    "&:after": {
      clear: "both"
    }
  },
  timelineBadge: {
    [theme.breakpoints.down("sm")]: {
      left: "5% !important"
    },
    color: whiteColor,
    width: "113px",
    height: "42px",
    lineHeight: "17px",
    fontSize: "1.1em",
    fontWeight: "470",
    textAlign: "right",
    paddingRight: "8px",
    paddingTop: "10px",
    position: "absolute",
    top: "10px",
    left: "5%",
    marginLeft: "-15px",
    zIndex: "100",
    borderTopRightRadius: "5%",
    borderTopLeftRadius: "5%",
    borderBottomRightRadius: "5%",
    borderBottomLeftRadius: "5%"
  },
  timelineSimpleBadge: {
    left: "5%"
  },


  timelineSingleColumnBadge: {
    [theme.breakpoints.down("sm")]: {
      left: "2.5% !important"
    },
    color: whiteColor,
    width: "113px",
    height: "42px",
    lineHeight: "17px",
    fontSize: "1.1em",
    fontWeight: "470",
    textAlign: "right",
    paddingRight: "8px",
    paddingTop: "10px",
    position: "absolute",
    top: "10px",
    left: "5%",
    marginLeft: "-15px",
    zIndex: "100",
    borderTopRightRadius: "5%",
    borderTopLeftRadius: "5%",
    borderBottomRightRadius: "5%",
    borderBottomLeftRadius: "5%"
  },
  timelineSingleColumnSimpleBadge: {
    left: "2.5%"
  },



  info: {
    backgroundColor: appMainColors[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(appMainColors[0]) +
      ", 0.4)"
  },
  success: {
    background: "linear-gradient(60deg, " + teeCardSuccessColors[0] + ", " + teeCardSuccessColors[1] + ")",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(teeCardSuccessColors[0]) +
      ", 0.4)"
  },
  danger: {
    background: "linear-gradient(60deg, " + teeCardDangerColors[0] + ", " + teeCardDangerColors[1] + ")",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(teeCardDangerColors[0]) +
      ", 0.4)"
  },
  warning: {
    background: "linear-gradient(60deg, " + teeCardWarningColors[0] + ", " + teeCardWarningColors[1] + ")",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(teeCardWarningColors[0]) +
      ", 0.4)"
  },
  primary: {
    background: "linear-gradient(60deg, " + utpStrategiaChartColors[0] + ", " + utpStrategiaChartColors[1] + ")",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(utpStrategiaChartColors[0]) +
      ", 0.4)"
  },
  badgeIcon: {
    width: "24px",
    height: "24px",
    float: "left",
    marginLeft: "5px",
    marginTop: "4px"
  },
  timelinePanel: {
    [theme.breakpoints.down("sm")]: {
      float: "right !important",
      width: "86% !important",
      "&:before": {
        borderLeftWidth: "0 !important",
        borderRightWidth: "15px !important",
        left: "-15px !important",
        right: "auto !important"
      },
      "&:after": {
        borderLeftWidth: "0 !important",
        borderRightWidth: "14px !important",
        left: "-14px !important",
        right: "auto !important"
      }
    },
    width: "45%",
    float: "left",
    padding: "25px",
    marginBottom: "20px",
    position: "relative",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
    "&:before": {
      position: "absolute",
      top: "26px",
      right: "-15px",
      display: "inline-block",
      borderTop: "15px solid transparent",
      borderLeft: "15px solid " + grayColor[10],
      borderRight: "0 solid " + grayColor[10],
      borderBottom: "15px solid transparent",
      content: '" "'
    },
    "&:after": {
      position: "absolute",
      top: "27px",
      right: "-14px",
      display: "inline-block",
      borderTop: "14px solid transparent",
      borderLeft: "14px solid " + whiteColor,
      borderRight: "0 solid " + whiteColor,
      borderBottom: "14px solid transparent",
      content: '" "'
    }
  },
  timelineSimplePanel: {
    width: "86%"
  },


  timelineSingleColumnPanel: {
    [theme.breakpoints.down("sm")]: {
      float: "right !important",
      width: "96.5% !important",
      "&:before": {
        borderLeftWidth: "0 !important",
        borderRightWidth: "15px !important",
        left: "-15px !important",
        right: "auto !important"
      },
      "&:after": {
        borderLeftWidth: "0 !important",
        borderRightWidth: "14px !important",
        left: "-14px !important",
        right: "auto !important"
      }
    },
    width: "45%",
    float: "left",
    padding: "25px",
    marginBottom: "20px",
    position: "relative",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
    "&:before": {
      position: "absolute",
      top: "26px",
      right: "-15px",
      display: "inline-block",
      borderTop: "15px solid transparent",
      borderLeft: "15px solid " + grayColor[10],
      borderRight: "0 solid " + grayColor[10],
      borderBottom: "15px solid transparent",
      content: '" "'
    },
    "&:after": {
      position: "absolute",
      top: "27px",
      right: "-14px",
      display: "inline-block",
      borderTop: "14px solid transparent",
      borderLeft: "14px solid " + whiteColor,
      borderRight: "0 solid " + whiteColor,
      borderBottom: "14px solid transparent",
      content: '" "'
    }
  },
  timelineSingleColumnSimplePanel: {
    width: "96.5%"
  },

  timelineSingleColumnPanelInverted: {
    [theme.breakpoints.up("sm")]: {
      float: "right",
      backgroundColor: whiteColor,
      "&:before": {
        borderLeftWidth: "0",
        borderRightWidth: "15px",
        left: "-15px",
        right: "auto"
      },
      "&:after": {
        borderLeftWidth: "0",
        borderRightWidth: "14px",
        left: "-14px",
        right: "auto"
      }
    }
  },









  timelinePanelInverted: {
    [theme.breakpoints.up("sm")]: {
      float: "right",
      backgroundColor: whiteColor,
      "&:before": {
        borderLeftWidth: "0",
        borderRightWidth: "15px",
        left: "-15px",
        right: "auto"
      },
      "&:after": {
        borderLeftWidth: "0",
        borderRightWidth: "14px",
        left: "-14px",
        right: "auto"
      }
    }
  },
  timelineHeading: {
    marginBottom: "15px"
  },
  timelineBody: {
    fontSize: "14px",
    lineHeight: "21px"
  },
  timelineFooter: {
    zIndex: "1000",
    position: "relative",
    float: "left"
  },
  footerTitle: {
    color: grayColor[7],
    fontWeight: "400",
    margin: "10px 0px 0px"
  },
  footerLine: {
    marginTop: "10px",
    marginBottom: "5px"
  }
});

export default timelineStyle;
