import axios from "axios";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { axiosApiCaller, HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED } from "layouts/AxiosService";
import React, { useState } from "react";
import * as AuthService from "services/AuthService.js";
import StatusRobotList from "./StatusRobotList";

const ViewStatusRobot = (props) => {
  const [statusRobotList, setStatusRobotList] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  const fetchStatusRobot = () => {
    let allData = [];
    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST +
          "/data-analysis/internal/analysis/process-crawling",
        {
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        response.data.map((a) => {
          allData.push(a);
          setStatusRobotList((prevList) => [...prevList, a]);
        });
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForSearchRobot(),
                  {
                    applicationName: "TEE",
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount,
                  },
                  {
                    responseType: "application/json",

                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  // setStatusRobotList(response);
                })
                .catch((error) => {
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch((error) => {
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  };

  const seeStatusRobot = () => {
    statusRobotList.map((a) => console.log(a));
    setDataIsLoaded(true);
  };

  const seeList = () => {
    let data = [];
    statusRobotList.map((a) => data.push(<div>{a}</div>));
    return data;
  };

  return (

    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <StatusRobotList listStatusRobot={statusRobotList} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ViewStatusRobot;
