import List from "@material-ui/icons/ViewList";
import FilterableApprofondimentiList from 'components/COMMON/FilterableApprofondimentiList';
import FilterableMultiApprofondimentiCustomTabs from "components/COMMON/FilterableMultiApprofondimentiCustomTabs";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from 'prop-types';
import React from "react";
import * as AuthService from 'services/AuthService.js';

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "26px",
    fontWeight: "450",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

export const CUSTOMERS_LIST_TAB_INDEX = 0;
//export const CUSTOMER_SCORING_TAB_INDEX = 1;

class FilterableApprofondimentiManagement extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.customTabsRef = React.createRef();
  }

  getInitialState() {
    return {
      selectedTab: (CUSTOMERS_LIST_TAB_INDEX),
      currentLoggedUserCanManageScoring: (AuthService.authenticathedUserCanManageCustomersScoring())
    };
  }

  componentWillReceiveProps(props) {

  }


  componentDidMount() {
  }


  componentWillUnmount() {
  
  }

  componentDidUpdate() {
  }

  getActiveTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Approfondimenti selezionati",
        tabIcon: List,
        tabContent: (
          <FilterableApprofondimentiList
            itemsList={this.props.allItemsList}
            fieldsList={this.props.fieldsList}
            headersList={this.props.headersList}
            showMonitored={this.props.showMonitored}
            showNotMonitored={this.props.showNotMonitored}
            readOnly={!this.state.currentLoggedUserCanManageScoring}
            customTabsRef={this.customTabsRef}
            showGeoName={this.props.showGeoName}
            showTipologiaName={this.props.showTipologiaName}
            showStatoName={this.props.showStatoName}
            showStrategiaName={this.props.showStrategiaName}
          />
        )
      }
    );
    return tabs;
  }


  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FilterableMultiApprofondimentiCustomTabs style={styles.cardTitleWhite}
              itemsList={this.props.allItemsList}
              selectedTabIndex={this.state.selectedTab}
              headerColor="info"
              fixedTabs={this.getActiveTabs()}
              ref={this.customTabsRef}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

FilterableApprofondimentiManagement.propTypes = {
  allItemsList: PropTypes.arrayOf(
    PropTypes.shape({
      personaFisica: PropTypes.bool,
      ragioneSociale: PropTypes.string,
      codiceFiscale: PropTypes.string,
      comune: PropTypes.string,
      dataCaricamento: PropTypes.string,
      statoLavorazione: PropTypes.string,
      monitoraggioAttivo: PropTypes.bool,
      strategia: PropTypes.string
    })
  ),
  showMonitored: PropTypes.bool,
  showNotMonitored: PropTypes.bool,
  showGeoName: PropTypes.string.isRequired,
  showTipologiaName: PropTypes.string.isRequired,
  showStatoName: PropTypes.string.isRequired,
  showStrategiaName: PropTypes.string.isRequired
};

export default FilterableApprofondimentiManagement;
