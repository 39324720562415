import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Edit from "@material-ui/icons/Edit";
import Pageview from "@material-ui/icons/Pageview";
import SearchIcon from "@material-ui/icons/Search";
import Work from "@material-ui/icons/Work";
import WorkOff from "@material-ui/icons/WorkOff";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import { axiosApiCaller } from "layouts/AxiosService";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import * as AuthService from "services/AuthService.js";
import * as UserManagementTabs from "views/COMMON/UsersManagement.js";

const localStyles = {
  icon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "23px",
    height: "23px",
    top: "0px",
    left: "2px",
    position: "relative",
  },
  actionButton: {
    margin: "5px 5px 5px 5px",
    padding: "0px",
    width: "30px",
    height: "30px",
    minWidth: "auto",
    fontSize: "20px",
  },
  dialogConfirmButton: {
    margin: "0 0 0 5px",
    padding: "20px",
    width: "80px",
    height: "auto",
    minWidth: "auto",
  },
  dialogCancelButton: {
    margin: "0 0 0 5px",
    padding: "7px",
    width: "auto",
    height: "auto",
    minWidth: "auto",
  },
};

const styles = {
  ...localStyles,
  ...extendedTablesStyle,
};

class ContractList extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.warningWithConfirmMessage = this.warningWithConfirmMessage.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.extractAllContractList = this.extractAllContractList.bind(this);
  }

  getInitialState() {
    return {
      contactsTableData: [],
      currentLoggedUser: AuthService.getLoggedUserObject(),
      currentLoggedToken: AuthService.getLoggedUserJwtToken(),
      currentAppName: AuthService.getApplicationName(),
      message: "",
      alert: null,
      dataInizioContrattoFilter: null,
      dataFineContrattoFilter: null,
    };
  }

  componentDidMount() {
    this.extractAllContractList();
  }

  componentWillUnmount() {}

  warningWithConfirmMessage(rowKeyForActions) {
    let confirmationText = "Sei sicuro di voler eliminare il contratto?";
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "140px" }}
          title={confirmationText}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 30px 0px 0px" }}
                  color="warning"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  <Cancel style={{ fontSize: "28px" }} />
                  &nbsp;Annulla
                </Button>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.eliminaContratto(rowKeyForActions);
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Conferma
                </Button>
              </div>
            </React.Fragment>
          }
        >
          Il contratto non sarà più associato all'applicazione. Per
          ripristinarlo occorrerà rivolgersi all'amministratore della
          piattaforma.
          <br />
        </SweetAlert>
      ),
    });
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  successDelete() {
    let confirmationText = "Eliminazione del contratto completata con successo";
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "140px" }}
          title={confirmationText}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
                centeredSingleActionButtonsColumn
              </div>
            </React.Fragment>
          }
        >
          Il contratto non è più associato all'applicazione. Per ripristinarlo
          occorrerà rivolgersi all'amministratore della piattaforma.
          <br />
        </SweetAlert>
      ),
    });
    this.extractAllContractList();
  }

  render() {
    const { classes } = this.props;
    let tableHead = [];
    tableHead.push("Azioni");
    tableHead.push("Applicazione");
    tableHead.push("Data inzio contratto");
    tableHead.push("Data fine contratto");
    tableHead.push("Numero Invii");
    tableHead.push("Progressivo Ko");
    let actionButtonsColumnClasses = null;
    let actionButtonsColumnHeaderClasses = null;
    if (this.props.readOnly) {
      actionButtonsColumnClasses = [classes.centeredSingleActionButtonsColumn];
      actionButtonsColumnHeaderClasses = [
        classes.centeredSingleActionButtonsColumnHead,
      ];
    } else {
      actionButtonsColumnClasses = [classes.centeredTripleActionButtonsColumn];
      actionButtonsColumnHeaderClasses = [
        classes.centeredTripleActionButtonsColumnHead,
      ];
    }
    return (
      <div style={{ width: "93%", marginLeft: "3px" }}>
        {this.state.alert}

        <FormControl fullWidth onKeyPress={this.onKeyUp}>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                name="dataInizioContratto"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data inizio contratto"
                value={this.state.dataInizioContrattoFilter}
                //maxDate={this.state.endDate}
                onChange={(event) => {
                  this.handleChangeDataInizio(event);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                name="dataFineContratto"
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data fine contratto"
                value={this.state.dataFineContrattoFilter}
                //maxDate={new Date()}
                //minDate={this.state.startDate}
                onChange={(event) => {
                  this.handleChangeDataFine(event);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />

              <Button
                style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}
                color="success"
                onClick={() => {
                  this.extractAllContractList();
                }}
                onK
                round
                class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-91 makeStyles-success-101 makeStyles-round-122"
                style={{ position: "relative", top: "17px", left: "20px" }}
              >
                Filtra&nbsp;&nbsp;&nbsp;
                <SearchIcon style={{ fontSize: "28px" }} />
              </Button>
            </MuiPickersUtilsProvider>
          </GridItem>
        </FormControl>

        <Table
          tableHeaderColor="warning"
          tableHead={tableHead}
          tableData={this.state.contactsTableData}
          customCellClasses={actionButtonsColumnClasses}
          customClassesForCells={[0]}
          customHeadCellClasses={actionButtonsColumnHeaderClasses}
          customHeadClassesForCells={[0]}
        />
      </div>
    );
  }

  handleChangeDataInizio(value) {
    this.setState({ dataInizioContrattoFilter: value });
  }
  handleChangeDataFine(value) {
    this.setState({ dataFineContrattoFilter: value });
  }

  getButtonsGroupForEnabledUsers(rowKeyForActions) {
    return [
      { color: "success", icon: WorkOff, tooltipText: "Disabilita contratto" },
      { color: "info", icon: Edit, tooltipText: "Modifica contratto" },
      { color: "warning", icon: Cancel, tooltipText: "Elimina contratto" },
    ].map((prop, key) => {
      return (
        <Tooltip
          id="tooltip-x"
          title={prop.tooltipText}
          placement="bottom"
          classes={styles.tooltip}
        >
          <Button
            color={prop.color}
            style={localStyles.actionButton}
            key={key}
            onClick={() => {
              this.doActionForEnabledUsers(key, rowKeyForActions);
            }}
          >
            <prop.icon style={localStyles.icon} />
          </Button>
        </Tooltip>
      );
    });
  }

  getButtonsGroupForDisabledUsers(rowKeyForActions) {
    return [
      { color: "danger", icon: Work, tooltipText: "Abilita contratto" },
      { color: "info", icon: Edit, tooltipText: "Modifica contratto" },
      { color: "warning", icon: Cancel, tooltipText: "Elimina contratto" },
    ].map((prop, key) => {
      return (
        <Tooltip
          id="tooltip-x"
          title={prop.tooltipText}
          placement="bottom"
          classes={styles.tooltip}
        >
          <Button
            color={prop.color}
            style={localStyles.actionButton}
            key={key}
            onClick={() => {
              this.doActionForDisabledUsers(key, rowKeyForActions);
            }}
          >
            <prop.icon style={localStyles.icon} />
          </Button>
        </Tooltip>
      );
    });
  }

  getButtonsGroupForReadOnlyUsers(rowKeyForActions) {
    return [
      {
        color: "info",
        icon: Pageview,
        tooltipText: "Visualizza i dettagli del contratto",
      },
    ].map((prop, key) => {
      return (
        <Tooltip
          id="tooltip-x"
          title={prop.tooltipText}
          placement="bottom"
          classes={styles.tooltip}
        >
          <Button
            color={prop.color}
            style={localStyles.actionButton}
            key={key}
            onClick={() => {
              this.doActionForReadOnlyUsers(key, rowKeyForActions);
            }}
          >
            <prop.icon style={localStyles.icon} />
          </Button>
        </Tooltip>
      );
    });
  }

  convertContractListToTableData(jsonContractData) {
    let rowKeyForActions = "";
    let retArray = [];
    jsonContractData.forEach((element) => {
      rowKeyForActions = element.id;
      let singleArray = [];
      singleArray.push(this.getButtonsGroupForEnabledUsers(rowKeyForActions));
      singleArray.push(element.applicationName ? element.applicationName : "");
      singleArray.push(
        element.dataInizioContratto
          ? this.formatSessionDateTime(element.dataInizioContratto)
          : ""
      );
      singleArray.push(
        element.dataFineContratto
          ? this.formatSessionDateTime(element.dataFineContratto)
          : ""
      );
      singleArray.push(element.numeroInvii ? element.numeroInvii : "");
      singleArray.push(element.progressivoKo ? element.progressivoKo : "");
      retArray.push(singleArray);
    });
    return retArray;
  }

  doActionForDisabledUsers(buttonIndex, rowKeyForActions) {
    if (buttonIndex === 0) {
      this.enableUser(rowKeyForActions);
    } else if (buttonIndex === 1) {
      this.props.customTabsRef.current.setItemForDynamicTabs(rowKeyForActions);
      this.props.customTabsRef.current.setSelectedTab(
        UserManagementTabs.USER_UPDATE_TAB_INDEX
      );
      this.props.customTabsRef.current.showDynamicTabs();
    } else if (buttonIndex === 2) {
      this.warningWithConfirmMessage(rowKeyForActions);
    }
  }

  doActionForEnabledUsers(buttonIndex, rowKeyForActions) {
    if (buttonIndex === 0) {
      this.disableUser(rowKeyForActions);
    } else if (buttonIndex === 1) {
      this.props.customTabsRef.current.setItemForDynamicTabs(rowKeyForActions);
      this.props.customTabsRef.current.setSelectedTab(
        UserManagementTabs.USER_UPDATE_TAB_INDEX
      );
      this.props.customTabsRef.current.showDynamicTabs();
    } else if (buttonIndex === 2) {
      this.warningWithConfirmMessage(rowKeyForActions);
    }
  }

  doActionForReadOnlyUsers(buttonIndex, rowKeyForActions) {
    if (buttonIndex === 0) {
      this.props.customTabsRef.current.setItemForDynamicTabs(rowKeyForActions);
      this.props.customTabsRef.current.setSelectedTab(
        UserManagementTabs.USER_UPDATE_TAB_INDEX - 1
      );
      this.props.customTabsRef.current.showDynamicTabs();
    }
  }

  enableUser(userIdentifier) {
  }

  disableUser(userIdentifier) {

  }

  eliminaContratto(idContratto) {
    axiosApiCaller
      .post(AuthService.deleteContractById() + "/" + idContratto)
      .then((res) => {
        if (res.status == "200") {
          this.successDelete();
        }
      })
      .catch((error) => {
        this.setState({ contactsTableData: [], message: error.message });
      });
  }

  formatSessionDateTime(dateTime) {
    var date = new Date(dateTime);
    return new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // second: "numeric",
    }).format(date);
  }

  extractAllContractList() {
    let appName = AuthService.getApplicationName();
    axiosApiCaller
      .post(AuthService.getContracts(), {
        offSet: 0,
        rowsPerPage: 10,
        size: 300,
        applicationName: appName,
        start:
          this.state.dataInizioContrattoFilter != null
            ? this.state.dataInizioContrattoFilter
            : null,
        end:
          this.state.dataFineContrattoFilter != null
            ? this.state.dataFineContrattoFilter
            : null,
      })
      .then((res) => {
        if (res.data.success === true) {
          let contractList = res.data.payload;
          let dataForTable = this.convertContractListToTableData(contractList);
          this.setState({
            contactsTableData: dataForTable,
            message: res.data.message,
          });
        } else {
          this.setState({ contactsTableData: [], message: res.data.message });
        }
      })
      .catch((error) => {
        this.setState({ contactsTableData: [], message: error.message });
      });
  }
}

ContractList.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  customTabsRef: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContractList);
