import React from "react";
import {
  BrowserRouter as Router,
  Link,
  withRouter
} from "react-router-dom";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import HowToReg from "@material-ui/icons/HowToReg";
import Security from "@material-ui/icons/Security";
import Home from "@material-ui/icons/Home";
import NoEncryption from "@material-ui/icons/NoEncryption";
import * as AuthService from 'services/AuthService.js';
import { AuthConsumer } from "context/AuthContext.js";
import {appMainColors} from "assets/jss/material-dashboard-pro-react.js"

import * as AxiosService from 'layouts/AxiosService';

const styles = {
  cardCategoryWhite: {
    color: "#FFFFFF",
    margin: "0",
    fontSize: "18px",
    fontWeight: "700",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "31px",
    fontWeight: "700",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  cardAvatarRightAlign: {
    textAlign: "right"
  },
  cardCategoryPowered: {
    color: "#FFFFFF",
    margin: "0",
    fontSize: "13px",
    fontWeight: "600",
    marginTop: "0",
    marginBottom: "0"
  },
  centeredLoginForm: {
    width: "55%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      loginEmail: '', password: '', message: '',
      submitted: false, success: false, successRedirect: '/admin', failureRedirect: ''  
    };
  }

  handleLoginEmailChange(event) {
    this.setState({ loginEmail: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  componentDidMount() {
    //AuthService.clearLoggedUserInfos();
  }

  componentWillUnmount() {
  }

  render() {
    let topMessage = null;
    let msg = '';
    const loginButtonsColor = appMainColors[0];
    if (this.state.submitted) {
      if (this.state.success) {
        msg = (<Success variant="h4"><Security />{this.state.message}</Success>);
      } else {
        msg = (<Warning variant="h4"><Security />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message}</Warning>);
      }
      topMessage = (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
            <br />{msg}
          </GridItem>
        </GridContainer>
      )
    } else topMessage = (<br />);
    return (
      // <div style={!this.props.withMargin? styles.centeredLoginForm:{backgroundColor:"white"}}>
      <div style={{backgroundColor:"white"}}>
      <AuthConsumer>
        {({ isAuth, login, logout }) => (
            <GridContainer style={{ width: '100%' }} onKeyUp={(e) => {
              if(e.key=="Enter") {
                this.loginAttempt(isAuth, login, logout);
              }
            }}>            
              <GridItem xs={12} sm={12} md={12}>
              <div style={{margin:"auto", minWidth:"400px"}}>
                <Card>
                <div style={{position: "relative", border:"2px solid rgb(188, 188, 188)"}}>
                  <div style={{position: "absolute", top:"-30px", left:"30px", padding:"0 17px",backgroundColor:"white"}}>
                    <p style={{fontStyle: "italic"}}>
                      <b style={{fontStyle: "normal", fontSize:"1.6em"}}>{AuthService.APPLICATION_DESCRIPTION}</b> <b style={{fontSize:"1.5em", color:"rgb(65,65,65)"}}> Login</b>
                      <br />
                      <b style={{fontSize:"0.85em"}}>Inserisci username e password per autenticarti</b>
                    </p>
                  </div>
                  
                  <CardBody>
                    {topMessage}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          id="emailDiLogin"
                          labelText="E-mail di login"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                            type: "email",
                            autoFocus: true,
                            onChange: (e) => { this.handleLoginEmailChange(e) }
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          id="password"
                          labelText="Password"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <NoEncryption />
                              </InputAdornment>
                            ),
                            type: "password",
                            onChange: (e) => { this.handlePasswordChange(e) }
                          }}
                          makeGray="true"
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <div style={{position:"relative",top:"40px",marginLeft:"auto",marginRight:"auto",mtextAlign: "center"}}>
                      <Button
                        id= "login"
                        style={{ fontSize: '17px'}}
                        onClick={() => { this.loginAttempt(isAuth, login, logout) }}
                        >
                        Login&nbsp;&nbsp;&nbsp;<HowToReg style={{ fontSize: '28px' }} />
                      </Button>
                    </div>
                    <br />
                  </CardFooter>
                </div>
                </Card>
                </div>
              </GridItem>
            </GridContainer> 

        )}
      </AuthConsumer>
      </div>
    );
  }


  fakeLoginAttempt(isAuth, login, logout) {
    let loggedUser = {
      "loginEmail": "marco.mastratisi@sintegra.it",
      "password": "",
      "firstName": "Marco Antonio",
      "lastName": "Mastratisi",
      "birthDate": "1971-10-25T23:00:00.000+0000",
      "birthPlace": "Crotone",
      "id": "5e7599ba2ec18b565d29dfb3",
      "fullName": "Marco Antonio Mastratisi",
      "dottedFullName": "M. A. Mastratisi",
      "extendedFullName": "Marco Mastratisi (marco.mastratisi@sintegra.it)",
      "formattedBirthDate": "martedì 26 ottobre 1971",
      "appName": "Titoloare Effettivo Estero App",
      "appRoleNames": ["CASCO Administrator"],
      "platformRoleNames": []
    };
    let loggedToken = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtYXJjby5tYXN0cmF0aXNpQHNpbnRlZ3JhLml0IiwiZXhwIjoxNTg4MjY1MTg4LCJpYXQiOjE1ODc2NjAzODh9.UqW_3kRYVdZs5RKajHEcFv33pTBPqZ21nzIraYu_TWYs3HkIxUVLQxRwadgR6eJHMpvKLsWqVi74dv9vqP0A5g";
    AuthService.setLoggedUserInfos(loggedUser, loggedToken);
    login();
    this.setState({ message: 'Login effettuato con successo', submitted: true, success: true });
    this.props.history.push("/consolle");
  }


  loginAttempt(isAuth, login, logout) {
    localStorage.clear();
    if (this.state.loginEmail.trim() === '') {
      this.setState({ message: "E' necessario indicare una e-mail per il login", submitted: true, success: false });
      return;
    }
    if (this.state.password.trim() === '') {
      this.setState({ message: "E' necessario indicare una password per il login", submitted: true, success: false });
      return;
    }
    if (verifyEmail(this.state.loginEmail.trim()) === false) {
      this.setState({ message: "E' necessario indicare una e-mail valida per il login", submitted: true, success: false });
      return;
    }

    //this.setState({message: '', submitted: false, success: false});
    let jwtToken = null;
    let jwtTokenRefresh = null;
    //this.setState({submitted: true});
    let appName = AuthService.getApplicationNameForLogin();
    fetch(AuthService.getBackendApiLogin(), {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        loginEmail: this.state.loginEmail,
        password: this.state.password,
        appName: appName
      })
    })
      .then(res => res.json())
      .then((data) => {
        if (data.success === true) {
          if(data.payload){
            if(data.payload){
              jwtToken = data.payload.jwtToken;
              jwtTokenRefresh = data.payload.jwtTokenRefresh;
            } else{
              jwtToken = data.payload.jwtToken;
              jwtTokenRefresh = data.payload.jwtTokenRefresh;
            }
          }
          let everLoggedIn = data.payload ? data.payload.everLoggedIn : null;
          let qrCode = data.payload ? data.payload.qrCodeImage : null;
          AuthService.setLoggedUserJwtToken(jwtToken);
          AuthService.setLoggedUserJwtTokenRefresh(jwtTokenRefresh);
          let tokenWithPrefix = AuthService.getLoggedUserJwtToken();
          //let authHeader = AuthService.AUTHORIZATION_HEADER_NAME;          
          let headerWithAuth = AuthService.getAuthHeaderAsObj();
          AxiosService.setInterceptor();
          localStorage.setItem('loginEmail', this.state.loginEmail);
          if(everLoggedIn == true){
            this.props.history.push("/verify-code");
          } else if (everLoggedIn === false){
            localStorage.setItem('qr-code', qrCode);
            this.props.history.push("/scan-qr-code");
          } else{
            jwtToken = data.payload.jwtToken;
            AuthService.setLoggedUserJwtToken(jwtToken);
            AuthService.setLoggedUserJwtTokenRefresh(jwtTokenRefresh);
            let headerWithAuth = AuthService.getAuthHeaderAsObj();
            AxiosService.setInterceptor();
            fetch(AuthService.getBackendApiApplicationLogin(), {
              method: 'POST',
              headers: headerWithAuth,
              body: JSON.stringify({
                loginEmail: this.state.loginEmail,
                applicationName: appName
              })
            })
              .then(res => res.json())
              .then((data) => {
                if (data.success === true) {
                  let loggedUser = data.payload;
                  AuthService.setLoggedUserInfos(loggedUser, jwtToken, jwtTokenRefresh);
                  login();
                  this.setState({ message: data.message, submitted: true, success: true });
                  localStorage.setItem(AuthService.SELECTED_APPLICATION_NAME, "TEE");
                  this.props.history.push("/consolle/storico-richieste");
                } else {
                  AuthService.clearLoggedUserInfos();
                  logout();
                  this.setState({ message: data.message, submitted: true, success: false });
                }
              })
              .catch((error) => {
                console.log('ERROR COMUNICATION WITH APP LOGIN SERVICE..');
                AuthService.clearLoggedUserInfos();
                logout();
                this.setState({ message: error.message, submitted: true, success: false });
              });
          }
        } else {
          this.setState({ message: data.message, submitted: true, success: false });
        }
      }) 
      .catch((error) => {
        AuthService.clearLoggedUserInfos();
        this.setState({ message: error.message, submitted: true, success: false });
      });
  }


}

export default withRouter(Login);