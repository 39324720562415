import MenuImgClosed from 'components/COMMON/png/menu-closed.png'
import MenuImgOpened from 'components/COMMON/png/menu-opened2.png'
import React from 'react'

const localStyles = {
  menuItem: {
    padding: '4px',
    color: 'rgb(75,75,75)',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    position: 'relative',
    hover: ''
  }
}

class OperationMenu extends React.Component {
  constructor (props) {
    super()
    this.state = {
      alert: null,
      hovered: true,
      isMenuVisible: false
    }
    this.changeVisibility = this.changeVisibility.bind(this)
    this.setBgColour = this.setBgColour.bind(this)

  }

  changeVisibility () {
    this.setState(prevState => {
      return { isMenuVisible: !prevState.isMenuVisible }
    })
  }
  setBgColour (event, hovered) {
    if (hovered) {
      event.currentTarget.style.background = '#A0C64D'
    } else {
        event.currentTarget.style.background = '#FFFFFF'
    }
  }
  render () {
    let imgContainerStyle = {
      cursor: 'pointer',
      display: 'inline-block',
      width: '35px',
      height: '35px',
      background: this.state.isMenuVisible ? 'rgb(240,240,240)' : 'white',
      borderRadius: '50%'
    }

    let imgStyle = {
      position: 'relative',
      top: '7px',
      left: '15px'
    }

    let lv1DivStyle = {
      display: this.state.isMenuVisible ? 'inline-block' : 'none',
      padding: '4px',
      width: '170px',
      position: 'absolute',
      left: '-185px',
      background: 'white',
      borderRadius: '3px',
      boxShadow: '3px 3px 8px rgb(121, 121, 121)',
      fontSize: '11px',
      fontWeight: '450',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      zIndex: '3',
      top:'0px'
    }

    let lv2DivStyle = {
      padding: '4px',
      color: 'rgb(75,75,75)',
      cursor: 'pointer',
      transition: 'background 0.3s ease',
      position: 'relative'
    }

    let lv2DivStyleHovered = {
      padding: '4px',
      background: 'rgb(160, 198, 77)',
      borderRadius: '1px',
      color: 'white',
      cursor: 'pointer'
    }

    let pStyle = {
      marginTop: '0',
      marginBottom: '0',
      padding: '4px 3px 4px 3px',
      textTransform: 'uppercase'
    }

    return (
      <span>
        {this.state.alert}
        <span style={{ position: 'relative' }}>
          <span
            style={imgContainerStyle}
            onClick={() => this.changeVisibility()}
          >
            <img
              style={imgStyle}
              src={this.state.isMenuVisible ? MenuImgOpened : MenuImgClosed}
            />
          </span>

          <div style={lv1DivStyle}>
            {this.props.menus != null
              ? this.props.menus.map((val, index) => {
                  return (
                    <div style={{    marginLeft: "8px",
                        marginRight: "8px"}}
                      onMouseEnter={event => this.setBgColour(event, true)}
                      onMouseLeave={event => this.setBgColour(event, false)}
                    >
                 
                          {val}
                       
                    </div>
                  )
                })
              : null}
          </div>
        </span>
      </span>
    )
  }
}
export default OperationMenu
