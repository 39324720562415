import AccountBox from "@material-ui/icons/AccountBox";
import AccountingIcon from "@material-ui/icons/EventNote";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Group from "@material-ui/icons/Group";
import Logout from "layouts/Logout";
import * as AuthService from "services/AuthService";
import ApprofondimentiListPage from "views/COMMON/ApprofondimentiListPage";
import Contract from "views/COMMON/Contract";
import Documenti from "views/COMMON/Documenti";
import ListaCarichePage from "views/COMMON/ListaCarichePage";
import RequestHistory from "views/COMMON/RequestHistory";
import RequestHistoryMonitoraggio from "views/COMMON/RequestHistoryMonitoraggio";
import RequestResult from "views/COMMON/RequestResult";
import ProfileEditing from "views/COMMON/UserProfileEditing";
import UsersManagement from "views/COMMON/UsersManagement";
import ViewStatusRobot from "views/COMMON/ViewStatusRobot";

var dashRoutes = [
  {
    path: "/users",
    name: "Gestione Utenti",
    description: "Gestione degli utenti dell'applicazione",
    rtlName: "لوحة القيادة",
    icon: Group,
    component: UsersManagement,
    layout: "/consolle",
    fixed: "false",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
    ],
  },
  {
    path: "/storico-richieste",
    name: "Storico richieste",
    description: "Gestione dello storico richieste",
    rtlName: "لوحة القيادة",
    icon: AccountingIcon,
    component: RequestHistory,
    layout: "/consolle",
    fixed: "false",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
    ],
  },
  {
    path: "/richieste-monitoraggio",
    name: "Richieste di monitoraggio",
    description: "Gestione dello storico richieste di monitoraggio",
    rtlName: "لوحة القيادة",
    icon: AccountingIcon,
    component: RequestHistoryMonitoraggio,
    layout: "/consolle",
    fixed: "false",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
    ],
  },
  {
    path: "/lista-approfondimenti",
    name: "Lista approfondimenti",
    description: "Gestione della lista degli approfondimenti",
    rtlName: "لوحة القيادة",
    icon: AccountingIcon,
    component: ApprofondimentiListPage,
    layout: "/consolle",
    fixed: "false",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
    ],
  },

  {
    path: "/lista-cariche",
    name: "Gestione cariche",
    description: "Gestione della lista degli cariche",
    rtlName: "لوحة القيادة",
    icon: AccountingIcon,
    component: ListaCarichePage,
    layout: "/consolle",
    fixed: "false",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
    ],
  }];

  if(AuthService.getApplicationName() !== "ADMINISTRATION_PANEL"){
    //if(localStorage.getItem("applicationValue")==="ADMINISTRATION_PANEL"){
    dashRoutes.push(
  {
    path: "/contratto",
    name: "Contratti",
    description: "Contratti",
    rtlName: "لوحة القيادة",
    icon: AccountingIcon,
    component: Contract,
    layout: "/consolle",
    fixed: "false",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
    ],
  })
}
dashRoutes.push(
  {
    path: "/robot-status",
    name: "Status Robot",
    description: "Status Robot",
    rtlName: "لوحة القيادة",
    icon: AccountingIcon,
    component: ViewStatusRobot,
    layout: "/consolle",
    fixed: "false",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
    ],
  },
  {
    path: "/documenti",
    name: "Tipo documento",
    description: "Gestione della lista degli documenti",
    rtlName: "لوحة القيادة",
    icon: AccountingIcon,
    component: Documenti,
    layout: "/consolle",
    fixed: "false",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
    ],
  },
  {
    path: "/request-result",
    name: "Risultati richiesta",
    description: "Gestione dei risultati di una richiesta",
    icon: AccountingIcon,
    component: RequestResult,
    layout: "/consolle",
    fixed: "true",
    visible: false,
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
    ],
  },
  {
    path: "/profile",
    name: "Gestione Profilo",
    description: "Gestione del profilo personale",
    rtlName: "لوحة القيادة",
    icon: AccountBox,
    component: ProfileEditing,
    layout: "/consolle",
    fixed: "true",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.OPERATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_OPERATIVE_ROLE_NAME,
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    description: "Esci dall'applicazione",
    rtlName: "لوحة القيادة",
    icon: ExitToApp,
    component: Logout,
    layout: "/consolle",
    fixed: "true",
    roles: [
      AuthService.ADMINISTRATIVE_ROLE_NAME,
      AuthService.COORDINATIVE_ROLE_NAME,
      AuthService.OPERATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_COORDINATIVE_ROLE_NAME,
      AuthService.VOLKSBANK_OPERATIVE_ROLE_NAME,
    ],
  }
)

export default dashRoutes;
